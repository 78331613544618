import {
  DynamicVariableDataCreateResponse,
  DynamicVariableDataCreateV2Request,
} from '../../types/DynamicVariable.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface IDynamicVariableApiClient {
  create(data: DynamicVariableDataCreateV2Request): Promise<DynamicVariableDataCreateResponse | undefined>;
  update(data: DynamicVariableDataCreateV2Request): Promise<DynamicVariableDataCreateResponse | undefined>;
}

export class DynamicVariableApiClient implements IDynamicVariableApiClient {
  variableApiClient: IApiClient;

  constructor(DynamicVariableApiClient: IApiClient) {
    this.variableApiClient = DynamicVariableApiClient;
  }

  async create(data: DynamicVariableDataCreateV2Request): Promise<DynamicVariableDataCreateResponse | undefined> {
    const response = await this.variableApiClient.post<
      DynamicVariableDataCreateV2Request,
      DynamicVariableDataCreateResponse
    >(`/variaveis-inteligentesV2`, data);
    return response;
  }

  async update(data: DynamicVariableDataCreateV2Request): Promise<DynamicVariableDataCreateResponse | undefined> {
    const response = await this.variableApiClient.put<
      DynamicVariableDataCreateV2Request,
      DynamicVariableDataCreateResponse
    >(`/variaveis-inteligentesV2`, data);
    return response;
  }
}

export default class DynamicVariableService {
  variableApiClient: IDynamicVariableApiClient;

  constructor() {
    this.variableApiClient = new DynamicVariableApiClient(new ApiClient());
  }

  async create(data: DynamicVariableDataCreateV2Request): Promise<DynamicVariableDataCreateResponse | undefined> {
    return this.variableApiClient.create(data);
  }

  async update(data: DynamicVariableDataCreateV2Request): Promise<DynamicVariableDataCreateResponse | undefined> {
    return this.variableApiClient.update(data);
  }
}
