import styled from 'styled-components';
import { ButtonProps } from '.';
import { theme } from '../../../styles/themes/default';

export const StyledButton = styled.button<ButtonProps>`
  padding: ${theme.spacings[1.5]} ${theme.spacings[2]};
  border: none;
  border-radius: 0.4rem;
  background: ${theme.colors.blue[600]};
  color: ${theme.colors.neutral.white};
  font-size: ${theme.fonts.small};
  line-height: ${theme.fonts.large};
  font-weight: 400;
  transition: background 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.blue[500]};
  }

  &:focus {
    background: ${theme.colors.blue[700]};
  }

  &[disabled] {
    background: ${theme.colors.blue[200]};
  }

  svg {
    max-height: 2rem;
    margin-right: ${theme.spacings[1.5]};
  }

  &.full-width {
    width: 100%;
  }

  &.alert {
    background-color: ${theme.colors.red[200]};
    color: ${theme.colors.red[500]};

    &:hover {
      background: ${theme.colors.red[100]};
    }

    &:focus {
      background: ${theme.colors.red[300]};
    }
  }

  &.outline {
    background: none;
    color: ${theme.colors.blue[600]};

    &:hover {
      background: none;
      color: ${theme.colors.blue[500]};
    }

    &:focus {
      background: none;
      color: ${theme.colors.blue[700]};
    }
  }

  &.secondary {
    background: ${theme.colors.blue[100]};
    color: ${theme.colors.blue[600]};

    &:hover,
    &:active,
    &:visited {
      background: ${theme.colors.blue[200]};
      color: ${theme.colors.blue[500]};
    }

    &:focus {
      background: ${theme.colors.blue[200]};
      color: ${theme.colors.blue[500]};
    }
  }
`;
