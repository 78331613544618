import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 6.4rem);
  overflow: hidden;
  z-index: 300;
  top: 6.4rem;
`;

export const Sidebar = styled.div`
  background: ${theme.colors.grey[100]};
  width: 54.6rem;
  padding: ${theme.spacings[2]} ${theme.spacings[4]} ${theme.spacings[3]};
  overflow-y: auto;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% - 54.6rem);
  background-color: ${theme.colors.grey[50]};
  overflow-y: auto;
  margin-bottom: ${theme.spacings[2]};
  &.no-sidebar {
    width: 100%;
    height: 100%;
    padding: ${theme.spacings[2]};
  }
`;
