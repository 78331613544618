import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TextForm from './TextForm';
import { TextFormValues, InitialTextForm } from '../../types/Text.types';
import { textService } from '../../api/services';
import { getFormattedInitialData } from './utils';
import { showToast } from '../../components/Toast/showToast';

const UpdateViewText = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<InitialTextForm>({
    name: '',
    type: { label: 'Texto comum', value: '1' },
    sections: [],
    text: '',
    variables: [],
    footnotes: [],
  });

  const navigate = useNavigate();
  const navigateToListTexts = useCallback(() => {
    navigate('/texts');
  }, []);

  const { pathname } = useLocation();
  const type = pathname.includes('update') ? 'update' : 'view';

  useEffect(() => {
    async function getInitialData() {
      const textData = await textService.getById(id as string);
      setInitialData(getFormattedInitialData(textData));
      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: TextFormValues) => {
    const successfullyUpdated = await textService.update(id as string, {
      nome: data.name,
      valor: data.text,
      tipo: parseInt(data.type?.value as string),
      secoes: data.sections?.map((section) => section.value as string),
    });
    if (successfullyUpdated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao atualizar texto.',
      });
      navigateToListTexts();
    }
  }, []);

  return !isLoading ? (
    <>
      <TextForm type={type} initialData={initialData} onSubmit={handleSubmit} onCancel={navigateToListTexts} />
    </>
  ) : null;
};

export default UpdateViewText;
