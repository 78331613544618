import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const StyledBox = styled.div`
  background: ${theme.colors.neutral.white};
  border: 0.1rem solid ${theme.colors.grey[200]};
  border-radius: 0.4rem;
  padding: ${theme.spacings[2.5]} ${theme.spacings[3]};

  &.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${theme.spacings[3]};
    > div {
      margin-top: 0;
    }
  }

  &.no-padding {
    padding: 0;
  }

  &.no-border {
    border: none;
  }

  &.no-background {
    background: none;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${theme.colors.neutral.white};
  border-top: 0.1rem solid ${theme.colors.grey[200]};
  width: calc(100% - 7.2rem);
  position: fixed;
  padding: ${theme.spacings[2]} ${theme.spacings[4]};
  bottom: 0;
  left: 7.2rem;
  z-index: 200;
  &.suspense-page {
    left: 23rem;
    width: calc(100% - 23rem);
  }
  &.full-page {
    left: 0;
    width: 100%;
  }
`;
