import {
  ModelData,
  ModelDataCreateRequest,
  ModelDataDeleteResponse,
  ModelDataUpdateResponse,
  PagedModelData,
  PagedModelDataRequest,
  SingleModelData,
} from '../../types/Model.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface IModelApiClient {
  create(data: ModelDataCreateRequest): Promise<ModelData | undefined>;
  list(type: string | undefined): Promise<ModelData[] | undefined>;
  listPaged(data: PagedModelDataRequest): Promise<PagedModelData | undefined>;
  getById(id: string): Promise<SingleModelData | undefined>;
  update(id: string, data: ModelDataCreateRequest): Promise<ModelDataUpdateResponse | undefined>;
  delete(id: string): Promise<ModelDataDeleteResponse | undefined>;
}

export class ModelApiClient implements IModelApiClient {
  modelApiClient: IApiClient;

  constructor(modelApiClient: IApiClient) {
    this.modelApiClient = modelApiClient;
  }

  async create(data: ModelDataCreateRequest): Promise<ModelData | undefined> {
    const response = await this.modelApiClient.post<ModelDataCreateRequest, ModelData>(`/modelos`, data);
    return response;
  }

  async list(type: string | undefined): Promise<ModelData[] | undefined> {
    const response = await this.modelApiClient.get<ModelData[]>(`/modelos${type ? `?tipoModelo=${type}` : ''}`);
    return response;
  }

  async listPaged(data: PagedModelDataRequest): Promise<PagedModelData | undefined> {
    const response = await this.modelApiClient.post<PagedModelDataRequest, PagedModelData>('/modelos_paged', data);
    return response;
  }

  async getById(id: string): Promise<SingleModelData | undefined> {
    const response = await this.modelApiClient.get<SingleModelData>(`/modelos/${id}`);
    return response;
  }

  async update(id: string, data: ModelDataCreateRequest): Promise<ModelDataUpdateResponse | undefined> {
    const response = await this.modelApiClient.put<ModelDataCreateRequest, ModelDataUpdateResponse>(
      `/modelos/${id}`,
      data
    );
    return response;
  }

  async delete(id: string): Promise<ModelDataDeleteResponse | undefined> {
    const response = await this.modelApiClient.delete<ModelDataDeleteResponse>(`/modelos/${id}`);
    return response;
  }
}

export default class ModelService {
  modelApiClient: IModelApiClient;

  constructor() {
    this.modelApiClient = new ModelApiClient(new ApiClient());
  }

  async create(data: ModelDataCreateRequest): Promise<ModelData | undefined> {
    return this.modelApiClient.create(data);
  }

  async list(type: string | undefined = undefined): Promise<ModelData[] | undefined> {
    return this.modelApiClient.list(type);
  }

  async listPaged(data: PagedModelDataRequest): Promise<PagedModelData | undefined> {
    return this.modelApiClient.listPaged(data);
  }

  async getById(id: string): Promise<SingleModelData | undefined> {
    return this.modelApiClient.getById(id);
  }

  async update(id: string, data: ModelDataCreateRequest): Promise<ModelDataUpdateResponse | undefined> {
    return this.modelApiClient.update(id, data);
  }

  async delete(id: string): Promise<ModelDataDeleteResponse | undefined> {
    return this.modelApiClient.delete(id);
  }
}
