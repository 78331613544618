import styled from 'styled-components';
import { theme } from '../styles/themes/default';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ProtectedRouteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid ${theme.colors.grey[200]};
  padding: ${theme.spacings[3]};
  background: ${theme.colors.neutral.white};
  min-width: 40rem;

  a {
    color: ${theme.colors.teal[500]};
  }

  h1 {
    margin: ${theme.spacings[1]} 0;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  height: 100%;
`;

export const Page = styled.div`
  flex: 1;
  padding: ${theme.spacings[4]} ${theme.spacings[3]};
  margin-top: 6.4rem;
  margin-left: 7.2rem;
`;
