import { Dispatch, SetStateAction } from 'react';
import SearchModel from '../../pages/Models/SearchModel';
import Dialog from '../Dialog';
import Button from '../Form/Button';
import SuspensePage from '../SuspensePage';
import Heading from '../Text/Heading';
import { ContentInfo } from './styles';
import Paragraph from '../Text/Paragraph';

interface ModelContentProps {
  openedModelOptionsModal: boolean;
  setOpenedModelOptionsModal: Dispatch<SetStateAction<boolean>>;
  openModelContent: (content: string, type?: string) => () => void;
  openedAddModelModal: boolean;
  setOpenedAddModelModal: Dispatch<SetStateAction<boolean>>;
  closeModelContent: () => void;
  openedAddModelPage: boolean;
  openedReplaceModelPage: boolean;
  setBpmnModel: (modelId: string, name: string) => () => void;
  currentModel: {
    name: string;
    modelId: string;
    fullName: string;
  };
}

const ModelContent = ({
  openedModelOptionsModal,
  setOpenedModelOptionsModal,
  openedAddModelModal,
  setOpenedAddModelModal,
  openModelContent,
  openedAddModelPage,
  closeModelContent,
  openedReplaceModelPage,
  currentModel,
  setBpmnModel,
}: ModelContentProps) => {
  return (
    <>
      <Dialog
        controlledClose={true}
        open={openedModelOptionsModal}
        setOpen={setOpenedModelOptionsModal}
        title={<Heading size="medium">Opções para sub-modelo</Heading>}
        buttonsStyle="row"
        secondaryButton={
          <Button type="button" kind="secondary" onClick={openModelContent('replaceModelPage')}>
            Substituir sub-modelo
          </Button>
        }
        content={
          <ContentInfo>
            <Paragraph size="small" className="type">
              Sub-modelo
            </Paragraph>
            <Paragraph size="medium" className="value">
              {currentModel.fullName}
            </Paragraph>
          </ContentInfo>
        }>
        <></>
      </Dialog>

      <Dialog
        controlledClose={true}
        open={openedAddModelModal}
        setOpen={setOpenedAddModelModal}
        title={
          <>
            <Heading size="medium">Adicionar sub-modelo</Heading>
            <Button kind="outline" onClick={openModelContent('addModelPage')}>
              Ver todos
            </Button>
          </>
        }
        content={<SearchModel type="modal" setBpmnModel={setBpmnModel} />}>
        <></>
      </Dialog>

      {openedAddModelPage && (
        <SuspensePage
          title="Modelos"
          menuItems={[{ name: 'Adicionar sub-modelo', onClick: () => null }]}
          backButtonTitle="Voltar à modelagem"
          onBackButtonClick={closeModelContent}>
          <SearchModel title="Adicionar sub-modelo" type="page" setBpmnModel={setBpmnModel} />
        </SuspensePage>
      )}

      {openedReplaceModelPage && (
        <SuspensePage
          title="Modelos"
          menuItems={[{ name: 'Substituir modelo', onClick: () => null }]}
          backButtonTitle="Voltar à modelagem"
          onBackButtonClick={closeModelContent}>
          <SearchModel title="Substituir modelo" type="page" setBpmnModel={setBpmnModel} />
        </SuspensePage>
      )}
    </>
  );
};

export default ModelContent;
