import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  height: 100%;
  width: 7.2rem;
  margin-top: 6.4rem;
  background: ${theme.colors.neutral.white};
  border-right: 0.1rem solid ${theme.colors.grey[200]};

  a {
    padding: ${theme.spacings[1]} ${theme.spacings[3]};
    margin: ${theme.spacings[1]} 0;
    display: block;
    border-left: 0.3rem solid ${theme.colors.neutral.white};

    &:hover svg {
      stroke: ${theme.colors.blue[600]};
    }
    &.active {
      border-left: 0.3rem solid ${theme.colors.blue[600]};
    }

    svg {
      height: 2.2rem;
      stroke: ${theme.colors.neutral.grey};
    }
  }
`;
