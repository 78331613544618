import { useCallback } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TemplateItemsFormValues } from '../../types/Template.types';
import SuspensePage from '../../components/SuspensePage';
import { useNavigate } from 'react-router-dom';
import Box from '../../components/Box';
import { StyledSuspenseUpdateTemplateItemsForm } from './styles';
import { Controller, useForm } from 'react-hook-form';
import { Select } from '../../components/Form/Select';
import FormGroup from '../../components/Form/FormGroup';
import PopoverContent, { Popover, PopoverTrigger } from '../../components/Popover';
import Button from '../../components/Form/Button';
import Input from '../../components/Form/Input';
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';
import FileUploader from '../../components/FileUploader';

interface TemplateItemsFormProps {
  initialData?: TemplateItemsFormValues;
  onSubmit: (data: TemplateItemsFormValues) => void;
}

const validationSchema = yup.object({
  font: yup.object().shape({
    label: yup.string().required('O campo fonte é obrigatório'),
    value: yup.string().required(),
  }),
  font_size: yup.object().shape({
    label: yup.string().required('O campo tamanho fonte é obrigatório'),
    value: yup.string().required(),
  }),
  margin: yup.object().shape({
    top: yup.number().required('O campo margem superior é obrigatório'),
    left: yup.number().required('O campo margem esquerda é obrigatório'),
    right: yup.number().required('O campo margem direita é obrigatório'),
    bottom: yup.number().required('O campo margem inferior é obrigatório'),
  }),
});

const TemplateItemsForm = ({ initialData, onSubmit }: TemplateItemsFormProps) => {
  const navigate = useNavigate();
  const navigateToListTemplates = useCallback(() => {
    navigate('/templates');
  }, []);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<TemplateItemsFormValues>({
    defaultValues: initialData,
    resolver: yupResolver(validationSchema),
  });

  const renderSelectFonts = useCallback(
    ({ field }: any) => {
      return (
        <>
          <Select
            placeholder="Fonte"
            {...field}
            options={[
              { label: 'IBM Plex Sans', value: 'IBM Plex Sans' },
              { label: 'Arial', value: 'Arial' },
              { label: 'Calibri', value: 'Calibri' },
              { label: 'Calibri Light', value: 'Calibri Light' },
              { label: 'Georgia', value: 'Georgia' },
              { label: 'Helvetica', value: 'Helvetica' },
              { label: 'Tahoma', value: 'Tahoma' },
              { label: 'Times New Roman', value: 'Times New Roman' },
              { label: 'Verdana', value: 'Verdana' },
            ]}
          />
        </>
      );
    },
    [initialData]
  );

  const renderSelectFontsSize = useCallback(
    ({ field }: any) => {
      return (
        <>
          <Select
            placeholder="..."
            {...field}
            options={[
              { label: '8pt', value: '8pt' },
              { label: '9pt', value: '9pt' },
              { label: '10pt', value: '10pt' },
              { label: '11pt', value: '11pt' },
              { label: '12pt', value: '12pt' },
              { label: '13pt', value: '13pt' },
              { label: '14pt', value: '14pt' },
              { label: '18pt', value: '18pt' },
              { label: '24pt', value: '24pt' },
              { label: '30pt', value: '30pt' },
              { label: '36pt', value: '36pt' },
              { label: '48pt', value: '48pt' },
              { label: '60pt', value: '60pt' },
              { label: '72pt', value: '12pt' },
            ]}
          />
        </>
      );
    },
    [initialData]
  );

  const renderSelectPageSize = useCallback(
    ({ field }: any) => {
      return (
        <>
          <Select
            placeholder="..."
            {...field}
            options={[
              { label: 'Carta', value: 'Carta' },
              { label: 'A4', value: 'A4' },
            ]}
          />
        </>
      );
    },
    [initialData]
  );

  const renderFileUploader = useCallback(
    ({ field }: any) => {
      const key = field.name as string;
      let images: string[] = [];
      if (initialData && key in initialData && initialData[key as keyof TemplateItemsFormValues]) {
        images = initialData[key as keyof TemplateItemsFormValues] as string[];
      }
      const names: { [key: string]: string } = {
        header: 'Cabeçalho',
        topic: 'Tópicos',
        footer: 'Rodapé',
      };
      return (
        <FileUploader
          {...field}
          defaultImages={images}
          setValue={setValue}
          thumbs={false}
          multiple={false}
          imageAsBackground={true}
          title={names[key]}
        />
      );
    },
    [initialData]
  );

  return (
    <SuspensePage backButtonTitle="Voltar a templates" onBackButtonClick={navigateToListTemplates}>
      <StyledSuspenseUpdateTemplateItemsForm onSubmit={handleSubmit(onSubmit)}>
        <Box
          border={false}
          background={false}
          padding={false}
          type="suspense-page"
          footer={
            <>
              <Button className="cancel-button" kind="alert" onClick={navigateToListTemplates}>
                Cancelar
              </Button>
              <Button className="submit-button" type="submit">
                Salvar
              </Button>
            </>
          }>
          <Box className="box-form" padding={false}>
            <FormGroup
              errors={[
                errors.font?.label?.message as string,
                errors.font_size?.label?.message as string,
                errors.margin?.top?.message as string,
                errors.margin?.left?.message as string,
                errors.margin?.right?.message as string,
                errors.margin?.bottom?.message as string,
              ]}>
              <div className="box-form-header">
                <Controller name="font" control={control} render={renderSelectFonts} />
                <Controller name="font_size" control={control} render={renderSelectFontsSize} />
                <Controller name="page_size" control={control} render={renderSelectPageSize} />
                <Popover>
                  <PopoverTrigger>
                    <Bars3BottomLeftIcon data-testid="config-options" />
                  </PopoverTrigger>
                  <PopoverContent container={document.getElementById('suspense-page-root')}>
                    <div className="margins-box">
                      <FormGroup>
                        <Input type="number" label="Margem superior" {...register('margin.top')} />
                      </FormGroup>
                      <FormGroup>
                        <Input type="number" label="Margem esquerda" {...register('margin.left')} />
                      </FormGroup>
                      <FormGroup>
                        <Input type="number" label="Margem direita" {...register('margin.right')} />
                      </FormGroup>
                      <FormGroup>
                        <Input type="number" label="Margem inferior" {...register('margin.bottom')} />
                      </FormGroup>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            </FormGroup>
            <div className="box-form-items">
              <Controller name="header" control={control} render={renderFileUploader} />
              <Controller name="topic" control={control} render={renderFileUploader} />
              <Controller name="footer" control={control} render={renderFileUploader} />
            </div>
          </Box>
        </Box>
      </StyledSuspenseUpdateTemplateItemsForm>
    </SuspensePage>
  );
};

export default TemplateItemsForm;
