import styled from 'styled-components';
import { theme } from '../../styles/themes/default';
import CoverBackground from '../../assets/login-background.jpg';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Cover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100vh;
  background: url(${CoverBackground});
  background-size: cover;
  box-shadow: 0 0.8rem 1.9rem 0.2rem rgba(0, 0, 0, 0.25);

  img {
    width: 24rem;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  align-items: center;
  padding: ${theme.spacings[3]};

  h1 {
    margin: ${theme.spacings[1]} 0;
  }

  p {
    color: ${theme.colors.grey[700]};
    position: absolute;
    bottom: ${theme.spacings[4]};
    margin: ${theme.spacings[2]};
    z-index: -1;
  }
`;

export const Form = styled.form`
  border-radius: 0.4rem;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  max-width: 44rem;

  h1 {
    margin-bottom: ${theme.spacings[4]};
  }

  input {
    margin-bottom: ${theme.spacings[2]};
  }
`;
