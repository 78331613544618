import LegalAreaService from './LegalAreaService';
import SectionService from './SectionService';
import VariableService from './VariableService';
import DynamicVariableService from './DynamicVariableService';
import TextService from './TextService';
import ModelService from './ModelService';
import ConditionService from './ConditionService';
import DocumentService from './DocumentService';
import ConditionAssociationService from './ConditionAssociationService';
import TemplateService from './TemplateService';
import UserService from './UserService';
import ExtratoLogService from './ExtratoLogService';

export const legalAreaService = new LegalAreaService();
export const sectionService = new SectionService();
export const variableService = new VariableService();
export const dynamicVariableService = new DynamicVariableService();
export const textService = new TextService();
export const modelService = new ModelService();
export const conditionService = new ConditionService();
export const documentService = new DocumentService();
export const conditionAssociationService = new ConditionAssociationService();
export const templateService = new TemplateService();
export const extratoLogService = new ExtratoLogService();
export const userService = new UserService();
