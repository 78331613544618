import Button from '../../components/Form/Button';
import Heading from '../../components/Text/Heading';
import { AlertDialog } from '../shared/styles';
import Dialog from '../../components/Dialog';
import EditAlert from '../../assets/icons/edit-alert.svg';
import Paragraph from '../../components/Text/Paragraph';
import { FolderOpenIcon } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';
import { Item } from '../../components/DropdownMenu/styles';

interface DialogConfirmSaveDocumentProps {
  navigateToListPage: () => void;
  onSave: () => void;
}

export const DialogConfirmSaveDocument = ({ navigateToListPage, onSave }: DialogConfirmSaveDocumentProps) => {
  const [openedModal, setOpenedModal] = useState(false);

  const openConfirmDialog = useCallback((e: any) => {
    e.preventDefault();
    setOpenedModal(true);
  }, []);

  const closeConfirmDialog = useCallback((e: any) => {
    e.preventDefault();
    setOpenedModal(false);
  }, []);

  const saveAndNavigateToListPage = useCallback((e: any) => {
    e.preventDefault();
    onSave();
    setOpenedModal(false);
    navigateToListPage();
  }, []);

  return (
    <Dialog
      controlledClose={true}
      open={openedModal}
      setOpen={setOpenedModal}
      primaryButton={<Button onClick={closeConfirmDialog}>Continuar preenchimento</Button>}
      secondaryButton={
        <Button className="back-button" onClick={saveAndNavigateToListPage} kind="outline">
          Sair
        </Button>
      }
      content={
        <AlertDialog>
          <img src={EditAlert} />
          <Heading>Continuar depois?</Heading>
          <Paragraph>
            Você está saindo do preenchimento do documento, não se preocupe, você poderá continuar de onde parou.
          </Paragraph>
        </AlertDialog>
      }>
      <Item data-testid="save-document" onClick={openConfirmDialog}>
        <FolderOpenIcon /> <span>Salvar e sair</span>
      </Item>
    </Dialog>
  );
};
