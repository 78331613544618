import { useCallback, useEffect, useState } from 'react';
import ConditionForm from './ConditionForm';
import { showToast } from '../../components/Toast/showToast';
import { conditionService } from '../../api/services';
import { ConditionData, ConditionFormValues, ConditionOptionBPMN } from '../../types/Condition.types';
import { getFormattedInitialData, getCreateData } from './utils';

interface SuspenseUpdateConditionProps {
  id: string;
  setBpmnCondition: (id: string, name: string, options: ConditionOptionBPMN[]) => () => void;
  closeContent: () => void;
}

const SuspenseCreateCondition = ({ id, setBpmnCondition, closeContent }: SuspenseUpdateConditionProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<ConditionFormValues>({
    name: '',
    description: '',
    options: [
      { optionId: '', optionValue: '', optionChkVariable: false, optionVariableId: '', optionVariableValue: '' },
    ],
  });

  useEffect(() => {
    async function getInitialData() {
      const conditionData = await conditionService.getById(id);
      setInitialData(getFormattedInitialData(conditionData));
      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: ConditionFormValues) => {
    const preparedData = getCreateData(data);
    const successfullyUpdated = await conditionService.update(id as string, preparedData);
    if (successfullyUpdated) {
      const updatedCondition = await conditionService.getById(id);
      const conditionOptions: ConditionOptionBPMN[] = (updatedCondition as ConditionData).condicaoOpcoes.map(
        (optionCondition) => ({
          conditionId: optionCondition.idModeloCondicaoOpcao as string,
          conditionValue: optionCondition.descricao as string,
        })
      );
      showToast({
        type: 'success',
        text: 'Sucesso ao editar condição.',
      });
      setBpmnCondition(id, updatedCondition?.titulo as string, conditionOptions)();
      closeContent();
    }
  }, []);

  return !isLoading ? (
    <>
      <ConditionForm
        type="update"
        boxType="suspense-page"
        initialData={initialData}
        onSubmit={handleSubmit}
        onCancel={closeContent}
      />
    </>
  ) : null;
};

export default SuspenseCreateCondition;
