import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LegalAreaForm from './LegalAreaForm';
import { LegalAreaFormValues } from '../../types/LegalArea.types';
import { showToast } from '../../components/Toast/showToast';
import { legalAreaService } from '../../api/services/';

const UpdateViewLegalArea = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<LegalAreaFormValues>({
    name: '',
    description: '',
  });

  const navigate = useNavigate();
  const navigateToListLegalAreas = useCallback(() => {
    navigate('/legal-areas');
  }, []);

  const { pathname } = useLocation();
  const type = pathname.includes('update') ? 'update' : 'view';

  useEffect(() => {
    async function getInitialData() {
      const legalAreaData = await legalAreaService.getById(id as string);
      setInitialData({
        name: legalAreaData?.nomeAreaJuridica,
        description: legalAreaData?.descricaoAreaJuridica,
      });
      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: LegalAreaFormValues) => {
    const successfullyUpdated = await legalAreaService.update(id as string, {
      nomeAreaJuridica: data.name,
      descricaoAreaJuridica: data.description,
    });
    if (successfullyUpdated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao atualizar área jurídica.',
      });
      navigateToListLegalAreas();
    }
  }, []);

  return !isLoading ? (
    <>
      <LegalAreaForm type={type} initialData={initialData} onSubmit={handleSubmit} />
    </>
  ) : null;
};

export default UpdateViewLegalArea;
