import { theme } from '../../styles/themes/default';

export const LabelStyles = `
  font-weight: 600;
  font-size: ${theme.fonts['x-small']};
  line-height: ${theme.fonts['large']};
  margin-bottom: ${theme.spacings[0.5]};
  color: ${theme.colors.grey[600]};
`;

export const InputTextStyles = `
  width: 100%;
  font-family: 'Proxima Nova', sans-serif;
  color: ${theme.colors.grey[800]};
  padding: ${theme.spacings[1.5]} ${theme.spacings[2]};
  background: ${theme.colors.neutral.white};
  border-radius: 0.4rem;
  border: 0.1rem solid ${theme.colors.grey[200]};
  outline: 0;
  font-size: ${theme.fonts['x-small']};
  line-height: ${theme.fonts['large']};
  transition: border-color 0.2s ease-in;
  appearance: none;

  &:hover {
    border-color: ${theme.colors.grey[400]};
  }

  &:active,
  &:focus {
    border-color: ${theme.colors.grey[600]};
  }

  &[disabled] {
    background: ${theme.colors.grey[50]};
    border-color: ${theme.colors.grey[200]};
    color: ${theme.colors.grey[400]};
  }
`;
