import {
  PagedTextData,
  PagedTextDataRequest,
  SingleTextData,
  TextData,
  TextDataCreateRequest,
  TextDataCreateRequestClient,
  TextDataCreateResponse,
  TextDataDeleteResponse,
} from '../../types/Text.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface ITextApiClient {
  create(data: TextDataCreateRequest): Promise<TextDataCreateResponse | undefined>;
  list(type: string | undefined): Promise<TextData[] | undefined>;
  listPaged(data: PagedTextDataRequest): Promise<PagedTextData | undefined>;
  getById(id: string): Promise<SingleTextData | undefined>;
  update(id: string, data: TextDataCreateRequest): Promise<TextDataCreateResponse | undefined>;
  delete(id: string): Promise<TextDataDeleteResponse | undefined>;
}

export class TextApiClient implements ITextApiClient {
  textApiClient: IApiClient;

  constructor(textApiClient: IApiClient) {
    this.textApiClient = textApiClient;
  }

  async create(data: TextDataCreateRequest): Promise<TextDataCreateResponse | undefined> {
    const response = await this.textApiClient.post<TextDataCreateRequest, TextDataCreateResponse>(`/textos`, data);
    return response;
  }

  async list(type: string | undefined): Promise<TextData[] | undefined> {
    const response = await this.textApiClient.get<TextData[]>(`/textos${type ? `?tipoTexto=${type}` : ''}`);
    return response;
  }

  async listPaged(data: PagedTextDataRequest): Promise<PagedTextData | undefined> {
    const response = await this.textApiClient.post<PagedTextDataRequest, PagedTextData>('/textos_paged', data);
    return response;
  }

  async getById(id: string): Promise<SingleTextData | undefined> {
    const response = await this.textApiClient.get<SingleTextData>(`/textos/${id}`);
    return response;
  }

  async update(id: string, data: TextDataCreateRequest): Promise<TextDataCreateResponse | undefined> {
    const response = await this.textApiClient.put<TextDataCreateRequest, TextDataCreateResponse>(`/textos/${id}`, data);
    return response;
  }

  async delete(id: string): Promise<TextDataDeleteResponse | undefined> {
    const response = await this.textApiClient.delete<TextDataDeleteResponse>(`/textos/${id}`);
    return response;
  }
}

export default class TextService {
  textApiClient: ITextApiClient;

  constructor() {
    this.textApiClient = new TextApiClient(new ApiClient());
  }

  async create(data: TextDataCreateRequestClient): Promise<TextDataCreateResponse | undefined> {
    const request: TextDataCreateRequest = {
      ...data,
      variaveis: this.getVariablesFromText(data.valor as string),
      rodapes: this.getFootnotesFromText(data.valor as string),
    };
    return this.textApiClient.create(request);
  }

  async list(type: string | undefined = undefined): Promise<TextData[] | undefined> {
    return this.textApiClient.list(type);
  }

  async listPaged(data: PagedTextDataRequest): Promise<PagedTextData | undefined> {
    return this.textApiClient.listPaged(data);
  }

  async getById(id: string): Promise<SingleTextData | undefined> {
    return this.textApiClient.getById(id);
  }

  async update(id: string, data: TextDataCreateRequestClient): Promise<TextDataCreateResponse | undefined> {
    const request: TextDataCreateRequest = {
      ...data,
      variaveis: this.getVariablesFromText(data.valor as string),
      rodapes: this.getFootnotesFromText(data.valor as string),
    };
    return this.textApiClient.update(id, request);
  }

  async delete(id: string): Promise<TextDataDeleteResponse | undefined> {
    return this.textApiClient.delete(id);
  }

  getVariablesFromText(text: string) {
    const reg = new RegExp(/data-variable-id="(.*?)">/gi);
    let matches;
    const variables: string[] = [];
    while ((matches = reg.exec(text))) {
      const variable = matches[1];
      if (!variables.includes(variable)) variables.push(variable);
    }
    return variables;
  }

  getFootnotesFromText(text: string) {
    const reg = new RegExp(/data-footnote-id="(.*?)">/gi);
    let matches;
    const footnotes: string[] = [];
    while ((matches = reg.exec(text))) {
      const footnote = matches[1];
      if (!footnotes.includes(footnote)) footnotes.push(footnote);
    }
    return footnotes;
  }
}
