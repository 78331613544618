import { NavLink } from 'react-router-dom';
import { Container, NotFoundContainer } from './styles';
import Heading from '../../components/Text/Heading';
import Paragraph from '../../components/Text/Paragraph';

const NotFound = () => {
  return (
    <>
      <Container>
        <NotFoundContainer>
          <Heading>Página não encontrada</Heading>
          <Paragraph>
            Clique <NavLink to="/">aqui</NavLink> para voltar para a página inicial.
          </Paragraph>
        </NotFoundContainer>
      </Container>
    </>
  );
};
export default NotFound;
