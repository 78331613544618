import { CheckCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useCallback, useEffect } from 'react';
import { ToastMessageProps } from '../../types/Toast.types';
import Paragraph from '../Text/Paragraph';
import { MessageContainer, MessageContent } from './styles';

export default function ToastMessage({ onRemoveMessage, message }: ToastMessageProps) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onRemoveMessage(message.id);
    }, message.duration || 7000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [message, onRemoveMessage]);

  const handleRemoveToast = useCallback(() => {
    onRemoveMessage(message.id);
  }, []);

  return (
    <MessageContainer className="toast-message" type={message.type} tabIndex={0} role="button">
      <MessageContent>
        {message.type === 'danger' && <XCircleIcon />}
        {message.type === 'success' && <CheckCircleIcon />}
        <Paragraph size="medium">{message.text}</Paragraph>
      </MessageContent>
      <button className="close-toast" onClick={handleRemoveToast}>
        <XMarkIcon />
      </button>
    </MessageContainer>
  );
}
