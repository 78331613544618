import React, { Ref } from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { Arrow, Content } from './styles';

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

interface PopoverContentProps {
  container?: HTMLElement | null;
  children: React.ReactNode;
}

const PopoverContent = React.forwardRef(({ children, container, ...props }: PopoverContentProps, forwardedRef) => (
  <PopoverPrimitive.Portal container={container}>
    <Content sideOffset={5} {...props} ref={forwardedRef as Ref<HTMLDivElement>}>
      {children}
      <Arrow />
    </Content>
  </PopoverPrimitive.Portal>
));

PopoverContent.displayName = 'Popover';

export default PopoverContent;
