import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SectionForm from './SectionForm';
import { SectionFormValues } from '../../types/Section.types';
import { showToast } from '../../components/Toast/showToast';
import { sectionService } from '../../api/services';

const UpdateViewSection = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<SectionFormValues>({
    name: '',
    description: '',
  });

  const navigate = useNavigate();
  const navigateToListSections = useCallback(() => {
    navigate('/sections');
  }, []);

  const { pathname } = useLocation();
  const type = pathname.includes('update') ? 'update' : 'view';

  useEffect(() => {
    async function getInitialData() {
      const legalAreaData = await sectionService.getById(id as string);
      setInitialData({
        name: legalAreaData?.tituloSecao,
        description: legalAreaData?.descricaoSecao,
      });
      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: SectionFormValues) => {
    const successfullyUpdated = await sectionService.update(id as string, {
      tituloSecao: data.name,
      descricaoSecao: data.description,
    });
    if (successfullyUpdated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao atualizar seção.',
      });
      navigateToListSections();
    }
  }, []);

  return !isLoading ? (
    <>
      <SectionForm type={type} initialData={initialData} onSubmit={handleSubmit} />
    </>
  ) : null;
};

export default UpdateViewSection;
