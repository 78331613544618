import styled from 'styled-components';
import { theme } from '../../styles/themes/default';
import { TemplateDocument } from '../../types/Template.types';

export const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[4]};

    div {
      display: flex;
      justify-content: space-between;
      button {
        width: 30rem;
        margin-left: ${theme.spacings[1]};
      }
      a {
        text-decoration: none;
        button {
          width: 20rem;
        }
      }
    }
  }

  .search-filter-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[1.5]};

    input {
      width: 40rem;
    }

    button {
      border: 0;
      background: none;
      display: flex;
      align-items: center;
      cursor: pointer;

      p {
        color: ${theme.colors.grey[500]};
      }

      svg {
        height: 2.4rem;
        stroke: ${theme.colors.grey[500]};
        margin-left: ${theme.spacings[1]};
      }
    }
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  min-height: calc(100% - 1.6rem);
  > div:first-child {
    width: 100%;
  }
  h1 {
    margin: ${theme.spacings[1.5]} 0 ${theme.spacings[2]};
  }
  .variables {
    > div {
      margin-bottom: ${theme.spacings[3]};
    }
  }
  .buttons {
    margin-top: ${theme.spacings[3]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &.initial-data {
      justify-content: flex-end;
    }
  }
  .switch-list {
    > div:first-child {
      margin: ${theme.spacings[1]} 0;
    }
  }
`;

export const StyledInitialDataContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  h2 {
    margin: ${theme.spacings[4]} 0 ${theme.spacings[1]};
  }
  p {
    color: ${theme.colors.grey[500]};
  }
`;

export const StyledDocumentPreview = styled.div<{ template: TemplateDocument }>`
  width: 100%;
  font-size: ${theme.fonts['small']};
  line-height: ${theme.fonts['large']};
  ${(props) => `
    margin-top: ${props.template.margin.top}cm;
    margin-bottom: ${props.template.margin.bottom}cm;
    margin-left: ${props.template.margin.left}cm;
    margin-right: ${props.template.margin.right}cm;
    font-family: ${props.template.font};
    .topic {
      display: flex;
      vertical-align: middle;
      background-image: url("${props.template.topic}");
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      min-height: 60px;
      padding-top: 1%;
      padding-bottom: 1%;
    }
  `}
  .initial-variable {
    color: ${theme.colors.red[400]};
    &::before {
      content: '[';
    }
    &::after {
      content: ']';
    }
  }
  .footnote {
    background-color: #e0f1f6;
    padding: 0.2rem;
    font-weight: bold;
  }
`;

export const StyledDocumentSaveOptions = styled.div`
  position: fixed;
  z-index: 99999;
  right: 6rem;
  bottom: 2.2rem;
  background: ${theme.colors.neutral.white};
  box-shadow: 0 0 3.2rem rgba(55, 65, 81, 0.2);
  border-radius: 99rem;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 2.4rem;
    stroke: ${theme.colors.grey[500]};
  }
`;

export const TextContainer = styled.div`
  .tox-tinymce {
    background: none;
    height: calc(100vh - 18rem) !important;
  }
`;

export const PreviewFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  button + button {
    margin-left: ${theme.spacings[1]};
  }
`;

export const StyledFinishDocument = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  text-align: center;
  .title {
    margin-top: 40%;
    display: flex;
    flex-direction: column;
    h2 {
      font-weight: 600;
    }
    p {
      color: ${theme.colors.grey[500]};
    }
  }
  img {
    height: 14rem;
    margin-bottom: ${theme.spacings[4]};
  }
  .buttons {
    margin-top: ${theme.spacings[3]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;
