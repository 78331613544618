import Button from '../../components/Form/Button';
import Heading from '../../components/Text/Heading';
import { AlertDialog } from './styles';
import Dialog from '../../components/Dialog';
import EditAlert from '../../assets/icons/edit-alert.svg';
import Paragraph from '../../components/Text/Paragraph';

interface DialogCancelCreateUpdateProps {
  navigateToListPage: () => void;
  type: string;
}

export const DialogCancelCreateUpdate = ({ navigateToListPage, type }: DialogCancelCreateUpdateProps) => {
  const typeText = type === 'create' || type === 'duplicate' ? 'o cadastro' : 'a edição';

  return (
    <Dialog
      primaryButton={
        <Button className="back-button" onClick={navigateToListPage}>
          Cancelar {typeText}
        </Button>
      }
      secondaryButton={<Button kind="outline">Continuar com {typeText}</Button>}
      content={
        <AlertDialog>
          <img src={EditAlert} />
          <Heading>Cancelar {typeText}?</Heading>
          <Paragraph>
            {type === 'create' || type === 'duplicate'
              ? 'Se você cancelar o cadastro irá perder todos os dados já preenchidos, essa ação não tem volta.'
              : 'Se você cancelar a edição, as informações que você alterou, serão perdidas.'}
          </Paragraph>
        </AlertDialog>
      }>
      <Button className="cancel-button" kind="alert">
        Cancelar
      </Button>
    </Dialog>
  );
};
