import { BlueBadge, RedBadge, GreenBadge } from './styles';

type BadgeProps = {
  className?: string;
  children: string | React.ReactNode;
  color?: 'blue' | 'red' | 'green';
};

const Badge = ({ color, ...props }: BadgeProps) => {
  switch (color) {
    case 'blue':
      return <BlueBadge {...props} />;
    case 'red':
      return <RedBadge {...props} />;
    case 'green':
    default:
      return <GreenBadge {...props} />;
  }
};

export default Badge;
