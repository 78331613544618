import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import Heading from '../../components/Text/Heading';
import { MagnifyingGlassIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { Container } from './styles';
import Input from '../../components/Form/Input';
import { DocumentData } from '../../types/Document.types';
import { documentService } from '../../api/services';
import { getBadge } from './utils';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Form/Button';
import { formatDate } from '../../components/DataTable/utils';
import DocumentActions from './DocumentActions';
import { CSVLink } from 'react-csv';
import PaginatedDataTable, { usePagination, useSorting } from '../../components/PaginatedDataTable';

const Documents = () => {
  const [documents, setDocuments] = useState<DocumentData[]>([]);
  const [documentsReport, setDocumentsReport] = useState<DocumentData[]>([]);
  const [loadingReport, setLoadingReport] = useState(false);

  const [deletedItem, setDeletedItem] = useState<string>('');

  const { limit, onPaginationChange, skip, pagination } = usePagination(10);
  const { sorting, onSortingChange, field, order } = useSorting('dataCriacao', 'DESC');
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [resetPagination, setPaginationTrigger] = useState(false); // Inverter valor para reiniciar a paginaçao
  const [search, setSearch] = useState<string>('');

  const handleSearch = useCallback((e: { target: { value: SetStateAction<string> } }) => {
    setSearch(e.target.value);
  }, []);

  const DocumentStatus = useCallback(
    (info: CellContext<DocumentData, unknown>) => <>{getBadge(info.getValue() as string)}</>,
    []
  );

  const DateFormatter = useCallback(
    (info: CellContext<DocumentData, unknown>) => formatDate(info.getValue() as string),
    []
  );

  const actions = useCallback(
    (info: CellContext<DocumentData, unknown>) => (
      <DocumentActions
        setDeletedItem={setDeletedItem}
        id={info.row.getValue('idDocumento')}
        name={info.row.getValue('nomeDocumento')}
        finished={info.row.getValue('finalizado')}
      />
    ),
    []
  );

  const columns = useMemo<ColumnDef<DocumentData>[]>(
    () => [
      {
        accessorKey: 'idDocumento',
        header: 'Id',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'nomeDocumento',
        header: 'Nome do documento',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'finalizado',
        header: 'Característica',
        cell: DocumentStatus,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataCriacao',
        header: 'Data da criação',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataAlteracao',
        header: 'Última alteração',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'usuarioCriacao',
        header: 'Criado por',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'action',
        header: '',
        cell: actions,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  const generateReport = useCallback(async () => {
    await getReport();
  }, []);

  const getReport = async () => {
    try {
      setDocumentsReport([]);
      setLoadingReport(true);
      const result = await documentService.list();
      if (result) {
        setDocumentsReport(result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagination, sorting, deletedItem]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (search.length < 3 && search.length != 0) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      fetchData(true);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const fetchData = async (_resetPagination = false) => {
    setLoading(true);
    try {
      const result = await documentService.listPaged({
        pagination: { skip: _resetPagination ? 0 : skip, limit },
        sort: { field, order },
        query: search,
      });

      if (result) {
        setDocuments(result.data);
        setCount(result.count);
        if (_resetPagination) {
          setPaginationTrigger(!resetPagination); //Retorna para pagina 1 após a pesquisa
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const pageCount = Math.ceil(count / limit);
  const navigate = useNavigate();
  const navigateToCreateDocument = useCallback(() => {
    navigate('/documents/create');
  }, []);

  return (
    <Container>
      <div className="header">
        <Heading>Documentos</Heading>

        <div>
          {documentsReport?.length > 0 && (
            <CSVLink
              data={documentsReport.map((document) => ({
                ...document,
                finalizado: document.finalizado ? 'Sim' : 'Não',
              }))}
              filename={'documentos.csv'}
              enclosingCharacter={`"`}
              headers={[
                { label: 'Nome do documento', key: 'nomeDocumento' },
                { label: 'Nome do modelo', key: 'modeloResponse.nomeModelo' },
                { label: 'Código do cliente', key: 'clienteCodigo' },
                { label: 'Código da pasta', key: 'clienteCodigoPasta' },
                { label: 'Nome do cliente', key: 'clienteNome' },
                { label: 'Finalizado', key: 'finalizado' },
                { label: 'Data da criação', key: 'dataCriacaoConvertida' },
                { label: 'Criado por', key: 'usuarioCriacao' },
                { label: 'Data da Finalização', key: 'dataAlteracaoConvertida' },
                { label: 'Tempo de execução', key: 'tempoExecucao' },
                { label: 'Alterado por', key: 'usuarioAlteracao' },
              ]}
              separator=";"
              asyncOnClick={true}>
              <Button kind="secondary" type="button">
                Baixar relatório
              </Button>
            </CSVLink>
          )}
          {documentsReport?.length === 0 && (
            <Button kind="secondary" type="button" onClick={generateReport} disabled={loadingReport}>
              {loadingReport ? 'Gerando relatório...' : 'Gerar relatório'}
            </Button>
          )}
          <Button onClick={navigateToCreateDocument}>
            <ClipboardDocumentIcon />
            Cadastrar novo documento
          </Button>
        </div>
      </div>

      <div className="search-filter-bar">
        <Input
          name="search"
          icon={<MagnifyingGlassIcon />}
          placeholder="Procurar por nome do documento, nome do cliente..."
          onChange={handleSearch}
        />
      </div>
      <PaginatedDataTable<DocumentData>
        columnVisibility={{ idDocumento: false }}
        data={documents}
        columns={columns}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        pagination={pagination}
        paginationResetTrigger={resetPagination}
        loading={loading}
        pageCount={pageCount}
        sorting={sorting}
      />
    </Container>
  );
};

export default Documents;
