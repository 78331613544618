import {
  LegalAreaData,
  LegalAreaDataCreateRequest,
  LegalAreaDataCreateResponse,
  LegalAreaDataDeleteResponse,
} from '../../types/LegalArea.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface ILegalAreaApiClient {
  create(data: LegalAreaDataCreateRequest): Promise<LegalAreaDataCreateResponse | undefined>;
  list(): Promise<LegalAreaData[] | undefined>;
  getById(id: string): Promise<LegalAreaData | undefined>;
  update(id: string, data: LegalAreaDataCreateRequest): Promise<LegalAreaData | undefined>;
  delete(id: string): Promise<LegalAreaDataDeleteResponse | undefined>;
}

export class LegalAreaApiClient implements ILegalAreaApiClient {
  legalAreaApiClient: IApiClient;

  constructor(LegalAreaApiClient: IApiClient) {
    this.legalAreaApiClient = LegalAreaApiClient;
  }

  async create(data: LegalAreaDataCreateRequest): Promise<LegalAreaDataCreateResponse | undefined> {
    const response = await this.legalAreaApiClient.post<LegalAreaDataCreateRequest, LegalAreaDataCreateResponse>(
      `/areas-juridicas`,
      data
    );
    return response;
  }

  async list(): Promise<LegalAreaData[] | undefined> {
    const response = await this.legalAreaApiClient.get<LegalAreaData[]>(`/areas-juridicas`);
    return response;
  }

  async getById(id: string): Promise<LegalAreaData | undefined> {
    const response = await this.legalAreaApiClient.get<LegalAreaData>(`/areas-juridicas/${id}`);
    return response;
  }

  async update(id: string, data: LegalAreaDataCreateRequest): Promise<LegalAreaDataCreateResponse | undefined> {
    const response = await this.legalAreaApiClient.put<LegalAreaDataCreateRequest, LegalAreaDataCreateResponse>(
      `/areas-juridicas/${id}`,
      data
    );
    return response;
  }

  async delete(id: string): Promise<LegalAreaDataDeleteResponse | undefined> {
    const response = await this.legalAreaApiClient.delete<LegalAreaDataDeleteResponse>(`/areas-juridicas/${id}`);
    return response;
  }
}

export default class LegalAreaService {
  legalAreaApiClient: ILegalAreaApiClient;

  constructor() {
    this.legalAreaApiClient = new LegalAreaApiClient(new ApiClient());
  }

  async create(data: LegalAreaDataCreateRequest): Promise<LegalAreaDataCreateResponse | undefined> {
    return this.legalAreaApiClient.create(data);
  }

  async list(): Promise<LegalAreaData[] | undefined> {
    return this.legalAreaApiClient.list();
  }

  async getById(id: string): Promise<LegalAreaData | undefined> {
    return this.legalAreaApiClient.getById(id);
  }

  async update(id: string, data: LegalAreaDataCreateRequest): Promise<LegalAreaDataCreateResponse | undefined> {
    return this.legalAreaApiClient.update(id, data);
  }

  async delete(id: string): Promise<LegalAreaDataDeleteResponse | undefined> {
    return this.legalAreaApiClient.delete(id);
  }
}
