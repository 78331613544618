import {
  UserData,
  UserDataCreateRequest,
  UserDataDeleteResponse,
  UserDataUpdateResponse,
} from '../../types/User.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface IUserApiClient {
  create(data: UserDataCreateRequest): Promise<UserData | undefined>;
  list(): Promise<UserData[] | undefined>;
  getById(id: string): Promise<UserData | undefined>;
  update(id: string, data: UserDataCreateRequest): Promise<UserDataUpdateResponse | undefined>;
  delete(id: string): Promise<UserDataDeleteResponse | undefined>;
}

export class UserApiClient implements IUserApiClient {
  userApiClient: IApiClient;

  constructor(UserApiClient: IApiClient) {
    this.userApiClient = UserApiClient;
  }

  async create(data: UserDataCreateRequest): Promise<UserData | undefined> {
    const response = await this.userApiClient.post<UserDataCreateRequest, UserData>(`/usuarios`, data);
    return response;
  }

  async list(): Promise<UserData[] | undefined> {
    const response = await this.userApiClient.get<UserData[]>(`/usuarios`);
    return response;
  }

  async getById(id: string): Promise<UserData | undefined> {
    const response = await this.userApiClient.get<UserData>(`/usuarios/${id}`);
    return response;
  }

  async update(id: string, data: UserDataCreateRequest): Promise<UserDataUpdateResponse | undefined> {
    const response = await this.userApiClient.put<UserDataCreateRequest, UserDataUpdateResponse>(
      `/usuarios/${id}`,
      data
    );
    return response;
  }

  async delete(id: string): Promise<UserDataDeleteResponse | undefined> {
    const response = await this.userApiClient.delete<UserDataDeleteResponse>(`/usuarios/${id}`);
    return response;
  }
}

export default class UserService {
  userApiClient: IUserApiClient;

  constructor() {
    this.userApiClient = new UserApiClient(new ApiClient());
  }

  async create(data: UserDataCreateRequest): Promise<UserData | undefined> {
    return this.userApiClient.create(data);
  }

  async list(): Promise<UserData[] | undefined> {
    return this.userApiClient.list();
  }

  async getById(id: string): Promise<UserData | undefined> {
    return this.userApiClient.getById(id);
  }

  async update(id: string, data: UserDataCreateRequest): Promise<UserDataUpdateResponse | undefined> {
    return this.userApiClient.update(id, data);
  }

  async delete(id: string): Promise<UserDataDeleteResponse | undefined> {
    return this.userApiClient.delete(id);
  }
}
