import { useCallback, useState } from 'react';
import ModelForm from './ModelForm';
import { ModelFormValues } from '../../types/Model.types';
import { showToast } from '../../components/Toast/showToast';
import { modelService } from '../../api/services';
import { getCreateUpdateData } from './utils';
import { useNavigate } from 'react-router-dom';
import { bpmnProcessId } from '../../components/Bpmn/Bpmn';

interface CreateModelProps {
  bpmnComponent: boolean;
}

const CreateModel = ({ bpmnComponent }: CreateModelProps) => {
  const navigate = useNavigate();
  const navigateToListModels = useCallback(() => {
    navigate('/models');
  }, []);

  const [initialData, setInitialData] = useState<ModelFormValues>({
    id: null,
    name: '',
    type: { label: 'Modelo simples', value: '1' },
    sections: [],
    legalAreas: [],
    active: false,
    xmlModel: `<?xml version="1.0" encoding="UTF-8"?>
    <bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_0xwm8qu" targetNamespace="http://bpmn.io/schema/bpmn" exporterVersion="11.5.0">
      <bpmn:process id="${bpmnProcessId}" isExecutable="false">
      </bpmn:process>
      <bpmndi:BPMNDiagram id="BPMNDiagram_1">
        <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="${bpmnProcessId}">
        </bpmndi:BPMNPlane>
      </bpmndi:BPMNDiagram>
    </bpmn:definitions>`,
  });

  const saveOrUpdate = async (data: ModelFormValues) => {
    let successfullyAction = false;
    if (data.id === null) {
      const successfullyCreated = await modelService.create(getCreateUpdateData(data));
      if (successfullyCreated) {
        successfullyAction = true;
        showToast({
          type: 'success',
          text: 'Sucesso ao cadastrar modelo.',
        });
        setInitialData({ ...initialData, id: successfullyCreated?.idModelo });
      }
    } else {
      const successfullyUpdated = await modelService.update(data.id as string, getCreateUpdateData(data));
      if (successfullyUpdated) {
        successfullyAction = true;
        showToast({
          type: 'success',
          text: 'Sucesso ao atualizar modelo.',
        });
      }
    }
    return successfullyAction;
  };

  const handleSubmitSaveAndContinue = useCallback(async (data: ModelFormValues) => {
    saveOrUpdate(data);
  }, []);

  const handleSubmitSave = useCallback(async (data: ModelFormValues) => {
    const successfullyAction = await saveOrUpdate(data);
    if (successfullyAction) {
      navigateToListModels();
    }
  }, []);

  return (
    <>
      <ModelForm
        type="create"
        bpmnComponent={bpmnComponent}
        onSubmitSave={handleSubmitSave}
        onSubmitSaveAndContinue={handleSubmitSaveAndContinue}
        initialData={initialData}
      />
    </>
  );
};

export default CreateModel;
