import { EllipsisVerticalIcon, MagnifyingGlassPlusIcon, PencilIcon } from '@heroicons/react/24/outline';
import { SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sectionService } from '../../api/services';
import DropdownMenu from '../../components/DropdownMenu';
import { Content, Item, Separator } from '../../components/DropdownMenu/styles';
import { showToast } from '../../components/Toast/showToast';
import { DialogCancelDelete } from '../shared/DialogCancelDelete';

interface SectionActionsProps {
  id: string;
  name: string;
  setDeletedItem: React.Dispatch<SetStateAction<string>>;
}

const SectionActions = ({ id, name, setDeletedItem }: SectionActionsProps) => {
  const [openedActions, setOpenedActions] = useState(false);

  const navigate = useNavigate();
  const navigateToViewSection = useCallback(() => {
    navigate(`/sections/${id}`);
  }, []);
  const navigateToUpdateSection = useCallback(() => {
    navigate(`/sections/${id}/update`);
  }, []);

  const removeSection = useCallback(
    (id: string) => async () => {
      const successfullyDeleted = await sectionService.delete(id);
      if (successfullyDeleted) {
        showToast({
          type: 'success',
          text: 'Sucesso ao remover seção.',
        });
        setDeletedItem(id);
      }
      setOpenedActions(false);
    },
    []
  );

  return (
    <DropdownMenu
      open={openedActions}
      setOpen={setOpenedActions}
      content={
        <Content sideOffset={5}>
          <Item onClick={navigateToViewSection}>
            <MagnifyingGlassPlusIcon /> <span>Ver detalhes</span>
          </Item>

          <Separator />

          <Item onClick={navigateToUpdateSection}>
            <PencilIcon /> <span>Editar seção</span>
          </Item>

          <Separator />

          <DialogCancelDelete itemName={name} itemType="seção" removeItem={removeSection(id)} />
        </Content>
      }>
      <EllipsisVerticalIcon className="actions-button" />
    </DropdownMenu>
  );
};

export default SectionActions;
