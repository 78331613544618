import { useCallback, useState } from 'react';
import TemplateForm from './TemplateForm';
import { TemplateFormValues, TemplateItemsFormValues } from '../../types/Template.types';
import { useNavigate } from 'react-router-dom';
import { templateService } from '../../api/services';
import { showToast } from '../../components/Toast/showToast';
import TemplateItemsForm from './TemplateItemsForm';

const CreateTemplate = () => {
  const [step, setStep] = useState('initial');
  const [initialData, setInitialData] = useState({
    nomeTemplate: '',
    ativo: false,
    codigoCliente: '',
  });

  const navigate = useNavigate();
  const navigateToListTemplates = useCallback(() => {
    navigate('/templates');
  }, []);

  const handleSubmit = useCallback(async (data: TemplateFormValues) => {
    setInitialData({
      nomeTemplate: data.name as string,
      ativo: data.active as boolean,
      codigoCliente: data.client?.value as string,
    });
    setStep('items');
  }, []);

  const handleSubmitItems = useCallback(
    async (data: TemplateItemsFormValues) => {
      const successfullyCreated = await templateService.create({
        ...initialData,
        valorConfiguracao: JSON.stringify({
          fonte: data.font?.value,
          tamanhoFonte: data.font_size?.value,
          tamanhoPagina: data.page_size?.value,
          margemSuperior: data.margin.top,
          margemEsquerda: data.margin.left,
          margemDireita: data.margin.right,
          margemInferior: data.margin.bottom,
        }),
        valorCabecalho: data?.header && data?.header.length > 0 ? data?.header[0] : '',
        valorRodape: data?.footer && data?.footer.length > 0 ? data?.footer[0] : '',
        valorTopico: data?.topic && data?.topic.length > 0 ? data?.topic[0] : '',
      });
      if (successfullyCreated) {
        showToast({
          type: 'success',
          text: 'Sucesso ao cadastrar template.',
        });
        navigateToListTemplates();
      }
    },
    [initialData]
  );

  return (
    <>
      {step === 'initial' && <TemplateForm type="create" onSubmit={handleSubmit} />}
      {step === 'items' && <TemplateItemsForm onSubmit={handleSubmitItems} />}
    </>
  );
};

export default CreateTemplate;
