import * as TooltipComponent from '@radix-ui/react-tooltip';
import { Arrow, Content } from './styles';

type TooltipProps = {
  children: string | React.ReactNode;
  content: string | React.ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
};

const Tooltip = ({ children, content, side = 'top' }: TooltipProps) => {
  return (
    <TooltipComponent.Provider>
      <TooltipComponent.Root>
        <TooltipComponent.Trigger asChild>{children}</TooltipComponent.Trigger>
        <TooltipComponent.Portal>
          <Content className="tooltipContent" side={side} sideOffset={5}>
            {content}
            <Arrow className="tooltipArrow" />
          </Content>
        </TooltipComponent.Portal>
      </TooltipComponent.Root>
    </TooltipComponent.Provider>
  );
};

export default Tooltip;
