import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LegalAreaForm from './LegalAreaForm';
import { LegalAreaFormValues } from '../../types/LegalArea.types';
import { showToast } from '../../components/Toast/showToast';
import { legalAreaService } from '../../api/services/';

const CreateLegalArea = () => {
  const navigate = useNavigate();
  const navigateToListLegalAreas = useCallback(() => {
    navigate('/legal-areas');
  }, []);

  const handleSubmit = useCallback(async (data: LegalAreaFormValues) => {
    const successfullyCreated = await legalAreaService.create({
      nomeAreaJuridica: data.name,
      descricaoAreaJuridica: data.description,
    });
    if (successfullyCreated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar área jurídica.',
      });
      navigateToListLegalAreas();
    }
  }, []);

  return (
    <>
      <LegalAreaForm type="create" onSubmit={handleSubmit} />
    </>
  );
};

export default CreateLegalArea;
