import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[4]};

    button {
      width: 25rem;
    }
  }

  .search-filter-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[1.5]};

    input {
      width: 40rem;
    }
  }
`;

export const StyledForm = styled.form`
  h1 {
    margin-bottom: ${theme.spacings[4]};
  }
  .label {
    margin-bottom: 0;
  }
  .switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
      font-weight: bold;
      margin-left: ${theme.spacings[1]};
    }
    div {
      width: auto;
    }
    label {
      display: none;
    }
  }
`;
