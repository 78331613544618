import styled from 'styled-components';
import { theme } from '../../../styles/themes/default';
import { InputTextStyles, LabelStyles } from '../styles';

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  &.full-width {
    width: 100%;
  }

  svg {
    height: 1.3rem;
    position: absolute;
    padding-left: ${theme.spacings[2]};
    stroke: ${theme.colors.grey[900]};
  }

  svg + input {
    padding-left: 4.4rem;
  }
`;

export const Label = styled.label`
  margin-bottom: ${theme.spacings[0.5]};
  ${LabelStyles}
`;

export const StyledInput = styled.input.attrs({
  placeholderTextColor: theme.colors.grey[400],
})`
  ${InputTextStyles}
`;

export const ToggleShowContent = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  width: 4rem;
  transition: 180ms ease-in-out;
  border: 0px;
  background: 0px;
  right: 1rem;
  top: 1rem;
  svg {
    top: 0px;
    left: 0px;
    stroke: ${theme.colors.grey[400]};
    height: 3rem;
    width: 4rem;
  }
`;
