import { useAppDispatch } from '../../hooks/useStore';
import { logout } from '../../store/slices/user';
import * as DropdownMenuComponent from '@radix-ui/react-dropdown-menu';
import Button from '../Form/Button';
import { useCallback } from 'react';
import { StyledHeaderActions } from './styles';
import { useNavigate } from 'react-router';

const HeaderActions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logout());
    navigate('/');
  }, []);

  return (
    <DropdownMenuComponent.Portal>
      <StyledHeaderActions className="DropdownMenuContent" sideOffset={5}>
        <DropdownMenuComponent.Item className="DropdownMenuItem">
          <Button fullWidth className="button" onClick={handleLogout}>
            Logout
          </Button>
        </DropdownMenuComponent.Item>
      </StyledHeaderActions>
    </DropdownMenuComponent.Portal>
  );
};

export default HeaderActions;
