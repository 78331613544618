import {
  ConditionAssociationData,
  ConditionAssociationDataCreateRequest,
  ConditionAssociationDataUpdateResponse,
} from '../../types/ConditionAssociation.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface IConditionAssociationApiClient {
  create(data: ConditionAssociationDataCreateRequest): Promise<ConditionAssociationData | undefined>;
  list(modelId: string): Promise<ConditionAssociationData[] | undefined>;
  update(
    id: string,
    data: ConditionAssociationDataCreateRequest
  ): Promise<ConditionAssociationDataUpdateResponse | undefined>;
}

export class ConditionAssociationApiClient implements IConditionAssociationApiClient {
  conditionAssociationApiClient: IApiClient;

  constructor(conditionAssociationApiClient: IApiClient) {
    this.conditionAssociationApiClient = conditionAssociationApiClient;
  }

  async create(data: ConditionAssociationDataCreateRequest): Promise<ConditionAssociationData | undefined> {
    const response = await this.conditionAssociationApiClient.post<
      ConditionAssociationDataCreateRequest,
      ConditionAssociationData
    >(`/modelos/condicoes-associacoes`, data);
    return response;
  }

  async list(modelId: string): Promise<ConditionAssociationData[] | undefined> {
    const response = await this.conditionAssociationApiClient.get<ConditionAssociationData[]>(
      `/modelos/${modelId}/condicoes-associacoes`
    );
    return response;
  }

  async update(
    id: string,
    data: ConditionAssociationDataCreateRequest
  ): Promise<ConditionAssociationDataUpdateResponse | undefined> {
    const response = await this.conditionAssociationApiClient.put<
      ConditionAssociationDataCreateRequest,
      ConditionAssociationDataUpdateResponse
    >(`/modelos/condicoes-associacoes?id=${id}`, data);
    return response;
  }
}

export default class ConditionAssociationService {
  conditionAssociationApiClient: IConditionAssociationApiClient;

  constructor() {
    this.conditionAssociationApiClient = new ConditionAssociationApiClient(new ApiClient());
  }

  async create(data: ConditionAssociationDataCreateRequest): Promise<ConditionAssociationData | undefined> {
    return this.conditionAssociationApiClient.create(data);
  }

  async list(modelId: string): Promise<ConditionAssociationData[] | undefined> {
    return this.conditionAssociationApiClient.list(modelId);
  }

  async update(
    id: string,
    data: ConditionAssociationDataCreateRequest
  ): Promise<ConditionAssociationDataUpdateResponse | undefined> {
    return this.conditionAssociationApiClient.update(id, data);
  }
}
