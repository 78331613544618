import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Button from '../Form/Button';
import Heading from '../Text/Heading';
import { Container, Content, Sidebar } from './styles';

interface MenuItem {
  name: string;
  onClick: () => void;
}

interface SuspensePageProps {
  children: React.ReactNode;
  title?: string;
  menuItems?: MenuItem[];
  backButtonTitle?: string;
  onBackButtonClick?: () => void;
}

export default function SuspensePage({
  children,
  title,
  menuItems,
  backButtonTitle,
  onBackButtonClick,
}: SuspensePageProps) {
  let container = document.getElementById('suspense-page-root');

  if (!container) {
    container = document.createElement('div');
    container.setAttribute('id', 'suspense-page-root');
    document.body.appendChild(container);
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  return ReactDOM.createPortal(
    <Container>
      <Sidebar>
        {backButtonTitle && (
          <Button kind="secondary" className="back-button" onClick={onBackButtonClick}>
            <ArrowUturnLeftIcon />
            {backButtonTitle}
          </Button>
        )}
        {title && <Heading size="medium">{title}</Heading>}
        <div className="menu-items">
          {menuItems &&
            menuItems.map((menuItem: MenuItem) => (
              <Button key={menuItem.name} kind="outline" onClick={menuItem.onClick}>
                {menuItem.name}
              </Button>
            ))}
        </div>
      </Sidebar>
      <Content>{children}</Content>
    </Container>,
    container
  );
}
