import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import Heading from '../../components/Text/Heading';
import { MagnifyingGlassIcon, Square3Stack3DIcon } from '@heroicons/react/24/outline';
import { Container } from './styles';
import Input from '../../components/Form/Input';
import { ModelData } from '../../types/Model.types';
import { modelService } from '../../api/services';
import Badge from '../../components/Text/Badge';
import Tooltip from '../../components/Tooltip';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Form/Button';
import { getTextList, getTotalElements } from './utils';
import { formatDate } from '../../components/DataTable/utils';
import ModelActions from './ModelActions';
import { CSVLink } from 'react-csv';
import PaginatedDataTable, { usePagination, useSorting } from '../../components/PaginatedDataTable';

const Models = () => {
  const [models, setModels] = useState<ModelData[]>([]);
  const [modelsReport, setModelsReport] = useState<ModelData[]>([]);
  const [loadingReport, setLoadingReport] = useState(false);

  const [deletedItem, setDeletedItem] = useState<string>('');

  const { limit, onPaginationChange, skip, pagination } = usePagination(10);
  const { sorting, onSortingChange, field, order } = useSorting('dataCriacao', 'DESC');
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [resetPagination, setPaginationTrigger] = useState(false); // Inverter valor para reiniciar a paginaçao
  const [search, setSearch] = useState<string>('');

  const handleSearch = useCallback((e: { target: { value: SetStateAction<string> } }) => {
    setSearch(e.target.value);
  }, []);

  const AvailableDocument = useCallback(
    (info: CellContext<ModelData, unknown>) => <>{info.getValue() === true ? 'Sim' : 'Não'}</>,
    []
  );

  const DateFormatter = useCallback(
    (info: CellContext<ModelData, unknown>) => formatDate(info.getValue() as string),
    []
  );

  const ModelSections = useCallback(
    (info: CellContext<ModelData, unknown>) => (
      <Tooltip content={getTextList(info.getValue(), 'tituloSecao')}>
        <div className="badge-cell">
          <Badge color="red">{getTotalElements(info.getValue())}</Badge>
        </div>
      </Tooltip>
    ),
    []
  );

  const ModelLegalAreas = useCallback(
    (info: CellContext<ModelData, unknown>) => (
      <Tooltip content={getTextList(info.getValue(), 'nomeAreaJuridica')}>
        <div className="badge-cell">
          <Badge color="red">{getTotalElements(info.getValue())}</Badge>
        </div>
      </Tooltip>
    ),
    []
  );

  const actions = useCallback(
    (info: CellContext<ModelData, unknown>) => (
      <ModelActions
        id={info.row.getValue('idModelo')}
        name={info.row.getValue('nomeModelo')}
        setDeletedItem={setDeletedItem}
      />
    ),
    []
  );

  const columns = useMemo<ColumnDef<ModelData>[]>(
    () => [
      {
        accessorKey: 'idModelo',
        header: 'Id',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'nomeModelo',
        header: 'Nome do modelo',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'secoes',
        header: 'Seções',
        cell: ModelSections,
        enableGlobalFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'areasjuridicas',
        header: 'Áreas jurídicas',
        cell: ModelLegalAreas,
        enableGlobalFilter: false,
        enableSorting: false,
        maxSize: 50,
      },
      {
        accessorKey: 'ativo',
        header: 'Documento disponível',
        cell: AvailableDocument,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataCriacao',
        header: 'Data da criação',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataAlteracao',
        header: 'Última alteração',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'usuarioCriacao',
        header: 'Criado por',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'action',
        header: '',
        cell: actions,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  const generateReport = useCallback(async () => {
    await getReport();
  }, []);

  const getReport = async () => {
    try {
      setModelsReport([]);
      setLoadingReport(true);
      const result = await modelService.list();
      if (result) {
        setModelsReport(result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagination, sorting, deletedItem]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (search.length < 3 && search.length != 0) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      fetchData(true);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const fetchData = async (_resetPagination = false) => {
    setLoading(true);
    try {
      const result = await modelService.listPaged({
        pagination: { skip: _resetPagination ? 0 : skip, limit },
        sort: { field, order },
        query: search,
      });

      if (result) {
        setModels(result.data);
        setCount(result.count);
        if (_resetPagination) {
          setPaginationTrigger(!resetPagination); //Retorna para pagina 1 após a pesquisa
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const pageCount = Math.ceil(count / limit);

  const navigate = useNavigate();
  const navigateToCreateModel = useCallback(() => {
    navigate('/models/create');
  }, []);

  return (
    <Container>
      <div className="header">
        <Heading>Modelos</Heading>

        <div>
          {modelsReport?.length > 0 && (
            <CSVLink
              data={modelsReport.map((model) => ({
                ...model,
                modeloCompleto: model.modeloCompleto ? 'Sim' : 'Não',
                secoes: getTextList(model.secoes, 'tituloSecao'),
                areasjuridicas: getTextList(model.areasjuridicas, 'nomeAreaJuridica'),
              }))}
              filename={'modelos.csv'}
              enclosingCharacter={`"`}
              headers={[
                { label: 'Nome do modelo', key: 'nomeModelo' },
                { label: 'Modelo completo', key: 'modeloCompleto' },
                { label: 'Seções', key: 'secoes' },
                { label: 'Áreas jurídicas', key: 'areasjuridicas' },
                { label: 'Data da criação', key: 'dataCriacao' },
                { label: 'Criado por', key: 'usuarioCriacao' },
                { label: 'Data da alteração', key: 'dataAlteracao' },
                { label: 'Alterado por', key: 'usuarioAlteracao' },
              ]}
              separator=";"
              uFEFF={false}
              asyncOnClick={true}>
              <Button kind="secondary" type="button">
                Baixar relatório
              </Button>
            </CSVLink>
          )}
          {modelsReport?.length === 0 && (
            <Button kind="secondary" type="button" onClick={generateReport} disabled={loadingReport}>
              {loadingReport ? 'Gerando relatório...' : 'Gerar relatório'}
            </Button>
          )}
          <Button onClick={navigateToCreateModel}>
            <Square3Stack3DIcon />
            Cadastrar novo modelo
          </Button>
        </div>
      </div>

      <div className="search-filter-bar">
        <Input
          name="search"
          icon={<MagnifyingGlassIcon />}
          placeholder="Procurar por nome do modelo, seção, área jurídica..."
          onChange={handleSearch}
        />
      </div>
      <PaginatedDataTable<ModelData>
        columnVisibility={{ idModelo: false }}
        data={models}
        columns={columns}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        pagination={pagination}
        paginationResetTrigger={resetPagination}
        loading={loading}
        pageCount={pageCount}
        sorting={sorting}
      />
    </Container>
  );
};

export default Models;
