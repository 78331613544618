import { useCallback } from 'react';
import { showToast } from '../../components/Toast/showToast';
import { variableService } from '../../api/services';
import { VariableFormValues } from '../../types/Variable.types';
import VariableForm from './VariableForm';
import { getCreateUpdateData } from './utils';

interface SuspenseCreateVariableProps {
  closeContent: () => void;
}

const SuspenseCreateVariable = ({ closeContent }: SuspenseCreateVariableProps) => {
  const handleSubmit = useCallback(async (data: VariableFormValues) => {
    const successfullyCreated = await variableService.create(getCreateUpdateData(data));
    if (successfullyCreated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar variável.',
      });
      closeContent();
    }
  }, []);

  return (
    <>
      <VariableForm boxType="suspense-page" type="create" onSubmit={handleSubmit} onCancel={closeContent} />
    </>
  );
};

export default SuspenseCreateVariable;
