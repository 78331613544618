import BlankCircle from '../../assets/icons/blank-circle.svg';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '../../components/Box';
import Button from '../../components/Form/Button';
import { DynamicVariableContainer, Steps, StyledForm } from './styles';
import Paragraph from '../../components/Text/Paragraph';
import Heading from '../../components/Text/Heading';
import { variableService } from '../../api/services';
import { OptionType, Select } from '../../components/Form/Select';
import { DialogConfirmAction } from '../shared/DialogConfirmAction';
import { SingleVariableData, VariableData } from '../../types/Variable.types';
import { DynamicVariableFormValues, DynamicVariableFormValuesX } from '../../types/DynamicVariable.types';
import { getBadge, multipleChoiceTypeName, suspenseListTypeName } from './utils';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import FormGroup from '../../components/Form/FormGroup';

interface DynamicVariableFormProps {
  initialData?: DynamicVariableFormValuesX;
  relationType?: string;
  onSubmit: (data: DynamicVariableFormValues) => void;
}

const validationSchema = yup.object({
  principalVariableId: yup.string().required('O campo principalVariableId é obrigatório'),
  listDepedentVariable: yup
    .array()
    .of(
      yup.object().shape({
        dependentVariableId: yup.object().shape({
          label: yup.string().required('A escolha da variável depedente é obrigatório'),
          value: yup.string().required(),
        }),
        values: yup
          .array()
          .of(
            yup.object().shape({
              principalVariableValue: yup.object().shape({
                label: yup.string().required('O valor da variável principal é obrigatório'),
                value: yup.string().required(),
              }),
              dependentVariableValue: yup.object().shape({
                label: yup.string().required('O valor da variável dependente é obrigatório'),
                value: yup.string().required(),
              }),
            })
          )
          .min(1, 'É necessária pelo menos uma associação de valores.'),
      })
    )
    .min(1, 'É necessário selecionar pelo menos uma variável dependente.'),
});

const DynamicVariableForm = ({ initialData, relationType, onSubmit }: DynamicVariableFormProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [listdependentVariableOptions, setListdependentVariableOptions] = useState<Array<readonly OptionType[]>>([]);
  const [principalVariable, setPrincipalVariable] = useState<VariableData>();
  const [principalVariableOptions, setPrincipalVariableOptions] = useState<readonly OptionType[]>();
  const [variables, setVariables] = useState<VariableData[]>();
  const [variablesOptions, setVariablesOptions] = useState<readonly OptionType[]>();

  const navigate = useNavigate();
  const navigateToVariables = useCallback(() => {
    navigate(`/variables`);
  }, []);

  const renderSelectPrincipalVariable = useCallback(
    ({ field }: any) => {
      return <Select {...field} placeholder="Selecione uma opção" options={principalVariableOptions} />;
    },
    [principalVariableOptions]
  );

  const renderSelectDependentVariable = useCallback(
    (field: any, index: number) => {
      return <Select {...field} placeholder="Selecione uma opção" options={listdependentVariableOptions[index]} />;
    },
    [listdependentVariableOptions]
  );

  const {
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<DynamicVariableFormValuesX>({
    defaultValues: initialData,
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    name: 'listDepedentVariable',
    control,
  });
  const watchListDepedent = watch('listDepedentVariable');

  const appendValue = useCallback(
    () => {
      append({
        dependentVariableId: {
          label: '',
          value: '',
        },
        values: [
          {
            principalVariableValue: {
              label: '',
              value: '',
            },
            dependentVariableValue: {
              label: '',
              value: '',
            },
          },
        ],
      });
    },
    [append] // Adicione as dependências necessárias
  );

  const appendCondition = useCallback(
    (index: number) => {
      const listValues = getValues(`listDepedentVariable.${index}.values`) || []; // Use || [] para garantir que sempre tenha um array

      const newValue = {
        principalVariableValue: {
          label: '',
          value: '',
        },
        dependentVariableValue: {
          label: '',
          value: '',
        },
      };
      const updatedValues = [...listValues, newValue];
      setValue(`listDepedentVariable.${index}.values`, updatedValues);
    },
    [getValues, setValue]
  );

  const removeVariable = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const removeDependentVariableAndValues = useCallback(
    (indexDependent: number, indexValue: number) => {
      const listValues = getValues(`listDepedentVariable.${indexDependent}.values`);
      console.log('o resultado de listValues eh:', listValues);
      if (listValues !== undefined && listValues?.length > 0) {
        const updatedValues = listValues.filter((_, idx) => idx !== indexValue);
        console.log('o resultado de updatedValues eh:', updatedValues);
        if (updatedValues.length > 0) {
          setValue(`listDepedentVariable.${indexDependent}.values`, updatedValues);
        } else {
          setValue(`listDepedentVariable.${indexDependent}.values`, [
            {
              principalVariableValue: {
                label: '',
                value: '',
              },
              dependentVariableValue: {
                label: '',
                value: '',
              },
            },
          ]);
        }
      }
    },
    [getValues, setValue]
  );

  useEffect(() => {
    async function getPrincipalVariable() {
      if (initialData != null) {
        const resultVariablePrincipal = await variableService.getById(initialData.principalVariableId);
        setPrincipalVariable(resultVariablePrincipal);
        if (initialData.listDepedentVariable != null && initialData.listDepedentVariable.length > 0) {
          const ListVariableSec = await Promise.all(
            initialData.listDepedentVariable.map(async (element) => {
              if (element.dependentVariableId != null) {
                return await variableService.getById(element.dependentVariableId.value);
              }
            })
          );
          const filteredVariables = ListVariableSec.filter((el): el is SingleVariableData => el !== undefined);
          filteredVariables.forEach((element, index) => {
            setListdependentVariableOptions((prevList) => {
              const updatedList = [...prevList];
              const listOption =
                element?.valorAuxiliar?.split('¨').map((option) => ({
                  label: option,
                  value: option,
                })) || [];

              updatedList[index] = listOption;
              return updatedList;
            });
          });
        } else {
          appendValue();
        }
      }
    }

    async function getVariables() {
      const variables = await variableService.list();
      if (
        initialData != null &&
        initialData.listDepedentVariable != null &&
        initialData.listDepedentVariable?.length > 0
      ) {
        initialData.listDepedentVariable.forEach((element, index) => {
          const findVariable = variables?.find((el) => el.idVariavel === element.dependentVariableId?.value);

          if (findVariable) {
            setValue(`listDepedentVariable.${index}.dependentVariableId`, {
              label: findVariable.nomeVariavel ?? '',
              value: findVariable.idVariavel ?? '',
            });
          }
        });
      }
      setVariables(variables);
      setLoading(false);
    }

    getPrincipalVariable();
    getVariables();
  }, []);

  useEffect(() => {
    const variablesOptionsData = variables
      ?.filter(
        (variable) =>
          (variable.descricaoTipoVariavel === multipleChoiceTypeName ||
            variable.descricaoTipoVariavel === suspenseListTypeName) &&
          variable.idVariavel !== initialData!.principalVariableId &&
          variable.tipoRelacao !== 'Principal' &&
          variable.tipoRelacao !== 'Dependente'
      )
      .map(
        (variable) =>
          ({
            label: variable.nomeVariavel,
            value: variable.idVariavel,
          } as OptionType)
      );
    setVariablesOptions(variablesOptionsData);
  }, [variables]);

  const getDependentVariable = useCallback(
    async (value: string, index: number) => {
      const dependentVariableData = await variableService.getById(value);
      if (dependentVariableData != null) {
        setListdependentVariableOptions((prevList) => {
          const updatedList = [...prevList];
          const listOption =
            dependentVariableData?.valorAuxiliar?.split('¨').map((option) => ({
              label: option,
              value: option,
            })) || [];

          updatedList[index] = listOption;
          return updatedList;
        });

        const newValue = {
          principalVariableValue: {
            label: '',
            value: '',
          },
          dependentVariableValue: {
            label: '',
            value: '',
          },
        };
        setValue(`listDepedentVariable.${index}.values`, [newValue]);
      }
    },
    [setListdependentVariableOptions]
  );

  useEffect(() => {
    setPrincipalVariableOptions(
      principalVariable?.valorAuxiliar?.split('¨').map((option) => ({
        label: option,
        value: option,
      }))
    );
  }, [principalVariable]);

  const renderSelectVariablesOptions = useCallback(
    (field: any, index: number) => (
      <Select
        placeholder="Selecione uma opção"
        disabled={relationType === 'Dependente'}
        {...field}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(element) => {
          field.onChange(element); // Chamando o onChange do react-hook-form para manter o comportamento anterior
          getDependentVariable(element.value, index); // Chamando sua função personalizada
        }}
        options={variablesOptions}
      />
    ),
    [variablesOptions]
  );

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <DynamicVariableContainer>
        <Box
          padding={false}
          border={false}
          footer={
            <>
              <Button type="button" kind="alert" onClick={navigateToVariables}>
                Cancelar
              </Button>

              <Button type="submit">Finalizar</Button>
            </>
          }>
          <Box>
            <Steps>
              <div>
                <img src={BlankCircle} /> <Paragraph>Variável principal</Paragraph>
              </div>
              <div className="separator"></div>
              <div className="">
                <img src={BlankCircle} /> <Paragraph>Aplicação</Paragraph>
              </div>
            </Steps>
          </Box>
          <Box>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Heading size="medium">Variável dinâmica</Heading>
              <Button type="button" kind="outline" style={{ width: '300px' }} onClick={appendValue}>
                <PlusIcon />
                Adicionar variável dependente.
              </Button>
            </div>

            <Paragraph>
              Aqui você irá vincular comportamentos de uma variável principal a dados de uma ou mais variáveis
              dependente.
            </Paragraph>

            <div className="variable-info">
              <div>
                <Paragraph className="key">Nome da variável principal</Paragraph>
                <div className="value">{principalVariable?.nomeVariavel}</div>
              </div>
              <div>
                <Paragraph className="key">Tipo</Paragraph>
                <div className="value">{principalVariable?.descricaoTipoVariavel}</div>
              </div>
              <div>
                <Paragraph className="key">Característica</Paragraph>
                <div className="value">{getBadge(principalVariable?.tipoRelacao as string)}</div>
              </div>
            </div>

            <div>
              <Heading size="medium">Lista de variáveis dependentes:</Heading>
              <div
                className="variable-info"
                style={{ height: '20px', padding: '0px', marginTop: '20px', marginBottom: '0px' }}></div>
            </div>

            {!loading &&
              fields.length > 0 &&
              fields.map((field, valueIndex) => (
                <div key={field.id}>
                  {' '}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'end',
                      marginTop: '25px',
                    }}>
                    <div style={{ width: '50%' }}>
                      <FormGroup
                        error={errors?.listDepedentVariable?.[valueIndex]?.dependentVariableId?.label?.message}>
                        <Paragraph className="label">Selecione uma variável dependente:</Paragraph>
                        <Controller
                          name={`listDepedentVariable.${valueIndex}.dependentVariableId`}
                          control={control}
                          // eslint-disable-next-line react/jsx-no-bind
                          render={({ field }) => renderSelectVariablesOptions(field, valueIndex)}
                        />
                      </FormGroup>
                    </div>

                    <DialogConfirmAction
                      type="delete"
                      trigger={
                        <Button type="button" kind="outline" style={{ width: '290px' }}>
                          <XMarkIcon />
                          Remover variável selecionada.
                        </Button>
                      }
                      title="Confirmar exclusão?"
                      text={`Tem certeza que deseja excluir essa variável depedente?`}
                      // eslint-disable-next-line react/jsx-no-bind
                      onConfirm={() => removeVariable(valueIndex)}
                    />
                  </div>
                  <div className="value-container" key={field.id}>
                    {watchListDepedent != null &&
                      watchListDepedent[valueIndex]?.values?.map((value, valueSubIndex) => (
                        <Box
                          className="value-container"
                          key={`${field.id}-${value.dependentVariableValue.value}-${valueSubIndex}`}>
                          <section style={{ marginTop: '5px' }}>
                            {/* Dados da variável principal */}
                            <FormGroup
                              error={
                                errors?.listDepedentVariable?.[valueIndex]?.values?.[valueSubIndex]
                                  ?.principalVariableValue?.label?.message
                              }>
                              <Paragraph className="label">Valor da variável principal</Paragraph>
                              <Controller
                                name={`listDepedentVariable.${valueIndex}.values.${valueSubIndex}.principalVariableValue`}
                                control={control}
                                render={renderSelectPrincipalVariable}
                              />
                            </FormGroup>

                            <div className="value-separator">
                              <Paragraph size="small">Vincular à</Paragraph>
                            </div>

                            <FormGroup
                              error={
                                errors?.listDepedentVariable?.[valueIndex]?.values?.[valueSubIndex]
                                  ?.dependentVariableValue?.label?.message
                              }>
                              <Paragraph className="label">Valor da variável dependente</Paragraph>
                              <Controller
                                name={`listDepedentVariable.${valueIndex}.values.${valueSubIndex}.dependentVariableValue`}
                                control={control}
                                // eslint-disable-next-line react/jsx-no-bind
                                render={({ field }) => renderSelectDependentVariable(field, valueIndex)}
                              />
                            </FormGroup>

                            <DialogConfirmAction
                              type="delete"
                              trigger={
                                <Button kind="outline" type="button">
                                  <XMarkIcon />
                                </Button>
                              }
                              title="Confirmar exclusão?"
                              text={`Tem certeza que deseja excluir essa relação?`}
                              // eslint-disable-next-line react/jsx-no-bind
                              onConfirm={() => removeDependentVariableAndValues(valueIndex, valueSubIndex)}
                            />
                          </section>
                        </Box>
                      ))}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}>
                    <Button
                      type="button"
                      kind="outline"
                      style={{ width: '250px' }}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => appendCondition(valueIndex)}>
                      <PlusIcon />
                      Adicionar um vínculo.
                    </Button>
                    {valueIndex === fields.length - 1 && (
                      <Button type="button" kind="outline" style={{ width: '290px' }} onClick={appendValue}>
                        <PlusIcon />
                        Adicionar variável dependente.
                      </Button>
                    )}
                  </div>
                  {valueIndex !== fields.length - 1 && (
                    <div
                      className="variable-info"
                      style={{ height: '40px', padding: '0px', marginTop: '0px', marginBottom: '0px' }}></div>
                  )}
                </div>
              ))}
          </Box>
        </Box>
      </DynamicVariableContainer>
    </StyledForm>
  );
};

export default DynamicVariableForm;
