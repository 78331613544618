import Badge from '../../components/Text/Badge';
import { SingleTextData } from '../../types/Text.types';
import { OptionType } from '../../components/Form/Select';

export const getBadge = (textType: string) => {
  switch (textType) {
    case 'Nota de Rodapé':
      return <Badge color="blue">Nota de rodapé</Badge>;
    case 'Texto Comum':
    default:
      return <Badge color="green">Comum</Badge>;
  }
};

export const getInitialType = (type: string) => {
  switch (type) {
    case 'Texto comum':
      return { label: 'Texto comum', value: '1' };
    case 'Nota de Rodapé':
      return { label: 'Nota de rodapé', value: '2' };
  }
};

export const getFormattedInitialData = (textData: SingleTextData | undefined) => {
  return {
    name: textData?.nome,
    type: getInitialType(textData?.tipo as string),
    sections: textData?.secoes.map((section) => ({ label: section.tituloSecao, value: section.idSecao } as OptionType)),
    text: textData?.valorTexto,
    variables: textData?.variaveis,
    footnotes: textData?.notasRodapes,
  };
};
