import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LegalAreaFormValues } from '../../types/LegalArea.types';
import FormGroup from '../../components/Form/FormGroup';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import Box from '../../components/Box';
import Heading from '../../components/Text/Heading';
import { StyledForm } from './styles';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import TextArea from '../../components/Form/TextArea';
import { DialogCancelCreateUpdate } from '../shared/DialogCancelCreateUpdate';

interface LegalAreaFormProps {
  type: 'create' | 'update' | 'view';
  initialData?: LegalAreaFormValues;
  onSubmit: (data: LegalAreaFormValues) => void;
}

const validationSchema = yup.object({
  name: yup.string().required('O campo nome é obrigatório'),
});

const LegalAreaForm = ({ type, initialData, onSubmit }: LegalAreaFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LegalAreaFormValues>({
    defaultValues: initialData,
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();
  const navigateToLegalAreas = useCallback(() => {
    navigate('/legal-areas');
  }, []);

  const title = {
    create: 'Cadastro de área jurídica',
    update: 'Editar área jurídica',
    view: 'Visualizar área jurídica',
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box
          footer={
            type === 'create' || type === 'update' ? (
              <>
                <DialogCancelCreateUpdate type={type} navigateToListPage={navigateToLegalAreas} />

                <Button className="submit-button" type="submit">
                  Salvar
                </Button>
              </>
            ) : (
              <Button className="cancel-button" kind="alert" onClick={navigateToLegalAreas}>
                Voltar
              </Button>
            )
          }>
          <div className="content">
            <Heading>{title[type]}</Heading>
            <FormGroup error={errors.name?.message}>
              <Input {...register('name')} label="Nome da área jurídica" disabled={type === 'view' ? true : false} />
            </FormGroup>
            <FormGroup error={errors.description?.message}>
              <TextArea
                {...register('description')}
                label="Descrição da área jurídica"
                disabled={type === 'view' ? true : false}
              />
            </FormGroup>
          </div>
        </Box>
      </StyledForm>
    </>
  );
};

export default LegalAreaForm;
