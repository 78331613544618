import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import DataTable from '../../components/DataTable';
import Heading from '../../components/Text/Heading';
import Button from '../../components/Form/Button';
import { MagnifyingGlassIcon, UserIcon } from '@heroicons/react/24/outline';
import { Container } from './styles';
import Input from '../../components/Form/Input';
import { UserData } from '../../types/User.types';
import { userService } from '../../api/services';
import { formatDate } from '../../components/DataTable/utils';
import UserActions from './UserActions';
import { permissionsList } from './utils';

const Users = () => {
  const [sections, setUsers] = useState<UserData[]>([]);
  const [search, setSearch] = useState<string>('');
  const [deletedItem, setDeletedItem] = useState<string>('');

  const handleSearch = useCallback((e: { target: { value: SetStateAction<string> } }) => {
    setSearch(e.target.value);
  }, []);

  const permissions = useCallback(
    (info: CellContext<UserData, unknown>) => (
      <>
        {(info.getValue() as string)
          ?.split('|')
          .map((permission) => permissionsList[permission]?.label)
          .join(', ')}
      </>
    ),
    []
  );

  const DateFormatter = useCallback(
    (info: CellContext<UserData, unknown>) => formatDate(info.getValue() as string),
    []
  );

  const actions = useCallback(
    (info: CellContext<UserData, unknown>) => (
      <UserActions setDeletedItem={setDeletedItem} id={info.row.getValue('idUsuario')} />
    ),
    []
  );

  const columns = useMemo<ColumnDef<UserData>[]>(
    () => [
      {
        accessorKey: 'idUsuario',
        header: 'Id',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'email',
        header: 'Email do usuário',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'permissao',
        header: 'Seções de acesso',
        cell: permissions,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataCriacao',
        header: 'Data da criação',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataAlteracao',
        header: 'Última alteração',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'usuarioCriacao',
        header: 'Criado por',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'action',
        header: '',
        cell: actions,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  useEffect(() => {
    async function getUsers() {
      const result = await userService.list();
      if (result) {
        setUsers(result);
      }
    }

    getUsers();
  }, [deletedItem]);

  const navigate = useNavigate();
  const navigateToCreateUser = useCallback(() => {
    navigate('/users/create');
  }, []);

  return (
    <Container>
      <div className="header">
        <Heading>Usuários</Heading>
        <Button onClick={navigateToCreateUser}>
          <UserIcon />
          Cadastrar novo usuário
        </Button>
      </div>

      <div className="search-filter-bar">
        <Input
          name="search"
          icon={<MagnifyingGlassIcon />}
          placeholder="Procurar por e-mail do usuário ou sessóes de acesso"
          onChange={handleSearch}
        />
      </div>
      <DataTable<UserData>
        columnVisibility={{ idUsuario: false }}
        globalFilter={search}
        data={sections}
        columns={columns}
      />
    </Container>
  );
};

export default Users;
