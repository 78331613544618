import { useCallback, useEffect, useRef, useState } from 'react';

import { StyledTable, Pagination } from './styles';

import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  Updater,
  VisibilityState,
  PaginationState,
  getPaginationRowModel,
} from '@tanstack/react-table';

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';
import React from 'react';
import Loading from '../Loading';

type PaginatedDataTableProps<T> = {
  data: T[];
  columns: ColumnDef<T>[];
  columnVisibility?: VisibilityState;
  globalFilter?: string;
  onPaginationChange?: any;
  onSortingChange?: any;
  pagination: PaginationState;
  paginationResetTrigger: boolean;
  loading: boolean;
  sorting: any;
  pageCount: number;
};

export function usePagination(initialSize = 5) {
  const [pagination, setPagination] = useState({
    pageSize: initialSize,
    pageIndex: 0,
  });
  const { pageSize, pageIndex } = pagination;

  return {
    // table state
    onPaginationChange: setPagination,
    pagination,
    // API
    limit: pageSize,
    skip: pageSize * pageIndex,
  };
}

export function useSorting(initialField = 'id', initialOrder = 'ASC') {
  const [sorting, setSorting] = useState([{ id: initialField, desc: initialOrder === 'DESC' }]);

  return {
    sorting,
    onSortingChange: setSorting,
    order: !sorting.length ? initialOrder : sorting[0].desc ? 'DESC' : 'ASC',
    field: sorting.length ? sorting[0].id : initialField,
  };
}

function PaginatedDataTable<T>({
  data,
  columns,
  columnVisibility,
  globalFilter,
  onPaginationChange,
  onSortingChange,
  loading,
  pageCount,
  pagination,
  paginationResetTrigger,
  sorting,
}: PaginatedDataTableProps<T>) {
  const table = useReactTable({
    manualPagination: true,
    manualSorting: true,
    onPaginationChange,
    onSortingChange,
    data,
    columns,
    state: {
      sorting,
      globalFilter,
      columnVisibility,
      pagination,
    },
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const isMounted = useRef(false);
  const setPageIndex = useCallback(
    (pageIndex: Updater<number>) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      table.setPageIndex(pageIndex);
    },
    []
  );

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    setFirstPage();
  }, [paginationResetTrigger]);

  const setFirstPage = useCallback(() => {
    table.setPageIndex(0);
  }, []);

  const setLastPage = useCallback(() => {
    table.setPageIndex(table.getPageCount() - 1);
  }, []);

  const getPageItems = () => {
    const maxPages = table.getPageCount();
    const currentPage = table.getState().pagination.pageIndex + 1;

    let leftSide = currentPage - 3;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = currentPage + 3;
    if (rightSide > maxPages) rightSide = maxPages;

    const items = [];

    for (let number = leftSide; number <= rightSide; number++) {
      items.push(
        <button onClick={setPageIndex(number - 1)} key={number} className={number === currentPage ? 'active' : ''}>
          {number}
        </button>
      );
    }

    return items;
  };

  return (
    <StyledTable>
      {!loading ? (
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} className={header.column.getCanSort() ? 'sort' : ''}>
                      <div
                        {...{
                          onClick: header.column.getToggleSortingHandler(),
                        }}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort()
                          ? {
                              asc: <ChevronUpIcon />,
                              desc: <ChevronDownIcon />,
                            }[header.column.getIsSorted() as string] ?? <ChevronUpDownIcon />
                          : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
      <Pagination>
        <button className="arrow" disabled={!table.getCanPreviousPage()} onClick={setFirstPage}>
          <ChevronDoubleLeftIcon />
        </button>
        <button
          className="arrow"
          data-testid="previous-button"
          onClick={table.previousPage}
          disabled={!table.getCanPreviousPage()}>
          <ChevronLeftIcon />
        </button>
        {getPageItems()}
        <button className="arrow" data-testid="next-button" onClick={table.nextPage} disabled={!table.getCanNextPage()}>
          <ChevronRightIcon />
        </button>
        <button className="arrow" disabled={!table.getCanNextPage()} onClick={setLastPage}>
          <ChevronDoubleRightIcon />
        </button>
      </Pagination>
    </StyledTable>
  );
}

export default PaginatedDataTable;
