import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid ${theme.colors.grey[200]};
  padding: ${theme.spacings[3]};
  background: ${theme.colors.neutral.white};
  min-width: 40rem;

  a {
    color: ${theme.colors.teal[500]};
  }

  h1 {
    margin: ${theme.spacings[1]} 0;
  }
`;
