import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import Heading from '../../components/Text/Heading';
import { DocumentDuplicateIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Container } from './styles';
import Input from '../../components/Form/Input';
import { TextData } from '../../types/Text.types';
import { textService } from '../../api/services';
import { getBadge } from './utils';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Form/Button';
import { formatDate } from '../../components/DataTable/utils';
import TextActions from './TextActions';
import Badge from '../../components/Text/Badge';
import Tooltip from '../../components/Tooltip';
import PaginatedDataTable, { usePagination, useSorting } from '../../components/PaginatedDataTable';

const Texts = () => {
  const [texts, setTexts] = useState<TextData[]>([]);
  const [deletedItem, setDeletedItem] = useState<string>('');

  const { limit, onPaginationChange, skip, pagination } = usePagination(10);
  const { sorting, onSortingChange, field, order } = useSorting('dataCriacao', 'DESC');
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [resetPagination, setPaginationTrigger] = useState(false); // Inverter valor para reiniciar a paginaçao
  const [search, setSearch] = useState<string>('');

  const handleSearch = useCallback((e: { target: { value: SetStateAction<string> } }) => {
    setSearch(e.target.value);
  }, []);

  const TextType = useCallback(
    (info: CellContext<TextData, unknown>) => <>{getBadge(info.getValue() as string)}</>,
    []
  );

  const DateFormatter = useCallback(
    (info: CellContext<TextData, unknown>) => formatDate(info.getValue() as string),
    []
  );

  const TextSections = useCallback(
    (info: CellContext<TextData, unknown>) => (
      <Tooltip content={(info.row.getValue('secoes') as string[]).join(', ')}>
        <div className="sections">
          <Badge color="red">{info.row.getValue('qtdeSecoes')}</Badge>
        </div>
      </Tooltip>
    ),
    []
  );

  const actions = useCallback(
    (info: CellContext<TextData, unknown>) => (
      <TextActions setDeletedItem={setDeletedItem} id={info.row.getValue('idTexto')} name={info.row.getValue('nome')} />
    ),
    []
  );

  const columns = useMemo<ColumnDef<TextData>[]>(
    () => [
      {
        accessorKey: 'idTexto',
        header: 'Id',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'qtdeSecoes',
        header: 'Quantidade de seções',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'nome',
        header: 'Nome do texto',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'secoes',
        header: 'Seções',
        cell: TextSections,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'tipo',
        header: 'Tipo',
        cell: TextType,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataCriacao',
        header: 'Data da criação',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataAlteracao',
        header: 'Última alteração',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'usuarioCriacao',
        header: 'Criado por',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'action',
        header: '',
        cell: actions,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  useEffect(() => {
    fetchData();
  }, [pagination, sorting, deletedItem]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (search.length < 3 && search.length != 0) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      fetchData(true);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const fetchData = async (_resetPagination = false) => {
    setLoading(true);
    try {
      const result = await textService.listPaged({
        pagination: { skip: _resetPagination ? 0 : skip, limit },
        sort: { field, order },
        query: search,
      });

      if (result) {
        setTexts(result.data);
        setCount(result.count);
        if (_resetPagination) {
          setPaginationTrigger(!resetPagination); //Retorna para pagina 1 após a pesquisa
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const pageCount = Math.ceil(count / limit);
  const navigate = useNavigate();
  const navigateToCreateText = useCallback(() => {
    navigate('/texts/create');
  }, []);

  return (
    <Container>
      <div className="header">
        <Heading>Textos</Heading>
        <Button onClick={navigateToCreateText}>
          <DocumentDuplicateIcon />
          Cadastrar novo texto
        </Button>
      </div>

      <div className="search-filter-bar">
        <Input
          name="search"
          icon={<MagnifyingGlassIcon />}
          placeholder="Procurar por nome do texto, tipo, criado por..."
          onChange={handleSearch}
        />
      </div>
      <PaginatedDataTable<TextData>
        columnVisibility={{ idTexto: false, qtdeSecoes: false }}
        data={texts}
        columns={columns}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        pagination={pagination}
        paginationResetTrigger={resetPagination}
        loading={loading}
        pageCount={pageCount}
        sorting={sorting}
      />
    </Container>
  );
};

export default Texts;
