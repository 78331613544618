import { AuthDataRequest, AuthDataResponse } from '../../types/Auth.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface IAuthApiClient {
  authenticate(data: AuthDataRequest): Promise<AuthDataResponse | undefined>;
}

export class AuthApiClient implements IAuthApiClient {
  AuthApiClient: IApiClient;

  constructor(AuthApiClient: IApiClient) {
    this.AuthApiClient = AuthApiClient;
  }

  async authenticate(data: AuthDataRequest): Promise<AuthDataResponse | undefined> {
    const response = await this.AuthApiClient.post<AuthDataRequest, AuthDataResponse>(`/authenticate`, data);
    return response;
  }
}

export default class AuthService {
  userApiClient: IAuthApiClient;

  constructor() {
    this.userApiClient = new AuthApiClient(new ApiClient());
  }

  async authenticate(data: AuthDataRequest): Promise<AuthDataResponse | undefined> {
    return this.userApiClient.authenticate(data);
  }
}
