import { MediumParagraph, SmallParagraph } from './styles';

interface ParagraphProps {
  children: string | React.ReactNode;
  className?: string;
  size?: 'small' | 'medium';
}

const Paragraph = ({ size, ...props }: ParagraphProps) => {
  switch (size) {
    case 'small':
      return <SmallParagraph {...props} />;
    case 'medium':
    default:
      return <MediumParagraph {...props} />;
  }
};

export default Paragraph;
