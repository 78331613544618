import styled, { keyframes } from 'styled-components';
import { theme } from '../../styles/themes/default';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: ${theme.colors.neutral.white};
  z-index: 300;
  animation: ${fadeIn} 0.1s;
`;

export const Sidebar = styled.div`
  width: 23rem;
  border-right: 0.1rem solid ${theme.colors.grey[200]};
  padding: ${theme.spacings[4]} ${theme.spacings[2]};

  button {
    width: 100%;
    font-size: ${theme.fonts['x-small']};
    font-weight: bold;
  }

  h1 {
    margin: ${theme.spacings[3]} 0 ${theme.spacings[1]};
  }

  .menu-items {
    border-bottom: 0.1rem solid ${theme.colors.grey[200]};
    button {
      width: 100%;
      font-size: ${theme.fonts['x-small']};
      justify-content: flex-start;
      font-weight: normal;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  width: calc(100% - 23rem);
  background-color: ${theme.colors.grey[50]};
  padding-bottom: 7rem;

  > div,
  > form {
    max-height: 100vh;
    width: 100%;
    overflow: auto;
    padding: ${theme.spacings[3]};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
`;
