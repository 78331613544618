import { StyledBox, StyledFooter } from './styles';

interface BoxProps {
  children: React.ReactNode;
  className?: string;
  footer?: React.ReactNode;
  grid?: number;
  border?: boolean;
  padding?: boolean;
  background?: boolean;
  type?: 'page' | 'suspense-page' | 'full-page';
}

const Box = ({
  children,
  className = '',
  footer,
  grid = 1,
  border = true,
  padding = true,
  background = true,
  type = 'page',
}: BoxProps) => {
  return (
    <StyledBox
      className={`${className} grid-${grid}${!border ? ' no-border' : ''}${!padding ? ' no-padding' : ''}${
        !background ? ' no-background' : ''
      } ${type}`}>
      {children}
      {footer && <StyledFooter className={type}>{footer}</StyledFooter>}
    </StyledBox>
  );
};

export default Box;
