import styled, { css } from 'styled-components';
import { theme } from '../../../styles/themes/default';
import { LabelStyles } from '../../Form/styles';
import { Text } from '../styles';

const BaseParagraph = css`
  font-weight: 400;
  &.label {
    ${LabelStyles};
    margin-bottom: 0;
  }
`;

export const MediumParagraph = styled(Text).attrs(() => ({
  as: 'p',
}))`
  ${BaseParagraph}
  font-size: ${theme.fonts['x-small']};
  line-height: ${theme.fonts['large']};
`;

export const SmallParagraph = styled(Text).attrs(() => ({
  as: 'p',
}))`
  ${BaseParagraph}
  font-size: ${theme.fonts['xx-small']};
  line-height: ${theme.fonts['small']};
`;
