import styled from 'styled-components';
import { theme } from '../../../styles/themes/default';
import { InputTextStyles, LabelStyles } from '../styles';

export const TextAreaContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  &.full-width {
    width: 100%;
  }

  svg {
    height: 1.3rem;
    position: absolute;
    padding-left: ${theme.spacings[2]};
    stroke: ${theme.colors.grey[900]};
  }

  svg + textarea {
    padding-left: 4.4rem;
  }
`;

export const Label = styled.label`
  ${LabelStyles}
`;

export const StyledTextArea = styled.textarea.attrs({
  placeholderTextColor: theme.colors.grey[400],
})`
  ${InputTextStyles}
`;
