import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Proxima Nova', sans-serif;
  }

  html {
    font-size: 62.5%;
  }

  body {
    ${({ theme }) => css`
      background: ${theme.colors.grey[50]};
    `}
  }

  html, body, #root {
    height: 100%;
  }

  #full-page-root, #suspense-page-root {
    > div {
      &[dir='ltr'], &[data-radix-popper-content-wrapper] {
        ${({ theme }) => css`
          z-index: 300 !important;
          min-width: 14rem !important;
          background: ${theme.colors.neutral.white};
        `}
      }
    }
  }

  .tox-promotion {
    display: none;
  }
`;
