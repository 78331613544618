import * as DropdownMenuComponent from '@radix-ui/react-dropdown-menu';

type DropdownMenuProps = {
  children: string | React.ReactNode;
  content: string | React.ReactNode;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const DropdownMenu = ({ children, content, open, setOpen }: DropdownMenuProps) => {
  return (
    <DropdownMenuComponent.Root open={open} onOpenChange={setOpen}>
      <DropdownMenuComponent.Trigger asChild>{children}</DropdownMenuComponent.Trigger>
      {content}
    </DropdownMenuComponent.Root>
  );
};

export default DropdownMenu;
