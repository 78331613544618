import { Root, Indicator } from './styles';

type ProgressBarProps = {
  progress: number;
};

const ProgressBar = ({ progress }: ProgressBarProps) => {
  return (
    <Root value={progress}>
      <Indicator className="ProgressIndicator" style={{ transform: `translateX(-${100 - progress}%)` }} />
    </Root>
  );
};

export default ProgressBar;
