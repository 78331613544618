import { useCallback, useEffect, useState } from 'react';
import TextForm from './TextForm';
import { TextFormValues, InitialTextForm } from '../../types/Text.types';
import { textService } from '../../api/services';
import { getFormattedInitialData } from './utils';
import { showToast } from '../../components/Toast/showToast';

interface SuspenseUpdateTextProps {
  id: string;
  setBpmnText: (textId: string, name: string) => () => void;
  closeContent: () => void;
}
const SuspenseUpdateText = ({ id, setBpmnText, closeContent }: SuspenseUpdateTextProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<InitialTextForm>({
    name: '',
    type: { label: 'Texto comum', value: '1' },
    sections: [],
    text: '',
    variables: [],
    footnotes: [],
  });

  useEffect(() => {
    async function getInitialData() {
      const textData = await textService.getById(id);
      setInitialData(getFormattedInitialData(textData));
      setIsLoading(false);
    }
    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: TextFormValues) => {
    const successfullyUpdated = await textService.update(id, {
      nome: data.name,
      valor: data.text,
      tipo: parseInt(data.type?.value as string),
      secoes: data.sections?.map((section) => section.value),
    });
    if (successfullyUpdated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao atualizar texto.',
      });
      setBpmnText(id, data.name as string)();
      closeContent();
    }
  }, []);

  return !isLoading ? (
    <>
      <TextForm
        type="update"
        boxType="suspense-page"
        initialData={initialData}
        onSubmit={handleSubmit}
        onCancel={closeContent}
      />
    </>
  ) : null;
};

export default SuspenseUpdateText;
