import { NavLink, Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Menu from '../components/Menu';
import { Container, Content, Page, ProtectedRouteContainer } from './styles';
import Heading from '../components/Text/Heading';
import Paragraph from '../components/Text/Paragraph';

interface ProtectedRouteProps {
  isAllowed: boolean;
}

const ProtectedRoute = ({ isAllowed }: ProtectedRouteProps) => {
  if (!isAllowed) {
    return (
      <Container>
        <ProtectedRouteContainer>
          <Heading>Não autorizado</Heading>
          <Paragraph>
            Clique <NavLink to="/">aqui</NavLink> para voltar para a página inicial.
          </Paragraph>
        </ProtectedRouteContainer>
      </Container>
    );
  }

  return (
    <>
      <Header />
      <Content>
        <Menu />
        <Page>
          <Outlet />
        </Page>
      </Content>
    </>
  );
};
export default ProtectedRoute;
