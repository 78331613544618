import { Base64 } from 'js-base64';

export const getEncodedUserData = (usuario: { email: string; permissao: string }) => {
  return Base64.encode(`${usuario.email}*${usuario.permissao}`);
};

export const getDecodedUserData = (encodedUserData: string) => {
  if (encodedUserData) {
    const decodedUserData = Base64.decode(encodedUserData);
    const [email, permissions] = decodedUserData.split('*');
    return {
      email,
      permissions: permissions != '' ? permissions.split('|') : null,
    };
  }
};
