import {
  PagedVariableData,
  PagedVariableDataRequest,
  SingleVariableData,
  VariableData,
  VariableDataCreateRequest,
  VariableDataCreateResponse,
  VariableDataDeleteResponse,
  VariableTypeData,
} from '../../types/Variable.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface IVariableApiClient {
  create(data: VariableDataCreateRequest): Promise<VariableDataCreateResponse | undefined>;
  list(): Promise<VariableData[] | undefined>;
  listPaged(data: PagedVariableDataRequest): Promise<PagedVariableData | undefined>;
  getById(id: string): Promise<SingleVariableData | undefined>;
  update(id: string, data: VariableDataCreateRequest): Promise<VariableData | undefined>;
  delete(id: string): Promise<VariableDataDeleteResponse | undefined>;
  getTypes(): Promise<VariableTypeData[] | undefined>;
}

export class VariableApiClient implements IVariableApiClient {
  variableApiClient: IApiClient;

  constructor(VariableApiClient: IApiClient) {
    this.variableApiClient = VariableApiClient;
  }

  async create(data: VariableDataCreateRequest): Promise<VariableDataCreateResponse | undefined> {
    const response = await this.variableApiClient.post<VariableDataCreateRequest, VariableDataCreateResponse>(
      `/variaveis`,
      data
    );
    return response;
  }

  async list(): Promise<VariableData[] | undefined> {
    const response = await this.variableApiClient.get<VariableData[]>(`/variaveis`);
    return response;
  }

  async listPaged(data: PagedVariableDataRequest): Promise<PagedVariableData | undefined> {
    const response = await this.variableApiClient.post<PagedVariableDataRequest, PagedVariableData>(
      `/variaveis_paged`,
      data
    );
    return response;
  }

  async getById(id: string): Promise<SingleVariableData | undefined> {
    const response = await this.variableApiClient.get<SingleVariableData>(`/variaveis/${id}`);
    return response;
  }

  async update(id: string, data: VariableDataCreateRequest): Promise<VariableDataCreateResponse | undefined> {
    const response = await this.variableApiClient.put<VariableDataCreateRequest, VariableDataCreateResponse>(
      `/variaveis/${id}`,
      data
    );
    return response;
  }

  async delete(id: string): Promise<VariableDataDeleteResponse | undefined> {
    const response = await this.variableApiClient.delete<VariableDataDeleteResponse>(`/variaveis/${id}`);
    return response;
  }

  async getTypes(): Promise<VariableTypeData[] | undefined> {
    const response = await this.variableApiClient.get<VariableTypeData[]>(`/tipos-variaveis`);
    return response;
  }
}

export default class VariableService {
  variableApiClient: IVariableApiClient;

  constructor() {
    this.variableApiClient = new VariableApiClient(new ApiClient());
  }

  async create(data: VariableDataCreateRequest): Promise<VariableDataCreateResponse | undefined> {
    return this.variableApiClient.create(data);
  }

  async list(): Promise<VariableData[] | undefined> {
    return this.variableApiClient.list();
  }

  async listPaged(data: PagedVariableDataRequest): Promise<PagedVariableData | undefined> {
    return this.variableApiClient.listPaged(data);
  }

  async getById(id: string): Promise<SingleVariableData | undefined> {
    return this.variableApiClient.getById(id);
  }

  async update(id: string, data: VariableDataCreateRequest): Promise<VariableDataCreateResponse | undefined> {
    return this.variableApiClient.update(id, data);
  }

  async delete(id: string): Promise<VariableDataDeleteResponse | undefined> {
    return this.variableApiClient.delete(id);
  }

  async getTypes(): Promise<VariableTypeData[] | undefined> {
    return this.variableApiClient.getTypes();
  }
}
