import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConditionForm from './ConditionForm';
import { ConditionFormValues } from '../../types/Condition.types';
import { conditionService } from '../../api/services';
import { getCreateData, getFormattedInitialData } from './utils';
import { showToast } from '../../components/Toast/showToast';

const UpdateCondition = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<ConditionFormValues>({
    name: '',
    description: '',
    options: [
      { optionId: '', optionValue: '', optionChkVariable: false, optionVariableId: '', optionVariableValue: '' },
    ],
    quantityOfModels: 0,
  });

  const navigate = useNavigate();
  const navigateToListConditions = useCallback(() => {
    navigate('/conditions');
  }, []);

  useEffect(() => {
    async function getInitialData() {
      const conditionData = await conditionService.getById(id as string);
      const formattedInitialData = getFormattedInitialData(conditionData);
      setInitialData({ ...formattedInitialData, name: `Cópia - ${formattedInitialData.name}` });
      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: ConditionFormValues) => {
    const successfullyUpdated = await conditionService.create(getCreateData(data));
    if (successfullyUpdated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar condição.',
      });
      navigateToListConditions();
    }
  }, []);

  return !isLoading ? (
    <>
      <ConditionForm
        type="duplicate"
        initialData={initialData}
        onSubmit={handleSubmit}
        onCancel={navigateToListConditions}
      />
    </>
  ) : null;
};

export default UpdateCondition;
