import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Heading from '../../components/Text/Heading';
import { useAppDispatch, useAppSelector } from '../../hooks/useStore';
import { userLogin } from '../../store/slices/user';
import { Container, LoginContainer, Form, Cover } from './styles';
import Logo from '../../assets/my-legal-docs-logo-white.svg';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import Paragraph from '../../components/Text/Paragraph';
import { getDecodedUserData } from '../../store/slices/user/utils';
import { pageByPermission } from '../Users/utils';

type LoginFormValues = {
  email: string;
  senha: string;
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading, userToken, userData } = useAppSelector((state) => state.user);
  const user = getDecodedUserData(userData as string);
  const { register, handleSubmit } = useForm<LoginFormValues>();

  useEffect(() => {
    if (
      userToken &&
      user &&
      user?.permissions &&
      user?.permissions?.length > 0 &&
      pageByPermission[user.permissions[0]]
    ) {
      navigate(pageByPermission[user.permissions[0]]);
    }
  }, [navigate, userToken]);

  const onSubmit = handleSubmit((data) => {
    dispatch(userLogin(data));
  });

  return (
    <Container>
      <Cover>
        <img src={Logo} />
      </Cover>
      <LoginContainer>
        <Form onSubmit={onSubmit}>
          <>
            <Heading size="display">Seja bem vindo!</Heading>
            <Input
              {...register('email')}
              placeholder="Digite seu e-mail"
              type="email"
              label="E-mail"
              fullWidth
              required
            />
            <Input
              {...register('senha')}
              placeholder="Digite sua senha"
              type="password"
              label="Senha"
              fullWidth
              required
              toggleShowContent={true}
              showContent={false}
            />
            <Button fullWidth type="submit" disabled={loading}>
              Entrar
            </Button>
          </>
        </Form>
        <Paragraph size="small">
          Caso esteja enfrentando problemas para acessar a plataforma, contate um administrador do sistema.
        </Paragraph>
      </LoginContainer>
    </Container>
  );
};

export default Login;
