import {
  ConditionDataCreateRequest,
  ConditionData,
  ConditionDataUpdateDeleteResponse,
  SingleConditionData,
  PagedConditionDataRequest,
  PagedConditionData,
  ConditionDataUpdateRequest,
} from '../../types/Condition.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface IConditionApiClient {
  create(data: ConditionDataCreateRequest): Promise<ConditionData | undefined>;
  list(): Promise<ConditionData[] | undefined>;
  listPaged(data: PagedConditionDataRequest): Promise<PagedConditionData | undefined>;
  getById(id: string): Promise<SingleConditionData | undefined>;
  update(id: string, data: ConditionDataCreateRequest): Promise<ConditionDataUpdateDeleteResponse | undefined>;
  delete(id: string): Promise<ConditionDataUpdateDeleteResponse | undefined>;
}

export class ConditionApiClient implements IConditionApiClient {
  conditionApiClient: IApiClient;

  constructor(conditionApiClient: IApiClient) {
    this.conditionApiClient = conditionApiClient;
  }

  async create(data: ConditionDataCreateRequest): Promise<ConditionData | undefined> {
    const response = await this.conditionApiClient.post<ConditionDataCreateRequest, ConditionData>(`/condicoes`, data);
    return response;
  }

  async list(): Promise<ConditionData[] | undefined> {
    const response = await this.conditionApiClient.get<ConditionData[]>(`/condicoes`);
    return response;
  }

  async listPaged(data: PagedConditionDataRequest): Promise<PagedConditionData | undefined> {
    const response = await this.conditionApiClient.post<PagedConditionDataRequest, PagedConditionData>(
      `/condicoes_paged`,
      data
    );
    return response;
  }

  async getById(id: string): Promise<SingleConditionData | undefined> {
    const response = await this.conditionApiClient.get<SingleConditionData>(`/condicoes/${id}`);
    return response;
  }

  async update(id: string, data: ConditionDataUpdateRequest): Promise<ConditionDataUpdateDeleteResponse | undefined> {
    const response = await this.conditionApiClient.put<ConditionDataUpdateRequest, ConditionDataUpdateDeleteResponse>(
      `/condicoes/${id}`,
      data
    );
    return response;
  }

  async delete(id: string): Promise<ConditionDataUpdateDeleteResponse | undefined> {
    const response = await this.conditionApiClient.delete<ConditionDataUpdateDeleteResponse>(`/condicoes/${id}`);
    return response;
  }
}

export default class ConditionService {
  conditionApiClient: IConditionApiClient;

  constructor() {
    this.conditionApiClient = new ConditionApiClient(new ApiClient());
  }

  async create(data: ConditionDataCreateRequest): Promise<ConditionData | undefined> {
    return this.conditionApiClient.create(data);
  }

  async list(): Promise<ConditionData[] | undefined> {
    return this.conditionApiClient.list();
  }

  async listPaged(data: PagedConditionDataRequest): Promise<PagedConditionData | undefined> {
    return this.conditionApiClient.listPaged(data);
  }

  async getById(id: string): Promise<SingleConditionData | undefined> {
    return this.conditionApiClient.getById(id);
  }

  async update(id: string, data: ConditionDataCreateRequest): Promise<ConditionDataUpdateDeleteResponse | undefined> {
    return this.conditionApiClient.update(id, data);
  }

  async delete(id: string): Promise<ConditionDataUpdateDeleteResponse | undefined> {
    return this.conditionApiClient.delete(id);
  }
}
