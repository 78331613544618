import { useCallback } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Container, Root, Thumb } from './styles';

interface SwitchProps {
  name: string;
  label?: string | React.ReactNode;
  checked?: boolean;
  onCheckedChange?: () => void;
  position?: 'left' | 'right';
  disabled?: boolean;
  defaultValue?: boolean;
}

const Switch = (props: SwitchProps) => {
  const { name, label, checked, onCheckedChange, position = 'left', disabled = false } = props;

  return (
    <Container position={position}>
      <Root id={name} onCheckedChange={onCheckedChange} checked={checked} disabled={disabled}>
        <Thumb />
      </Root>
      <label htmlFor={name}>{label}</label>
    </Container>
  );
};

interface SwitchFormProps extends SwitchProps {
  control: Control<any>;
}
export const SwitchForm = ({ position, control, name, label, disabled, defaultValue = false }: SwitchFormProps) => {
  const renderSwitch = useCallback(
    ({ field: { onChange, value } }: { field: any }) => (
      <Switch
        label={label}
        name={name}
        checked={value}
        position={position}
        disabled={disabled}
        onCheckedChange={onChange}
      />
    ),
    []
  );

  return <Controller control={control} name={name} defaultValue={defaultValue} render={renderSwitch} />;
};

export default Switch;
