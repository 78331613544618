import styled from 'styled-components';
import { theme } from '../../../styles/themes/default';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: ${theme.spacings[2]} 0;
`;

export const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})`
  cursor: pointer;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  &:checked ~ .check:after {
    display: block;
  }
  &:checked ~ .check {
    border-color: ${theme.colors.blue[600]};
    background: ${theme.colors.blue[600]};
  }
`;

export const Check = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.8rem;
  width: 1.8rem;
  background: ${theme.colors.blue[50]};
  border: 0.1rem solid ${theme.colors.grey[300]};
  transition: 100ms ease-in-out;
  border-radius: 0.2rem;
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 0.5rem;
    top: 0.2rem;
    width: 0.4rem;
    height: 0.8rem;
    border: 0.15rem solid ${theme.colors.blue[50]};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const Label = styled.label`
  font-size: ${theme.fonts['x-small']};
  display: flex;
  align-items: center;
  position: relative;
  color: ${theme.colors.gray};
  cursor: pointer;
  user-select: none;
  padding-left: ${theme.spacings[3]};
`;
