import styled from 'styled-components';
import { theme } from '../../../styles/themes/default';

const badgeStyle = `
  display: inline-block;
  border-radius: 2.4rem;
  padding: ${theme.spacings[0.5]} ${theme.spacings[1.5]};
  font-size: ${theme.fonts['xx-small']};
  line-height: ${theme.fonts['small']};
`;

export const BlueBadge = styled.div`
  ${badgeStyle};
  background: ${theme.colors.blue[100]};
  color: ${theme.colors.blue[800]};
`;

export const GreenBadge = styled.div`
  ${badgeStyle};
  background: ${theme.colors.green[200]};
  color: ${theme.colors.green[600]};
`;

export const RedBadge = styled.div`
  ${badgeStyle};
  background: #fce8f3;
  color: #d61f69;
`;
