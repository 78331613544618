import {
  BriefcaseIcon,
  ScaleIcon,
  ArrowsRightLeftIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  UserIcon,
  ClipboardDocumentIcon,
  Square3Stack3DIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';
import { StyledMenu } from './styles';
import Tooltip from '../Tooltip';
import { useAppSelector } from '../../hooks/useStore';
import { getDecodedUserData } from '../../store/slices/user/utils';

const Menu = () => {
  const { userToken, userData } = useAppSelector((state) => state.user);
  const user = getDecodedUserData(userData as string);

  return (
    <StyledMenu>
      {((!!userToken && user?.permissions?.includes('areas-juridicas')) as boolean) && (
        <NavLink to="/legal-areas">
          <Tooltip side="right" content="Áreas jurídicas">
            <BriefcaseIcon />
          </Tooltip>
        </NavLink>
      )}
      {((!!userToken && user?.permissions?.includes('secoes')) as boolean) && (
        <NavLink to="/sections">
          <Tooltip side="right" content="Seções">
            <ScaleIcon />
          </Tooltip>
        </NavLink>
      )}
      {((!!userToken && user?.permissions?.includes('condicoes')) as boolean) && (
        <NavLink to="/conditions">
          <Tooltip side="right" content="Condições">
            <DocumentTextIcon />
          </Tooltip>
        </NavLink>
      )}
      {((!!userToken && user?.permissions?.includes('documentos')) as boolean) && (
        <NavLink to="/documents">
          <Tooltip side="right" content="Documentos">
            <ClipboardDocumentIcon />
          </Tooltip>
        </NavLink>
      )}
      {((!!userToken && user?.permissions?.includes('variaveis')) as boolean) && (
        <NavLink to="/variables">
          <Tooltip side="right" content="Variáveis">
            <ArrowsRightLeftIcon />
          </Tooltip>
        </NavLink>
      )}
      {((!!userToken && user?.permissions?.includes('textos')) as boolean) && (
        <NavLink to="/texts">
          <Tooltip side="right" content="Textos">
            <DocumentDuplicateIcon />
          </Tooltip>
        </NavLink>
      )}
      {((!!userToken && user?.permissions?.includes('modelos')) as boolean) && (
        <NavLink to="/models">
          <Tooltip side="right" content="Modelos">
            <Square3Stack3DIcon />
          </Tooltip>
        </NavLink>
      )}
      {((!!userToken && user?.permissions?.includes('templates')) as boolean) && (
        <NavLink to="/templates">
          <Tooltip side="right" content="Templates">
            <ClipboardDocumentListIcon />
          </Tooltip>
        </NavLink>
      )}
      {((!!userToken && user?.permissions?.includes('usuarios')) as boolean) && (
        <NavLink to="/users">
          <Tooltip side="right" content="Usuários">
            <UserIcon />
          </Tooltip>
        </NavLink>
      )}
    </StyledMenu>
  );
};

export default Menu;
