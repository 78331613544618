import { ExtratoLogData, ExtratoLogDataCreateRequest } from '../../types/ExtratoLog.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface IExtratoLogApiClient {
  create(data: ExtratoLogDataCreateRequest): Promise<ExtratoLogData | undefined>;
  list(): Promise<ExtratoLogData[] | undefined>;
  getById(id: string): Promise<ExtratoLogData | undefined>;
}

export class ExtratoLogApiClient implements IExtratoLogApiClient {
  extratoApiClient: IApiClient;

  constructor(ExtratoLogApiClient: IApiClient) {
    this.extratoApiClient = ExtratoLogApiClient;
  }

  async create(data: ExtratoLogDataCreateRequest): Promise<ExtratoLogData | undefined> {
    const response = await this.extratoApiClient.post<ExtratoLogDataCreateRequest, ExtratoLogData>(
      `/extratoLogs`,
      data
    );
    return response;
  }

  async list(): Promise<ExtratoLogData[] | undefined> {
    const response = await this.extratoApiClient.get<ExtratoLogData[]>(`/extratoLogs`);
    return response;
  }

  async getById(id: string): Promise<ExtratoLogData | undefined> {
    const response = await this.extratoApiClient.get<ExtratoLogData>(`/extratoLogs/${id}`);
    return response;
  }

  async update(id: string, data: ExtratoLogDataCreateRequest): Promise<ExtratoLogData | undefined> {
    const response = await this.extratoApiClient.put<ExtratoLogDataCreateRequest, ExtratoLogData>(
      `/extratoLogs/${id}`,
      data
    );
    return response;
  }
}

export default class ExtratoLogService {
  extratoApiClient: IExtratoLogApiClient;

  constructor() {
    this.extratoApiClient = new ExtratoLogApiClient(new ApiClient());
  }

  async create(data: ExtratoLogDataCreateRequest): Promise<ExtratoLogData | undefined> {
    return this.extratoApiClient.create(data);
  }

  async list(): Promise<ExtratoLogData[] | undefined> {
    return this.extratoApiClient.list();
  }

  async getById(id: string): Promise<ExtratoLogData | undefined> {
    return this.extratoApiClient.getById(id);
  }
}
