import * as TooltipComponent from '@radix-ui/react-tooltip';
import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Content = styled(TooltipComponent.Content)`
  background: ${theme.colors.grey[700]};
  border-radius: 0.8rem;
  color: ${theme.colors.neutral.white};
  padding: ${theme.spacings[1.5]} ${theme.spacings[2]};
  font-size: ${theme.fonts['x-small']};

  span {
    left: translate(-50%, -50%);
  }
`;

export const Arrow = styled(TooltipComponent.Arrow)`
  fill: ${theme.colors.grey[700]};
`;
