import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import DataTable from '../../components/DataTable';
import SectionActions from './SectionActions';
import Heading from '../../components/Text/Heading';
import Button from '../../components/Form/Button';
import { ScaleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Container } from './styles';
import Input from '../../components/Form/Input';
import { SectionData } from '../../types/Section.types';
import { sectionService } from '../../api/services';
import { formatDate } from '../../components/DataTable/utils';

const Sections = () => {
  const [sections, setSections] = useState<SectionData[]>([]);
  const [search, setSearch] = useState<string>('');
  const [deletedItem, setDeletedItem] = useState<string>('');

  const handleSearch = useCallback((e: { target: { value: SetStateAction<string> } }) => {
    setSearch(e.target.value);
  }, []);

  const SectionName = useCallback((info: CellContext<SectionData, unknown>) => <>{info.getValue()}</>, []);

  const DateFormatter = useCallback(
    (info: CellContext<SectionData, unknown>) => formatDate(info.getValue() as string),
    []
  );

  const actions = useCallback(
    (info: CellContext<SectionData, unknown>) => (
      <SectionActions
        setDeletedItem={setDeletedItem}
        id={info.row.getValue('idSecao')}
        name={info.row.getValue('tituloSecao')}
      />
    ),
    []
  );

  const columns = useMemo<ColumnDef<SectionData>[]>(
    () => [
      {
        accessorKey: 'idSecao',
        header: 'Id',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'tituloSecao',
        header: 'Título da seção',
        cell: SectionName,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataCriacao',
        header: 'Data da criação',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataAlteracao',
        header: 'Última alteração',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'usuarioCriacao',
        header: 'Criado por',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'action',
        header: '',
        cell: actions,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  useEffect(() => {
    async function getSections() {
      const result = await sectionService.list();
      if (result) {
        setSections(result);
      }
    }

    getSections();
  }, [deletedItem]);

  const navigate = useNavigate();
  const navigateToCreateSection = useCallback(() => {
    navigate('/sections/create');
  }, []);

  return (
    <Container>
      <div className="header">
        <Heading>Seções</Heading>
        <Button onClick={navigateToCreateSection}>
          <ScaleIcon />
          Cadastrar nova seção
        </Button>
      </div>

      <div className="search-filter-bar">
        <Input
          name="search"
          icon={<MagnifyingGlassIcon />}
          placeholder="Procurar por título da seção"
          onChange={handleSearch}
        />
      </div>
      <DataTable<SectionData>
        columnVisibility={{ idSecao: false }}
        globalFilter={search}
        data={sections}
        columns={columns}
      />
    </Container>
  );
};

export default Sections;
