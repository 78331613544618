import { EllipsisVerticalIcon, PencilIcon, Square3Stack3DIcon } from '@heroicons/react/24/outline';
import { SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DropdownMenu from '../../components/DropdownMenu';
import { Content, Item, Separator } from '../../components/DropdownMenu/styles';
import { modelService } from '../../api/services';
import { showToast } from '../../components/Toast/showToast';
import { DialogCancelDelete } from '../shared/DialogCancelDelete';

interface ModelActionsProps {
  id: string;
  name: string;
  setDeletedItem: React.Dispatch<SetStateAction<string>>;
}

const ModelActions = ({ id, name, setDeletedItem }: ModelActionsProps) => {
  const [openedActions, setOpenedActions] = useState(false);

  const navigate = useNavigate();
  const navigateToUpdateModel = useCallback(() => {
    navigate(`/models/${id}/update`);
  }, []);
  const navigateToDuplicateModel = useCallback(() => {
    navigate(`/models/${id}/duplicate`);
  }, []);

  const removeModel = useCallback(
    (id: string) => async () => {
      const successfullyDeleted = await modelService.delete(id);
      if (successfullyDeleted) {
        showToast({
          type: 'success',
          text: 'Sucesso ao remover modelo.',
        });
        setDeletedItem(id);
      }
      setOpenedActions(false);
    },
    []
  );

  return (
    <DropdownMenu
      open={openedActions}
      setOpen={setOpenedActions}
      content={
        <Content sideOffset={5}>
          <Item onClick={navigateToDuplicateModel}>
            <Square3Stack3DIcon /> <span>Duplicar modelo</span>
          </Item>

          <Separator />

          <Item onClick={navigateToUpdateModel}>
            <PencilIcon /> <span>Editar modelo</span>
          </Item>

          <Separator />

          <DialogCancelDelete itemName={name} itemType="modelo" removeItem={removeModel(id)} />
        </Content>
      }>
      <EllipsisVerticalIcon className="actions-button" />
    </DropdownMenu>
  );
};

export default ModelActions;
