import { Suspense } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import ProtectedRoute from './routes/ProtectedRoute';
import ToastContainer from './components/Toast/ToastContainer';
import Login from './pages/Login';
import NotFound from './pages/NotFound';

import './index.css';

import GlobalStyle from './styles/global';
import { theme } from './styles/themes/default';
import LegalAreas from './pages/LegalAreas';
import CreateLegalArea from './pages/LegalAreas/CreateLegalArea';
import UpdateViewLegalArea from './pages/LegalAreas/UpdateViewLegalArea';
import Sections from './pages/Sections';
import CreateSection from './pages/Sections/CreateSection';
import UpdateViewSection from './pages/Sections/UpdateViewSection';
import Variables from './pages/Variables';
import CreateVariable from './pages/Variables/CreateVariable';
import UpdateViewVariable from './pages/Variables/UpdateViewVariable';
import Texts from './pages/Texts';
import CreateText from './pages/Texts/CreateText';
import CreateDynamicVariable from './pages/Variables/CreateDynamicVariable';
import UpdateDynamicVariable from './pages/Variables/UpdateDynamicVariable';
import UpdateViewText from './pages/Texts/UpdateViewText';
import DuplicateText from './pages/Texts/DuplicateText';
import Models from './pages/Models';
import CreateModel from './pages/Models/CreateModel';
import UpdateModel from './pages/Models/UpdateModel';
import DuplicateModel from './pages/Models/DuplicateModel';
import Conditions from './pages/Conditions';
import CreateCondition from './pages/Conditions/CreateCondition';
import UpdateCondition from './pages/Conditions/UpdateCondition';
import DuplicateCondition from './pages/Conditions/DuplicateCondition';
import Documents from './pages/Documents';
import CreateDocument from './pages/Documents/CreateDocument';
import UpdateDocument from './pages/Documents/UpdateDocument';
import Templates from './pages/Templates';
import CreateTemplate from './pages/Templates/CreateTemplate';
import UpdateTemplate from './pages/Templates/UpdateTemplate';
import Users from './pages/Users';
import CreateUser from './pages/Users/CreateUser';
import UpdateUser from './pages/Users/UpdateUser';
import { useAppSelector } from './hooks/useStore';
import { getDecodedUserData } from './store/slices/user/utils';
import DuplicateVariable from './pages/Variables/DuplicateVariable';

function App() {
  const { userData, userToken } = useAppSelector((state) => state.user);
  const user = getDecodedUserData(userData as string);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastContainer />
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              element={
                <ProtectedRoute
                  isAllowed={(!!userToken && user?.permissions?.includes('areas-juridicas')) as boolean}
                />
              }>
              <Route path="/legal-areas" element={<LegalAreas />} />
              <Route path="/legal-areas/create" element={<CreateLegalArea />} />
              <Route path="/legal-areas/:id" element={<UpdateViewLegalArea />} />
              <Route path="/legal-areas/:id/update" element={<UpdateViewLegalArea />} />
            </Route>

            <Route
              element={
                <ProtectedRoute isAllowed={(!!userToken && user?.permissions?.includes('secoes')) as boolean} />
              }>
              <Route path="/sections" element={<Sections />} />
              <Route path="/sections/create" element={<CreateSection />} />
              <Route path="/sections/:id" element={<UpdateViewSection />} />
              <Route path="/sections/:id/update" element={<UpdateViewSection />} />
            </Route>

            <Route
              element={
                <ProtectedRoute isAllowed={(!!userToken && user?.permissions?.includes('variaveis')) as boolean} />
              }>
              <Route path="/variables" element={<Variables />} />
              <Route path="/variables/create" element={<CreateVariable />} />
              <Route path="/variables/:id" element={<UpdateViewVariable />} />
              <Route path="/variables/:id/update" element={<UpdateViewVariable />} />
              <Route path="/variables/:id/duplicate" element={<DuplicateVariable />} />
              <Route path="/variables/:id/dynamic" element={<CreateDynamicVariable />} />
              <Route path="/variables/:id/dynamic/update" element={<UpdateDynamicVariable />} />
            </Route>

            <Route
              element={
                <ProtectedRoute isAllowed={(!!userToken && user?.permissions?.includes('textos')) as boolean} />
              }>
              <Route path="/texts" element={<Texts />} />
              <Route path="/texts/create" element={<CreateText />} />
              <Route path="/texts/:id" element={<UpdateViewText />} />
              <Route path="/texts/:id/update" element={<UpdateViewText />} />
              <Route path="/texts/:id/duplicate" element={<DuplicateText />} />
            </Route>

            <Route
              element={
                <ProtectedRoute isAllowed={(!!userToken && user?.permissions?.includes('modelos')) as boolean} />
              }>
              <Route path="/models" element={<Models />} />
              <Route path="/models/create" element={<CreateModel bpmnComponent={true} />} />
              <Route path="/models/:id/update" element={<UpdateModel bpmnComponent={true} />} />
              <Route path="/models/:id/duplicate" element={<DuplicateModel bpmnComponent={true} />} />
            </Route>

            <Route
              element={
                <ProtectedRoute isAllowed={(!!userToken && user?.permissions?.includes('condicoes')) as boolean} />
              }>
              <Route path="/conditions" element={<Conditions />} />
              <Route path="/conditions/create" element={<CreateCondition />} />
              <Route path="/conditions/:id/update" element={<UpdateCondition />} />
              <Route path="/conditions/:id/duplicate" element={<DuplicateCondition />} />
            </Route>

            <Route
              element={
                <ProtectedRoute isAllowed={(!!userToken && user?.permissions?.includes('documentos')) as boolean} />
              }>
              <Route path="/documents" element={<Documents />} />
              <Route path="/documents/create" element={<CreateDocument />} />
              <Route path="/documents/:id/update" element={<UpdateDocument />} />
            </Route>

            <Route
              element={
                <ProtectedRoute isAllowed={(!!userToken && user?.permissions?.includes('templates')) as boolean} />
              }>
              <Route path="/templates" element={<Templates />} />
              <Route path="/templates/create" element={<CreateTemplate />} />
              <Route path="/templates/:id/update" element={<UpdateTemplate />} />
            </Route>

            <Route
              element={
                <ProtectedRoute isAllowed={(!!userToken && user?.permissions?.includes('usuarios')) as boolean} />
              }>
              <Route path="/users" element={<Users />} />
              <Route path="/users/create" element={<CreateUser />} />
              <Route path="/users/:id/update" element={<UpdateUser />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;
