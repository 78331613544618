import { EllipsisVerticalIcon, PencilIcon } from '@heroicons/react/24/outline';
import { SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DropdownMenu from '../../components/DropdownMenu';
import { Content, Item, Separator } from '../../components/DropdownMenu/styles';
import { showToast } from '../../components/Toast/showToast';
import { userService } from '../../api/services';
import { DialogCancelDelete } from '../shared/DialogCancelDelete';

interface UserActionsProps {
  id: string;
  setDeletedItem: React.Dispatch<SetStateAction<string>>;
}

const UserActions = ({ id, setDeletedItem }: UserActionsProps) => {
  const [openedActions, setOpenedActions] = useState(false);

  const navigate = useNavigate();
  const navigateToUpdateUser = useCallback(() => {
    navigate(`/users/${id}/update`);
  }, []);

  const removeUser = useCallback(
    (id: string) => async () => {
      const successfullyDeleted = await userService.delete(id);
      if (successfullyDeleted) {
        showToast({
          type: 'success',
          text: 'Sucesso ao remover usuário.',
        });
        setDeletedItem(id);
      }
      setOpenedActions(false);
    },
    []
  );

  return (
    <DropdownMenu
      open={openedActions}
      setOpen={setOpenedActions}
      content={
        <Content sideOffset={5}>
          <Item onClick={navigateToUpdateUser}>
            <PencilIcon /> <span>Editar usuário</span>
          </Item>

          <Separator />

          <DialogCancelDelete itemType="usuário" removeItem={removeUser(id)} />
        </Content>
      }>
      <EllipsisVerticalIcon className="actions-button" />
    </DropdownMenu>
  );
};

export default UserActions;
