import * as PopoverComponent from '@radix-ui/react-popover';
import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Content = styled(PopoverComponent.Content)`
  border-radius: 0.4rem;
  padding: ${theme.spacings[2]};
  background-color: ${theme.colors.neutral.white};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
`;

export const Arrow = styled(PopoverComponent.Arrow)`
  fill: white;
`;
