import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[4]};

    button {
      width: 23rem;
    }
  }

  .search-filter-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[1.5]};

    input {
      width: 40rem;
    }
  }

  .sections {
    text-align: center;
  }
`;

export const StyledForm = styled.form`
  padding-bottom: 10rem;
  .box-info {
    width: 100%;
  }
  h1 {
    margin-bottom: ${theme.spacings[4]};
  }
  .label {
    margin-bottom: 0;
  }
  .content-editor {
    width: 100%;
    margin-top: ${theme.spacings[4]};
    .tox-tinymce {
      z-index: 0;
    }
  }
  .tabs {
    display: flex;
    .tab {
      color: ${theme.colors.grey[400]};
      font-weight: bold;
      margin-right: ${theme.spacings[5]};
      cursor: pointer;
      position: relative;
      background: none;
      border: 0;
      &.active {
        color: ${theme.colors.grey[700]};
      }
      &.active::after {
        content: '';
        width: 3.2rem;
        border: 0.15rem solid ${theme.colors.blue[600]};
        position: absolute;
        bottom: -1.8rem;
        left: 0;
      }
    }
  }
  .info {
    margin-top: 0.4rem;
    ul {
      background: ${theme.colors.neutral.white};
      border: 0.1rem solid ${theme.colors.grey[200]};
      border-radius: 0.4rem;
      list-style: none;
      font-size: ${theme.fonts['x-small']};
      line-height: ${theme.fonts['large']};
      padding: ${theme.spacings[1.5]} ${theme.spacings[1]};
      max-height: 14rem;
      overflow-y: scroll;
      li {
        padding: ${theme.spacings[0.5]} ${theme.spacings[1]};
        border-radius: 0.4rem;
        &:nth-child(odd) {
          background: ${theme.colors.grey[50]};
        }
      }
    }
  }
`;
