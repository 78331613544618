import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[4]};

    button {
      width: 23rem;
    }
  }

  .search-filter-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[1.5]};

    input {
      width: 40rem;
    }
  }
`;

export const StyledForm = styled.form`
  h1 {
    margin-bottom: ${theme.spacings[4]};
  }
  .label {
    margin-bottom: 0;
  }
  .switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
      font-weight: bold;
      margin-left: ${theme.spacings[1]};
    }
    div {
      width: auto;
    }
    label {
      display: none;
    }
  }
`;

export const StyledSuspenseUpdateTemplateItemsForm = styled.form`
  .box-form {
    width: 76rem;

    .error {
      margin: 0 0 ${theme.spacings[2]} ${theme.spacings[2]};
    }

    .box-form-header {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      border-bottom: 0.1rem solid ${theme.colors.grey[200]};
      padding: ${theme.spacings[2]};
      div {
        width: auto;
      }
      .margins-box {
        display: grid;
        align-items: center;
        width: 500rem;
      }
      .react-select__control {
        border: none !important;
        // width: 18rem;
        min-width: 7rem;
      }
      button {
        border: none;
        background: none;
        svg {
          height: 2rem;
          stroke: ${theme.colors.neutral.black};
        }
      }
    }
  }
`;
