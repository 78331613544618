import { Dispatch, SetStateAction } from 'react';
import SearchCondition from '../../pages/Conditions/SearchCondition';
import SuspenseCreateCondition from '../../pages/Conditions/SuspenseCreateCondition';
import SuspenseUpdateCondition from '../../pages/Conditions/SuspenseUpdateCondition';
import { ConditionOptionBPMN } from '../../types/Condition.types';
import Dialog from '../Dialog';
import Button from '../Form/Button';
import SuspensePage from '../SuspensePage';
import Heading from '../Text/Heading';
import Paragraph from '../Text/Paragraph';
import { ContentInfo } from './styles';
import AssociationCondition from '../../pages/Conditions/AssociationCondition';
import { useParams } from 'react-router-dom';

interface ConditionContentProps {
  openedConditionOptionsModal: boolean;
  setOpenedConditionOptionsModal: Dispatch<SetStateAction<boolean>>;
  openConditionContent: (content: string, type?: string) => () => void;
  openedAddConditionModal: boolean;
  setOpenedAddConditionModal: Dispatch<SetStateAction<boolean>>;
  openedAssociationConditionPage: boolean;
  openedNewConditionPage: boolean;
  closeNewConditionPage: () => void;
  closeConditionContent: () => void;
  openedAddConditionPage: boolean;
  openedReplaceConditionPage: boolean;
  openedUpdateConditionPage: boolean;
  currentCondition: {
    name: string;
    conditionId: string;
  };
  setBpmnCondition: (conditionId: string, name: string, options: ConditionOptionBPMN[]) => () => void;
}

const ConditionContent = ({
  openedConditionOptionsModal,
  setOpenedConditionOptionsModal,
  openConditionContent,
  openedAddConditionModal,
  setOpenedAddConditionModal,
  openedAssociationConditionPage,
  openedNewConditionPage,
  closeNewConditionPage,
  closeConditionContent,
  openedAddConditionPage,
  openedReplaceConditionPage,
  openedUpdateConditionPage,
  currentCondition,
  setBpmnCondition,
}: ConditionContentProps) => {
  const { id: modelId } = useParams();

  return (
    <>
      <Dialog
        size="medium"
        controlledClose={true}
        open={openedConditionOptionsModal}
        setOpen={setOpenedConditionOptionsModal}
        title={<Heading size="medium">Opções para condição</Heading>}
        buttonsStyle="row"
        primaryButton={
          <Button type="button" onClick={openConditionContent('updateConditionPage')}>
            Editar condição
          </Button>
        }
        secondaryButton={
          <>
            <Button type="button" kind="secondary" onClick={openConditionContent('replaceConditionPage')}>
              Substituir condição
            </Button>
            {modelId && (
              <Button type="button" kind="secondary" onClick={openConditionContent('associationConditionPage')}>
                Associar condição
              </Button>
            )}
          </>
        }
        content={
          <ContentInfo>
            <Paragraph size="small" className="type">
              Condição
            </Paragraph>
            <Paragraph size="medium" className="value">
              {currentCondition.name}
            </Paragraph>
          </ContentInfo>
        }>
        <></>
      </Dialog>

      <Dialog
        controlledClose={true}
        open={openedAddConditionModal}
        setOpen={setOpenedAddConditionModal}
        title={
          <>
            <Heading size="medium">Adicionar condição</Heading>
            <Button kind="outline" onClick={openConditionContent('addConditionPage')}>
              Ver todos
            </Button>
          </>
        }
        content={
          <>
            <SearchCondition
              type="modal"
              setBpmnCondition={setBpmnCondition}
              newConditionAction={openConditionContent('newConditionPage', 'modal')}
            />
          </>
        }>
        <></>
      </Dialog>

      {openedNewConditionPage && (
        <SuspensePage
          title="Modelos"
          menuItems={[{ name: 'Novo condição', onClick: () => null }]}
          backButtonTitle="Voltar à modelagem"
          onBackButtonClick={closeNewConditionPage}>
          <SuspenseCreateCondition closeContent={closeNewConditionPage} />
        </SuspensePage>
      )}

      {openedAddConditionPage && (
        <SuspensePage
          title="Modelos"
          menuItems={[{ name: 'Adicionar condição', onClick: () => null }]}
          backButtonTitle="Voltar à modelagem"
          onBackButtonClick={closeConditionContent}>
          <SearchCondition
            title="Adicionar condição"
            type="page"
            setBpmnCondition={setBpmnCondition}
            newConditionAction={openConditionContent('newConditionPage', 'page')}
          />
        </SuspensePage>
      )}

      {openedReplaceConditionPage && (
        <SuspensePage
          title="Modelos"
          menuItems={[{ name: 'Substituir condição', onClick: () => null }]}
          backButtonTitle="Voltar à modelagem"
          onBackButtonClick={closeConditionContent}>
          <SearchCondition
            title="Substituir condição"
            type="page"
            setBpmnCondition={setBpmnCondition}
            newConditionAction={openConditionContent('newConditionPage', 'page')}
          />
        </SuspensePage>
      )}

      {openedUpdateConditionPage && (
        <SuspensePage
          title="Modelos"
          menuItems={[{ name: 'Edição de condição', onClick: () => null }]}
          backButtonTitle="Voltar à modelagem"
          onBackButtonClick={closeConditionContent}>
          <SuspenseUpdateCondition
            id={currentCondition.conditionId}
            setBpmnCondition={setBpmnCondition}
            closeContent={closeConditionContent}
          />
        </SuspensePage>
      )}

      {openedAssociationConditionPage && (
        <SuspensePage
          title="Modelos"
          menuItems={[{ name: 'Associação de condição', onClick: () => null }]}
          backButtonTitle="Voltar à modelagem"
          onBackButtonClick={closeConditionContent}>
          <AssociationCondition id={currentCondition.conditionId} closeContent={closeConditionContent} />
        </SuspensePage>
      )}
    </>
  );
};

export default ConditionContent;
