import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import DataTable from '../../components/DataTable';
import Heading from '../../components/Text/Heading';
import Button from '../../components/Form/Button';
import { ClipboardDocumentListIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Container } from './styles';
import Input from '../../components/Form/Input';
import { TemplateData } from '../../types/Template.types';
import { templateService } from '../../api/services';
import { formatDate } from '../../components/DataTable/utils';
import TemplateActions from './TemplateActions';

const Templates = () => {
  const [sections, setTemplates] = useState<TemplateData[]>([]);
  const [search, setSearch] = useState<string>('');
  const [deletedItem, setDeletedItem] = useState<string>('');

  const handleSearch = useCallback((e: { target: { value: SetStateAction<string> } }) => {
    setSearch(e.target.value);
  }, []);

  const DateFormatter = useCallback(
    (info: CellContext<TemplateData, unknown>) => formatDate(info.getValue() as string),
    []
  );

  const actions = useCallback(
    (info: CellContext<TemplateData, unknown>) => (
      <TemplateActions
        id={info.row.getValue('idTemplate')}
        name={info.row.getValue('nomeTemplate')}
        setDeletedItem={setDeletedItem}
      />
    ),
    []
  );

  const columns = useMemo<ColumnDef<TemplateData>[]>(
    () => [
      {
        accessorKey: 'idTemplate',
        header: 'Id',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'nomeTemplate',
        header: 'Nome do template',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataCriacao',
        header: 'Data da criação',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataAlteracao',
        header: 'Última alteração',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'usuarioCriacao',
        header: 'Criado por',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'action',
        header: '',
        cell: actions,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  useEffect(() => {
    async function getTemplates() {
      const result = await templateService.list();
      if (result) {
        setTemplates(result);
      }
    }

    getTemplates();
  }, [deletedItem]);

  const navigate = useNavigate();
  const navigateToCreateTemplate = useCallback(() => {
    navigate('/templates/create');
  }, []);

  return (
    <Container>
      <div className="header">
        <Heading>Templates timbrados</Heading>
        <Button onClick={navigateToCreateTemplate}>
          <ClipboardDocumentListIcon />
          Criar novo template
        </Button>
      </div>

      <div className="search-filter-bar">
        <Input
          name="search"
          icon={<MagnifyingGlassIcon />}
          placeholder="Procurar por nome do template"
          onChange={handleSearch}
        />
      </div>
      <DataTable<TemplateData>
        columnVisibility={{ idTemplate: false }}
        globalFilter={search}
        data={sections}
        columns={columns}
      />
    </Container>
  );
};

export default Templates;
