import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModelFormValues } from '../../types/Model.types';
import { StyledForm } from './styles';
import { DialogCancelCreateUpdate } from '../shared/DialogCancelCreateUpdate';
import { legalAreaService, sectionService } from '../../api/services';
import Box from '../../components/Box';
import FormGroup from '../../components/Form/FormGroup';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import { SwitchForm } from '../../components/Form/Switch';
import { OptionType, Select } from '../../components/Form/Select';
import Accordion from '../../components/Accordion';
import Paragraph from '../../components/Text/Paragraph';
import Heading from '../../components/Text/Heading';
import BpmnEditor from '../../components/Bpmn/BpmnEditor';

interface ModelFormProps {
  type: 'create' | 'update';
  initialData?: ModelFormValues;
  onSubmitSave: (data: ModelFormValues) => void;
  onSubmitSaveAndContinue: (data: ModelFormValues) => void;
  bpmnComponent: boolean;
}

const validationSchema = yup.object({
  name: yup.string().required('O campo nome é obrigatório'),
  legalAreas: yup.array().min(1, 'É necessário escolher pelo menos uma área jurídica.'),
  sections: yup.array().min(1, 'É necessário escolher pelo menos uma seção.'),
});

const ModelForm = ({ type, initialData, onSubmitSave, onSubmitSaveAndContinue, bpmnComponent }: ModelFormProps) => {
  const [configuredModeler, setConfiguredModeler] = useState<any>(null);
  const [sections, setSections] = useState<readonly OptionType[]>([]);
  const [legalAreas, setLegalAreas] = useState<readonly OptionType[]>([]);

  useEffect(() => {
    const fetchPromises = () => {
      const sectionsRequest = sectionService.list();
      const legalAreasRequest = legalAreaService.list();

      Promise.all([sectionsRequest, legalAreasRequest]).then(([sectionsResponse, legalAreasResponse]) => {
        if (sectionsResponse) {
          setSections(
            sectionsResponse.map((section) => ({
              label: section.tituloSecao as string,
              value: section.idSecao as string,
            }))
          );
        }

        if (legalAreasResponse) {
          setLegalAreas(
            legalAreasResponse.map((legalArea) => ({
              label: legalArea.nomeAreaJuridica as string,
              value: legalArea.idAreaJuridica as string,
            }))
          );
        }
      });
    };

    fetchPromises();
  }, []);

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ModelFormValues>({
    defaultValues: {
      ...initialData,
    },
    resolver: yupResolver(validationSchema),
  });
  const watchActive = watch('active');

  const navigate = useNavigate();
  const navigateToModels = useCallback(() => {
    navigate('/models');
  }, []);

  const renderSelectSection = useCallback(
    ({ field }: any) => <Select placeholder="Selecione as seções associadas" {...field} isMulti options={sections} />,
    [sections]
  );

  const renderSelectLegalArea = useCallback(
    ({ field }: any) => (
      <Select placeholder="Selecione as áreas jurídicas associadas" {...field} isMulti options={legalAreas} />
    ),
    [sections]
  );

  const renderSelectType = useCallback(
    ({ field }: any) => (
      <Select
        placeholder="Selecione o tipo do modelo"
        {...field}
        options={[
          { label: 'Modelo simples', value: '1' },
          { label: 'Modelo completo', value: '2' },
        ]}
      />
    ),
    []
  );

  useEffect(() => {
    setValue('id', initialData?.id);
  }, [initialData]);

  /* istanbul ignore next */
  const getBpmnComponent = useCallback(
    () =>
      bpmnComponent === true ? (
        <BpmnEditor
          initialBpmn={initialData?.xmlModel as string}
          configuredModeler={configuredModeler}
          setConfiguredModeler={setConfiguredModeler}
        />
      ) : null,
    [configuredModeler]
  );

  const modelFormSubmit = useCallback(
    (onSubmit: (data: ModelFormValues) => void) => async () => {
      /* istanbul ignore next */
      if (bpmnComponent) {
        const result = await configuredModeler.saveXML();
        const { xml } = result;
        setValue('xmlModel', xml);
      }
      handleSubmit(onSubmit)();
    },
    [configuredModeler]
  );

  return (
    <>
      <StyledForm>
        <Box
          padding={false}
          border={false}
          footer={
            <>
              <DialogCancelCreateUpdate type={type} navigateToListPage={navigateToModels} />

              <div className="buttons-container">
                <Button onClick={modelFormSubmit(onSubmitSaveAndContinue)} kind="secondary" type="button">
                  Salvar e continuar
                </Button>

                <Button className="submit-button" onClick={modelFormSubmit(onSubmitSave)} type="button">
                  Salvar
                </Button>
              </div>
            </>
          }>
          <Accordion
            content={
              <div className="content">
                <Box grid={2} border={false} padding={false}>
                  <FormGroup error={errors.type?.message}>
                    <Paragraph className="label">Tipo do modelo</Paragraph>
                    <Controller name="type" control={control} render={renderSelectType} />
                  </FormGroup>
                  <FormGroup error={errors.legalAreas?.message}>
                    <Paragraph className="label">Área</Paragraph>
                    <Controller name="legalAreas" control={control} render={renderSelectLegalArea} />
                  </FormGroup>
                  <FormGroup error={errors.name?.message}>
                    <Input {...register('name')} label="Nome do modelo" placeholder="Digite o nome do modelo" />
                  </FormGroup>
                  <FormGroup error={errors.sections?.message}>
                    <Paragraph className="label">Seções</Paragraph>
                    <Controller name="sections" control={control} render={renderSelectSection} />
                  </FormGroup>
                  <FormGroup error={errors.active?.message}>
                    <Paragraph className="label">Ativar modelo?</Paragraph>
                    <div className="switch">
                      <SwitchForm name="active" position="left" label="Ativar modelo?" control={control} />
                      <Paragraph>{watchActive === true ? 'Sim' : 'Não'}</Paragraph>
                    </div>
                  </FormGroup>
                </Box>
              </div>
            }>
            <Heading>Modelagem de documento</Heading>
          </Accordion>
        </Box>
        <Box padding={false} border={false}>
          {getBpmnComponent()}
        </Box>
      </StyledForm>
    </>
  );
};

export default ModelForm;
