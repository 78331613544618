import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import TextForm from './TextForm';
import { TextFormValues } from '../../types/Text.types';
import { showToast } from '../../components/Toast/showToast';
import { textService } from '../../api/services';

const CreateText = () => {
  const navigate = useNavigate();
  const navigateToListTexts = useCallback(() => {
    navigate('/texts');
  }, []);

  const handleSubmit = useCallback(async (data: TextFormValues) => {
    const successfullyCreated = await textService.create({
      nome: data.name,
      valor: data.text,
      tipo: parseInt(data.type?.value as string),
      secoes: data.sections?.map((section) => section.value as string),
    });
    if (successfullyCreated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar texto.',
      });
      navigateToListTexts();
    }
  }, []);

  return (
    <>
      <TextForm type="create" onSubmit={handleSubmit} onCancel={navigateToListTexts} />
    </>
  );
};

export default CreateText;
