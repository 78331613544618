import { Root, Item, Indicator, Label, ContentIndicator } from './styles';
import React from 'react';

type RadioGroupProps = {
  items: {
    label: string;
    value: string;
  }[];
  selectedValue?: string;
  setSelectedValue: (value: string) => void;
};

const RadioGroup = React.forwardRef<HTMLInputElement, RadioGroupProps>(
  ({ items, selectedValue, setSelectedValue }, ref) => {
    return (
      <Root defaultValue={selectedValue} onValueChange={setSelectedValue}>
        {items.map((item) => (
          <div key={item.value}>
            <Item className="RadioGroupItem" value={item.value} id={item.value}>
              <Label htmlFor={item.value}>{item.label}</Label>
              <ContentIndicator>
                <Indicator className="RadioGroupIndicator" />
              </ContentIndicator>
            </Item>
          </div>
        ))}
      </Root>
    );
  }
);

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
