import Button from '../../components/Form/Button';
import Heading from '../../components/Text/Heading';
import { AlertDialog } from './styles';
import Dialog from '../../components/Dialog';
import EditAlert from '../../assets/icons/edit-alert.svg';
import DeleteAlert from '../../assets/icons/delete-alert.svg';
import Paragraph from '../../components/Text/Paragraph';
import { useCallback, useState } from 'react';

interface DialogConfirmActionProps {
  trigger: string | React.ReactNode;
  title: string;
  text: string;
  type: 'update' | 'delete';
  onConfirm: () => void;
}

export const DialogConfirmAction = ({ trigger, type, title, text, onConfirm }: DialogConfirmActionProps) => {
  const [open, setOpen] = useState(false);
  const handleConfirm = useCallback(() => {
    setOpen(false);
    onConfirm();
  }, []);

  return (
    <Dialog
      primaryButton={<Button onClick={handleConfirm}>Confirmar</Button>}
      secondaryButton={<Button kind="outline">Cancelar</Button>}
      open={open}
      setOpen={setOpen}
      content={
        <AlertDialog>
          <img src={type === 'update' ? EditAlert : DeleteAlert} />
          <Heading>{title}</Heading>
          <Paragraph>{text}</Paragraph>
        </AlertDialog>
      }>
      {trigger}
    </Dialog>
  );
};
