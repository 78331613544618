import * as AccordionComponent from '@radix-ui/react-accordion';
import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Root = styled(AccordionComponent.Root)`
  border-radius: 0.4rem;
  width: 100%;
  background-color: ${theme.colors.neutral.white};
`;

export const Item = styled(AccordionComponent.Item)`
  overflow: visible;
  margin-top: 0.1rem;

  &:first-child {
    margin-top: 0;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
  }

  &:last-child {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }

  &:focus-within {
    position: relative;
    z-index: 1;
  }
`;

export const Header = styled(AccordionComponent.Header)`
  display: flex;
`;

export const Trigger = styled.div`
  font-family: inherit;
  background-color: transparent;
  padding: ${theme.spacings[3]} ${theme.spacings[3]} ${theme.spacings[1.5]};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  background-color: ${theme.colors.neutral.white};
  border: 0.1rem solid ${theme.colors.grey[200]};
  border-radius: 0.4rem 0.4rem 0 0;

  &[data-state='open'] > svg {
    transform: rotate(180deg);
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    svg {
      stroke: ${theme.colors.grey[600]};
      width: 2.4rem;
      transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
  }
`;

export const Content = styled(AccordionComponent.Content)`
  overflow: visible;
  border: 0.1rem solid ${theme.colors.grey[200]};
  border-top: 0;
  border-radius: 0 0 0.4rem 0.4rem;
  padding: ${theme.spacings[3]};

  &[data-state='open'] {
    animation: 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;
