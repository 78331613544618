import { Dispatch, SetStateAction } from 'react';
import SearchText from '../../pages/Texts/SearchText';
import SuspenseCreateText from '../../pages/Texts/SuspenseCreateText';
import SuspenseUpdateText from '../../pages/Texts/SuspenseUpdateText';
import Dialog from '../Dialog';
import Button from '../Form/Button';
import SuspensePage from '../SuspensePage';
import Heading from '../Text/Heading';
import Paragraph from '../Text/Paragraph';
import { ContentInfo } from './styles';

interface TextContentProps {
  openedTextOptionsModal: boolean;
  setOpenedTextOptionsModal: Dispatch<SetStateAction<boolean>>;
  openTextContent: (content: string, type?: string) => () => void;
  openedAddTextModal: boolean;
  setOpenedAddTextModal: Dispatch<SetStateAction<boolean>>;
  openedNewTextPage: boolean;
  closeNewTextPage: () => void;
  closeTextContent: () => void;
  openedAddTextPage: boolean;
  openedReplaceTextPage: boolean;
  openedUpdateTextPage: boolean;
  currentText: {
    name: string;
    textId: string;
    fullName: string;
  };
  setBpmnText: (textId: string, name: string) => () => void;
}

const TextContent = ({
  openedTextOptionsModal,
  setOpenedTextOptionsModal,
  openTextContent,
  openedAddTextModal,
  setOpenedAddTextModal,
  openedNewTextPage,
  closeNewTextPage,
  closeTextContent,
  openedAddTextPage,
  openedReplaceTextPage,
  openedUpdateTextPage,
  currentText,
  setBpmnText,
}: TextContentProps) => {
  return (
    <>
      <Dialog
        controlledClose={true}
        open={openedTextOptionsModal}
        setOpen={setOpenedTextOptionsModal}
        title={<Heading size="medium">Opções para texto</Heading>}
        buttonsStyle="row"
        primaryButton={
          <Button type="button" onClick={openTextContent('updateTextPage')}>
            Editar texto
          </Button>
        }
        secondaryButton={
          <Button type="button" kind="secondary" onClick={openTextContent('replaceTextPage')}>
            Substituir texto
          </Button>
        }
        content={
          <ContentInfo>
            <Paragraph size="small" className="type">
              Texto
            </Paragraph>
            <Paragraph size="medium" className="value">
              {currentText.fullName}
            </Paragraph>
          </ContentInfo>
        }>
        <></>
      </Dialog>

      <Dialog
        controlledClose={true}
        open={openedAddTextModal}
        setOpen={setOpenedAddTextModal}
        title={
          <>
            <Heading size="medium">Adicionar texto</Heading>
            <Button kind="outline" onClick={openTextContent('addTextPage')}>
              Ver todos
            </Button>
          </>
        }
        content={
          <>
            <SearchText
              type="modal"
              setBpmnText={setBpmnText}
              newTextAction={openTextContent('newTextPage', 'modal')}
            />
          </>
        }>
        <></>
      </Dialog>

      {openedNewTextPage && (
        <SuspensePage
          title="Modelos"
          menuItems={[{ name: 'Novo texto', onClick: () => null }]}
          backButtonTitle="Voltar à modelagem"
          onBackButtonClick={closeNewTextPage}>
          <SuspenseCreateText closeContent={closeNewTextPage} />
        </SuspensePage>
      )}

      {openedAddTextPage && (
        <SuspensePage
          title="Modelos"
          menuItems={[{ name: 'Adicionar texto', onClick: () => null }]}
          backButtonTitle="Voltar à modelagem"
          onBackButtonClick={closeTextContent}>
          <SearchText
            title="Adicionar texto"
            type="page"
            setBpmnText={setBpmnText}
            newTextAction={openTextContent('newTextPage', 'page')}
          />
        </SuspensePage>
      )}

      {openedReplaceTextPage && (
        <SuspensePage
          title="Modelos"
          menuItems={[{ name: 'Substituir texto', onClick: () => null }]}
          backButtonTitle="Voltar à modelagem"
          onBackButtonClick={closeTextContent}>
          <SearchText
            title="Substituir texto"
            type="page"
            setBpmnText={setBpmnText}
            newTextAction={openTextContent('newTextPage', 'page')}
          />
        </SuspensePage>
      )}

      {openedUpdateTextPage && (
        <SuspensePage
          title="Modelos"
          menuItems={[{ name: 'Edição de texto', onClick: () => null }]}
          backButtonTitle="Voltar à modelagem"
          onBackButtonClick={closeTextContent}>
          <SuspenseUpdateText id={currentText.textId} setBpmnText={setBpmnText} closeContent={closeTextContent} />
        </SuspensePage>
      )}
    </>
  );
};

export default TextContent;
