import { useCallback, useEffect, useState } from 'react';
import UserForm from './UserForm';
import { UserFormValues } from '../../types/User.types';
import { useNavigate, useParams } from 'react-router-dom';
import { userService } from '../../api/services';
import { showToast } from '../../components/Toast/showToast';
import { permissionsList } from './utils';
import { OptionType } from '../../components/Form/Select';

const UpdateUser = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<UserFormValues>({
    email: '',
    permission: [],
  });

  const navigate = useNavigate();
  const navigateToListUsers = useCallback(() => {
    navigate('/users');
  }, []);

  useEffect(() => {
    async function getInitialData() {
      const userData = await userService.getById(id as string);
      let permission: OptionType[] = [];
      if (userData?.permissao) {
        permission = userData?.permissao?.split('|').map((permission) => permissionsList[permission]);
      }
      setInitialData({
        email: userData?.email,
        permission,
      });
      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: UserFormValues) => {
    const successfullyCreated = await userService.update(id as string, {
      email: data.email,
      permissao: data?.permission?.map((permission) => permission.value).join('|'),
    });
    if (successfullyCreated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao editar usuário.',
      });
      navigateToListUsers();
    }
  }, []);

  return !isLoading ? <UserForm type="update" initialData={initialData} onSubmit={handleSubmit} /> : null;
};

export default UpdateUser;
