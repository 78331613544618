import styled from 'styled-components';
import { theme } from '../../../styles/themes/default';
import { Text } from '../styles';

export const DisplayStyle = styled(Text).attrs(() => ({
  as: 'h1',
}))`
  font-weight: 700;
  font-size: ${theme.fonts['xxx-large']};
  line-height: ${theme.fonts['large']};
`;

export const HeadingStyle = styled(Text).attrs(() => ({
  as: 'h1',
}))`
  font-weight: 700;
  font-size: ${theme.fonts['x-large']};
  line-height: ${theme.fonts['xxx-large']};
`;

export const MediumStyle = styled(Text).attrs(() => ({
  as: 'h1',
}))`
  font-weight: 700;
  font-size: ${theme.fonts['medium']};
  line-height: ${theme.fonts['xxx-large']};
`;

export const SmallStyle = styled(Text).attrs(() => ({
  as: 'h2',
}))`
  font-weight: 500;
  font-size: ${theme.fonts['small']};
  line-height: ${theme.fonts['large']};
`;
