import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import VariableForm from './VariableForm';
import { VariableFormValues } from '../../types/Variable.types';
import { showToast } from '../../components/Toast/showToast';
import { variableService } from '../../api/services';
import { getCreateUpdateData } from './utils';

const CreateVariable = () => {
  const navigate = useNavigate();
  const navigateToListVariables = useCallback(() => {
    navigate('/variables');
  }, []);

  const handleSubmit = useCallback(async (data: VariableFormValues) => {
    const successfullyCreated = await variableService.create(getCreateUpdateData(data));
    if (successfullyCreated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar variável.',
      });
      navigateToListVariables();
    }
  }, []);

  return (
    <>
      <VariableForm type="create" onSubmit={handleSubmit} onCancel={navigateToListVariables} />
    </>
  );
};

export default CreateVariable;
