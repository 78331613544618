import { useCallback, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Item } from '../../components/DropdownMenu/styles';
import Heading from '../../components/Text/Heading';
import { AlertDialog } from './styles';
import Dialog from '../../components/Dialog';
import DeleteAlert from '../../assets/icons/delete-alert.svg';
import Paragraph from '../../components/Text/Paragraph';
import Button from '../../components/Form/Button';

interface DialogCancelDeleteProps {
  itemName?: string;
  itemType: string;
  removeItem: () => void;
}

export const DialogCancelDelete = ({ itemName, itemType, removeItem }: DialogCancelDeleteProps) => {
  const [openedModal, setOpenedModal] = useState(false);

  const openDeleteModal = useCallback((e: any) => {
    e.preventDefault();
    setOpenedModal((prevState) => !prevState);
  }, []);

  return (
    <Dialog
      controlledClose={true}
      open={openedModal}
      setOpen={setOpenedModal}
      primaryButton={
        <Button kind="alert" className="back-button" onClick={removeItem}>
          Confirmar remoção
        </Button>
      }
      secondaryButton={<Button kind="outline">Cancelar</Button>}
      content={
        <AlertDialog>
          <img src={DeleteAlert} />
          <Heading>Remover {itemType}?</Heading>
          <Paragraph>
            Tem certeza que deseja remover <strong>{itemName ? itemName : 'esse item'}</strong>?
          </Paragraph>
        </AlertDialog>
      }>
      <Item className="Alert" onClick={openDeleteModal}>
        <ExclamationCircleIcon /> <span>Excluir {itemType}</span>
      </Item>
    </Dialog>
  );
};
