import { useCallback, useState, useEffect } from 'react';
import { toastEventManager } from './showToast';
import ToastMessage from './ToastMessage';
import { Container } from './styles';
import { ToastMessageType, ToastProps } from '../../types/Toast.types';

export default function ToastContainer() {
  const [messages, setMessages] = useState<ToastMessageType[]>([]);
  const random = window.crypto.getRandomValues(new Uint32Array(1))[0] / 0x100000000;

  useEffect(() => {
    function handleAddToast({ type, text, duration }: ToastProps) {
      setMessages((prevState) => [
        ...prevState,
        {
          id: random,
          type,
          text,
          duration,
        },
      ]);
    }

    toastEventManager.on('addToast', handleAddToast);

    return () => {
      toastEventManager.removeListener('addToast', handleAddToast);
    };
  }, []);

  const handleRemoveMessage = useCallback((id: number) => {
    setMessages((prevState) => prevState.filter((message) => message.id !== id));
  }, []);

  return (
    <Container>
      {messages.map((message) => (
        <ToastMessage key={message.id} message={message} onRemoveMessage={handleRemoveMessage} />
      ))}
    </Container>
  );
}
