import { forwardRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Container, Content, Sidebar } from './styles';

interface FullPageProps {
  sidebar: React.ReactNode;
  content: React.ReactNode;
}

const FullPage = forwardRef<HTMLDivElement, FullPageProps>(({ sidebar, content }, ref) => {
  let container = document.getElementById('full-page-root');

  if (!container) {
    container = document.createElement('div');
    container.setAttribute('id', 'full-page-root');
    document.body.appendChild(container);
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  return ReactDOM.createPortal(
    <Container>
      {sidebar && <Sidebar className="sidebar">{sidebar}</Sidebar>}
      {content && (
        <Content ref={ref} className={`${!sidebar ? 'no-sidebar' : ''}`}>
          {content}
        </Content>
      )}
    </Container>,
    container
  );
});

FullPage.displayName = 'FullPage';

export default FullPage;
