import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[4]};

    div {
      display: flex;
      justify-content: space-between;
      button {
        width: 24rem;
        margin-left: ${theme.spacings[1]};
      }
      a {
        text-decoration: none;
        button {
          width: 20rem;
        }
      }
    }
  }

  .search-filter-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[1.5]};

    input {
      width: 40rem;
    }
  }

  .badge-cell {
    width: 3rem;
  }
`;

export const StyledForm = styled.form`
  padding-bottom: 10rem;
  > div {
    margin-bottom: ${theme.spacings[2]};
  }
  .buttons-container {
    display: flex;
    button + button {
      margin-left: ${theme.spacings[2]};
    }
  }
  .switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
      font-weight: bold;
      margin-left: ${theme.spacings[1]};
    }
    div {
      width: auto;
    }
    label {
      display: none;
    }
  }
`;
