import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VariableForm from './VariableForm';
import { VariableFormValues } from '../../types/Variable.types';
import { showToast } from '../../components/Toast/showToast';
import { OptionType } from '../../components/Form/Select';
import { variableService } from '../../api/services';
import { getCreateUpdateData } from './utils';

const UpdateViewVariable = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<VariableFormValues>({
    name: '',
    description: '',
    variableType: {
      label: undefined,
      value: undefined,
    },
    required: false,
    options: [],
    myLegalIntegration: false,
  });
  const [initialDataOptions, setInitialDataOptions] = useState('');

  const navigate = useNavigate();
  const navigateToListVariables = useCallback(() => {
    navigate('/variables');
  }, []);
  const navigateToUpdateDynamicVariable = useCallback(() => {
    navigate(`/variables/${id}/dynamic/update`);
  }, []);

  const { pathname } = useLocation();
  const type = pathname.includes('update') ? 'update' : 'view';

  useEffect(() => {
    async function getInitialData() {
      const variableData = await variableService.getById(id as string);
      setInitialDataOptions(variableData?.valorAuxiliar as string);
      setInitialData({
        name: variableData?.integracaoMyLegal
          ? ({ label: variableData?.nomeVariavel, value: variableData?.nomeVariavel } as OptionType)
          : (variableData?.nomeVariavel as string),
        description: variableData?.descricaoVariavel,
        variableType: {
          label: variableData?.descricaoTipoVariavel,
          value: variableData?.idTipoVariavel,
        },
        required: variableData?.obrigatorio,
        options: variableData?.valorAuxiliar?.split('¨').map((option) => ({
          label: option,
          value: option,
        })) as unknown as OptionType[],
        relationType: variableData?.tipoRelacao,
        relationData: variableData?.variavelRelacao?.map((relationVariable) => ({
          relationVariableId: relationVariable.idVariavelDependente,
          principalVariableId: relationVariable.idVariavelPrincipal,
          dependentVariableId: relationVariable.idVariavelDependente,
          principalVariableName: relationVariable.nomeVariavelPrincipal,
          dependentVariableName: relationVariable.nomeVariavelDependente,
          principalVariableValue: relationVariable.valorVariavelPrincipal,
          dependentVariableValue: relationVariable.valorVariavelDependente,
        })),
        myLegalIntegration: variableData?.integracaoMyLegal,
      });
      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(
    async (data: VariableFormValues) => {
      const dataOptions = data.options.map((option) => option.value).join('|');
      const successfullyUpdated = await variableService.update(id as string, getCreateUpdateData(data));
      if (successfullyUpdated) {
        showToast({
          type: 'success',
          text: 'Sucesso ao atualizar variável.',
        });
        if (initialDataOptions != dataOptions) {
          navigateToUpdateDynamicVariable();
        } else {
          navigateToListVariables();
        }
      }
    },
    [initialData]
  );

  return !isLoading ? (
    <>
      <VariableForm type={type} initialData={initialData} onSubmit={handleSubmit} onCancel={navigateToListVariables} />
    </>
  ) : null;
};

export default UpdateViewVariable;
