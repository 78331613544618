import * as SwitchComponent from '@radix-ui/react-switch';
import styled, { css } from 'styled-components';
import { theme } from '../../../styles/themes/default';

export const Container = styled.div<{ position?: string }>`
  ${({ position }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: 'flex-start';
    flex-direction: ${position === 'right' ? 'row-reverse' : 'row'};
    label {
      cursor: pointer;
      font-size: ${theme.fonts['x-small']};
      color: ${theme.colors.grey[600]};
      margin-left: ${position === 'left' ? theme.spacings[1.5] : 0};
      margin-right: ${position === 'right' ? theme.spacings[1.5] : 0};
    }
  `}
`;

export const Root = styled(SwitchComponent.Root)`
  width: 3rem;
  height: 1.5rem;
  background-color: ${theme.colors.grey[400]};
  border-radius: 9999px;
  border: none;
  position: relative;
  cursor: pointer;
  &[data-state='checked'] {
    background-color: ${theme.colors.blue[400]};
  }
`;

export const Thumb = styled(SwitchComponent.Thumb)`
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: ${theme.colors.neutral.white};
  border-radius: 9999px;
  transition: transform 100ms;
  transform: translateX(0.3rem);
  will-change: transform;
  &[data-state='checked'] {
    transform: translateX(1.7rem);
  }
`;
