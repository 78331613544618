import { Controller, useForm } from 'react-hook-form';
import Heading from '../../components/Text/Heading';
import { StyledForm } from './styles';
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import Button from '../../components/Form/Button';
import { DocumentDataForm } from '../../types/Document.types';
import Paragraph from '../../components/Text/Paragraph';
import FormGroup from '../../components/Form/FormGroup';
import { SingleTextData } from '../../types/Text.types';
import { VariableData } from '../../types/Variable.types';
import Input from '../../components/Form/Input';
import TextArea from '../../components/Form/TextArea';
import { Select } from '../../components/Form/Select';
import FileUploader from '../../components/FileUploader';
import Checkbox from '../../components/Form/Checkbox';
import { SwitchForm } from '../../components/Form/Switch';

export type DocumentTextFormHandle = {
  setFormValue: (key: string, value: string) => void;
};

interface DocumentTextFormProps {
  text?: SingleTextData;
  initialData: DocumentDataForm;
  onNext: (data: DocumentDataForm) => void;
  onBack: () => void;
  onChangeVariable: (value: DocumentDataForm) => void;
}

export const DocumentTextForm = forwardRef<DocumentTextFormHandle, DocumentTextFormProps>(
  ({ text, initialData, onNext, onBack, onChangeVariable }, ref) => {
    const {
      handleSubmit,
      control,
      register,
      watch,
      formState: { errors },
      setValue,
    } = useForm();

    useImperativeHandle(ref, () => ({
      setFormValue(key: string, value: string) {
        setValue(key, { label: value, value });
      },
    }));

    useEffect(() => {
      const subscription = watch((value) => {
        onChangeVariable(value);
      });
      return () => subscription.unsubscribe();
    }, []);

    const renderSelect = useCallback(({ field }: any) => {
      const variable = text?.variaveis.find((variable) => variable.idVariavel === field.name);
      return (
        <Select
          placeholder="Selecione uma opção"
          {...field}
          options={variable?.valorAuxiliar?.split('¨').map((value) => ({ label: value.split('|')[0], value }))}
        />
      );
    }, []);

    const renderFileUploader = useCallback(
      ({ field }: any) => {
        const defaultValue = initialData[field.name] ? initialData[field.name] : undefined;
        let images: string[] = [];
        if (defaultValue) {
          images = (defaultValue as string).split('<br/>');
        }
        return <FileUploader {...field} defaultImages={images} setValue={setValue} />;
      },
      [initialData]
    );

    const getInput = useCallback(
      (variableData: VariableData) => {
        const required = variableData?.obrigatorio ? 'Campo obrigatório' : false;
        const defaultValue = initialData[variableData?.idVariavel as string]
          ? initialData[variableData?.idVariavel as string]
          : undefined;
        switch (variableData.descricaoTipoVariavel) {
          case 'Data':
            return (
              <FormGroup
                key={variableData?.idVariavel}
                error={errors[variableData?.idVariavel as string]?.message as string}>
                <Paragraph>{variableData.descricaoVariavel}</Paragraph>
                <Input
                  label={variableData.nomeVariavel}
                  mask="99/99/9999"
                  {...register(variableData?.idVariavel as string, {
                    required,
                    value: defaultValue,
                  })}
                  data-optional={!required}
                />
              </FormGroup>
            );
          case 'Hora':
            return (
              <FormGroup
                key={variableData?.idVariavel}
                error={errors[variableData?.idVariavel as string]?.message as string}>
                <Paragraph>{variableData.descricaoVariavel}</Paragraph>
                <Input
                  label={variableData.nomeVariavel}
                  mask="99:99"
                  {...register(variableData?.idVariavel as string, {
                    required,
                    value: defaultValue,
                  })}
                  data-optional={!required}
                />
              </FormGroup>
            );
          case 'Data e Hora':
            return (
              <FormGroup
                key={variableData?.idVariavel}
                error={errors[variableData?.idVariavel as string]?.message as string}>
                <Paragraph>{variableData.descricaoVariavel}</Paragraph>
                <Input
                  label={variableData.nomeVariavel}
                  mask="99/99/9999 99:99"
                  {...register(variableData?.idVariavel as string, {
                    required,
                    value: defaultValue,
                  })}
                  data-optional={!required}
                />
              </FormGroup>
            );
          case 'Número real':
            return (
              <FormGroup
                key={variableData?.idVariavel}
                error={errors[variableData?.idVariavel as string]?.message as string}>
                <Paragraph>{variableData.descricaoVariavel}</Paragraph>
                <Input
                  label={variableData.nomeVariavel}
                  type="number"
                  {...register(variableData?.idVariavel as string, {
                    required,
                    value: defaultValue,
                  })}
                  step="0.01"
                  data-optional={!required}
                />
              </FormGroup>
            );
          case 'Número inteiro':
            return (
              <FormGroup
                key={variableData?.idVariavel}
                error={errors[variableData?.idVariavel as string]?.message as string}>
                <Paragraph>{variableData.descricaoVariavel}</Paragraph>
                <Input
                  label={variableData.nomeVariavel}
                  type="number"
                  {...register(variableData?.idVariavel as string, {
                    required,
                    value: defaultValue,
                  })}
                  step="1"
                  data-optional={!required}
                />
              </FormGroup>
            );
          case 'Moeda':
            return (
              <FormGroup
                key={variableData?.idVariavel}
                error={errors[variableData?.idVariavel as string]?.message as string}>
                <Paragraph>{variableData.descricaoVariavel}</Paragraph>
                <Input
                  label={variableData.nomeVariavel}
                  currency
                  mask="R$ 999999999999999"
                  {...register(variableData?.idVariavel as string, {
                    required,
                    value: defaultValue,
                  })}
                  data-optional={!required}
                />
              </FormGroup>
            );
          case 'Lista suspensa':
            return (
              <FormGroup
                key={variableData?.idVariavel}
                error={errors[variableData?.idVariavel as string]?.message as string}>
                <Paragraph className="label">{variableData.nomeVariavel}</Paragraph>
                <Paragraph>{variableData.descricaoVariavel}</Paragraph>
                <Controller
                  name={variableData?.idVariavel as string}
                  control={control}
                  render={renderSelect}
                  rules={{ required }}
                  defaultValue={
                    defaultValue
                      ? {
                          label: defaultValue,
                          value: defaultValue,
                        }
                      : undefined
                  }
                  data-optional={!required}
                />
                <div data-optional={!required}></div>
              </FormGroup>
            );
          case 'Multipla escolha':
            return (
              <div key={variableData?.idVariavel}>
                <FormGroup error={errors[variableData?.idVariavel as string]?.message as string}>
                  <Paragraph className="label">{variableData.nomeVariavel}</Paragraph>
                  <Paragraph>{variableData.descricaoVariavel}</Paragraph>
                  <div className="switch-list">
                    <SwitchForm
                      name={`as-list-${variableData?.idVariavel as string}`}
                      position="left"
                      label="Exibir como lista?"
                      control={control}
                      defaultValue={!!initialData[`as-list-${variableData?.idVariavel as string}`]}
                    />
                  </div>
                  {variableData?.valorAuxiliar?.split('¨').map((value) => (
                    <Checkbox
                      key={value}
                      id={value}
                      label={value}
                      {...register(variableData?.idVariavel as string, {
                        required,
                        value: defaultValue,
                      })}
                      value={value}
                      data-optional={!required}
                    />
                  ))}
                </FormGroup>
              </div>
            );
          case 'Texto longo':
            return (
              <FormGroup
                key={variableData?.idVariavel}
                error={errors[variableData?.idVariavel as string]?.message as string}>
                <Paragraph>{variableData.descricaoVariavel}</Paragraph>
                <TextArea
                  label={variableData.nomeVariavel}
                  {...register(variableData?.idVariavel as string, {
                    required,
                    value: defaultValue,
                  })}
                  data-optional={!required}
                />
              </FormGroup>
            );
          case 'Upload de imagens':
            return (
              <FormGroup
                key={variableData?.idVariavel}
                error={errors[variableData?.idVariavel as string]?.message as string}>
                <Paragraph className="label">{variableData.nomeVariavel}</Paragraph>
                <Paragraph>{variableData.descricaoVariavel}</Paragraph>
                <Controller
                  name={variableData?.idVariavel as string}
                  control={control}
                  render={renderFileUploader}
                  rules={{ required }}
                  defaultValue={defaultValue ? (defaultValue as string).split('<br/>') : ''}
                />
              </FormGroup>
            );
          default:
            return (
              <FormGroup
                key={variableData?.idVariavel}
                error={errors[variableData?.idVariavel as string]?.message as string}>
                <Paragraph>{variableData.descricaoVariavel}</Paragraph>
                <Input
                  label={variableData.nomeVariavel}
                  {...register(variableData?.idVariavel as string, {
                    required,
                    value: defaultValue,
                  })}
                  data-optional={!required}
                />
              </FormGroup>
            );
        }
      },
      [errors]
    );

    const onSubmit = useCallback((data: DocumentDataForm) => {
      onNext(data);
    }, []);

    return (
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Heading>{text?.nome}</Heading>
          <div className="variables">{text?.variaveis.map((variable) => getInput(variable))}</div>
        </div>
        <div className="buttons">
          <Button type="button" kind="secondary" onClick={onBack}>
            Voltar
          </Button>
          <Button type="submit">Próximo</Button>
        </div>
      </StyledForm>
    );
  }
);

DocumentTextForm.displayName = 'DocumentTextForm';

export default DocumentTextForm;
