import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[4]};

    button {
      width: 25rem;
    }
  }

  .search-filter-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings[1.5]};

    input {
      width: 40rem;
    }
  }
`;

export const StyledForm = styled.form`
  padding-bottom: 10rem;
  width: 100%;
  h1 {
    margin-bottom: ${theme.spacings[4]};
  }
  .switch {
    margin-top: ${theme.spacings[2]};
    display: flex;
    justify-content: flex-end;
    svg {
      height: 1.6rem;
      margin-left: ${theme.spacings[0.5]};
    }
    label {
      display: flex;
      align-items: center;
    }
  }
  .subheader {
    margin: ${theme.spacings[4]} 0 ${theme.spacings[2]};
    font-size: ${theme.fonts['medium']};
  }
  .default-value {
    margin-top: ${theme.spacings[1]};
  }
  .value-container {
    position: relative;
    margin-top: ${theme.spacings[1]};
    section {
      display: grid;
      grid-template-columns: 3fr 1fr 3fr;
      .value-separator {
        display: flex;
        justify-content: center;
        margin-top: 4.8rem;
      }
      button {
        position: absolute;
        right: -1.6rem;
        top: 0;
        svg {
          height: 2rem;
          stroke: ${theme.colors.grey[900]};
        }
      }
    }
  }
`;

export const VariableFormContainer = styled.div`
  .values-container {
    margin-top: ${theme.spacings[3]};
    margin-bottom: 10rem;
  }
`;

export const DynamicVariableContainer = styled.div`
  margin-bottom: 10rem;
  > div {
    background: none;
    max-width: 92rem;
    margin: 0 auto;
    > div {
      padding: ${theme.spacings[1.5]} ${theme.spacings[3]};
    }
    > div + div {
      margin-top: ${theme.spacings[2]};
    }
  }
  h1 {
    margin-bottom: 0;
  }
  .variable-info {
    border-top: 0.1rem solid ${theme.colors.grey[200]};
    border-bottom: 0.1rem solid ${theme.colors.grey[200]};
    margin: ${theme.spacings[4]} -${theme.spacings[3]} ${theme.spacings[3]};
    background: ${theme.colors.grey[50]};
    padding: ${theme.spacings[1.5]} ${theme.spacings[3]};
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    .key {
      color: ${theme.colors.grey[400]};
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: ${theme.spacings[0.5]};
    }
    .value {
      font-size: ${theme.fonts['x-small']};
      line-height: ${theme.fonts['large']};
    }
  }
  .second-step {
    margin-top: ${theme.spacings[4]};
    .button-container {
      display: flex;
      justify-content: flex-end;
      button {
        width: 18rem;
        padding-right: 0;
      }
    }
  }
`;

export const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    align-items: center;

    p {
      margin-left: ${theme.spacings[1]};
      color: ${theme.colors.blue[600]};
      font-weight: bold;
    }

    &.separator {
      width: 8rem;
      height: 0.1rem;
      background: ${theme.colors.blue[100]};
      margin: 0 ${theme.spacings[1]};
    }

    &.disabled {
      opacity: 0.6;
    }
  }
  svg {
    height: 2rem;
    width: 2rem;
    fill: ${theme.colors.blue[50]};
    stroke: ${theme.colors.blue[600]};
  }
  img {
    height: 1.6rem;
    width: 1.6rem;
  }
`;
