import React from 'react';
import { StyledButton } from './styles';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  kind?: string;
  children: React.ReactNode;
  fullWidth?: boolean;
}

const Button = React.forwardRef<HTMLInputElement, ButtonProps>(({ ...props }, ref) => {
  const { kind, children, fullWidth } = props;

  const getClassName = () => {
    let className = '';
    className += fullWidth ? 'full-width' : '';
    className += kind ? ' ' + kind : '';
    return className;
  };

  return (
    <StyledButton {...props} className={getClassName()}>
      {children}
    </StyledButton>
  );
});

Button.displayName = 'Button';
export default Button;
