import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TemplateFormValues } from '../../types/Template.types';
import FormGroup from '../../components/Form/FormGroup';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import Box from '../../components/Box';
import Heading from '../../components/Text/Heading';
import { StyledForm } from './styles';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import Paragraph from '../../components/Text/Paragraph';
import { Select } from '../../components/Form/Select';
import { SwitchForm } from '../../components/Form/Switch';

interface TemplateFormProps {
  type: 'create' | 'update';
  initialData?: TemplateFormValues;
  onSubmit: (data: TemplateFormValues) => void;
}

const validationSchema = yup.object({
  name: yup.string().required('O campo nome é obrigatório'),
  client: yup.object().shape({
    label: yup.string().required('O campo cliente é obrigatório'),
    value: yup.string().required(),
  }),
});

const TemplateForm = ({ type, initialData, onSubmit }: TemplateFormProps) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm<TemplateFormValues>({
    defaultValues: initialData,
    resolver: yupResolver(validationSchema),
  });
  const watchActive = watch('active');

  const navigate = useNavigate();
  const navigateToListTemplates = useCallback(() => {
    navigate('/templates');
  }, []);

  const title = {
    create: 'Criar novo template',
    update: 'Editar template',
  };

  const renderSelectClients = useCallback(
    ({ field }: any) => (
      <>
        <Paragraph className="label">Cliente</Paragraph>
        <Select
          placeholder="Escolha o cliente"
          {...field}
          options={[{ label: 'Cliente padrão', value: 'Cliente padrão' }]}
        />
      </>
    ),
    []
  );

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box
          footer={
            <>
              <Button className="cancel-button" kind="alert" onClick={navigateToListTemplates}>
                Voltar
              </Button>
              <Button className="submit-button" type="submit">
                Próximo
              </Button>
            </>
          }>
          <div className="content">
            <Heading>{title[type]}</Heading>
            <Box grid={2} padding={false} border={false}>
              <FormGroup error={errors.name?.message}>
                <Input {...register('name')} label="Nome do template" />
              </FormGroup>
              <FormGroup error={errors.client?.label?.message}>
                <Controller name="client" control={control} render={renderSelectClients} />
              </FormGroup>
              <FormGroup error={errors.active?.message}>
                <Paragraph className="label">Ativar modelo?</Paragraph>
                <div className="switch">
                  <SwitchForm name="active" position="left" label="Ativar template?" control={control} />
                  <Paragraph>{watchActive === true ? 'Sim' : 'Não'}</Paragraph>
                </div>
              </FormGroup>
            </Box>
          </div>
        </Box>
      </StyledForm>
    </>
  );
};

export default TemplateForm;
