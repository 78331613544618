import { DocumentDuplicateIcon, EllipsisVerticalIcon, PencilIcon } from '@heroicons/react/24/outline';
import { SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { conditionService } from '../../api/services/';
import DropdownMenu from '../../components/DropdownMenu';
import { Content, Item, Separator } from '../../components/DropdownMenu/styles';
import { showToast } from '../../components/Toast/showToast';
import { DialogCancelDelete } from '../shared/DialogCancelDelete';

interface ConditionActionsProps {
  id: string;
  name: string;
  setDeletedItem: React.Dispatch<SetStateAction<string>>;
}

const ConditionActions = ({ id, name, setDeletedItem }: ConditionActionsProps) => {
  const [openedActions, setOpenedActions] = useState(false);

  const navigate = useNavigate();
  const navigateToUpdateCondition = useCallback(() => {
    navigate(`/conditions/${id}/update`);
  }, []);
  const navigateToDuplicateCondition = useCallback(() => {
    navigate(`/conditions/${id}/duplicate`);
  }, []);

  const removeCondition = useCallback(
    (id: string) => async () => {
      const successfullyDeleted = await conditionService.delete(id);
      if (successfullyDeleted) {
        showToast({
          type: 'success',
          text: 'Sucesso ao remover condição.',
        });
        setDeletedItem(id);
      }
      setOpenedActions(false);
    },
    []
  );

  return (
    <DropdownMenu
      open={openedActions}
      setOpen={setOpenedActions}
      content={
        <Content sideOffset={5}>
          <Item onClick={navigateToUpdateCondition}>
            <PencilIcon /> <span>Editar condição</span>
          </Item>

          <Separator />

          <Item onClick={navigateToDuplicateCondition}>
            <DocumentDuplicateIcon /> <span>Duplicar condição</span>
          </Item>

          <Separator />

          <DialogCancelDelete itemName={name} itemType="condição" removeItem={removeCondition(id)} />
        </Content>
      }>
      <EllipsisVerticalIcon className="actions-button" />
    </DropdownMenu>
  );
};

export default ConditionActions;
