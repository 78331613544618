import * as DialogComponent from '@radix-ui/react-dialog';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { DialogContent, DialogOverlay, ButtonsContainer } from './styles';

type DialogProps = {
  children: string | React.ReactNode;
  content: string | React.ReactNode;
  title?: string | React.ReactNode;
  primaryButton?: React.ReactNode;
  secondaryButton?: React.ReactNode;
  buttonsStyle?: string;
  controlledClose?: boolean;
  open?: boolean;
  size?: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Dialog = ({
  controlledClose,
  open = false,
  setOpen,
  children,
  content,
  title = false,
  primaryButton,
  secondaryButton,
  buttonsStyle = 'column',
  size = 'default',
}: DialogProps) => {
  const rootProps = controlledClose ? { open, onOpenChange: setOpen } : {};

  return (
    <DialogComponent.Root {...rootProps}>
      <DialogComponent.Trigger asChild>{children}</DialogComponent.Trigger>
      <DialogComponent.Portal>
        <DialogOverlay />
        <DialogContent className={`${title ? 'has-title' : ''} ${size}`}>
          {title ? <div className="title">{title}</div> : null}
          {content}
          {primaryButton || secondaryButton ? (
            <ButtonsContainer className={buttonsStyle}>
              {primaryButton && <DialogComponent.Close asChild>{primaryButton}</DialogComponent.Close>}
              {secondaryButton && <DialogComponent.Close asChild>{secondaryButton}</DialogComponent.Close>}
            </ButtonsContainer>
          ) : null}
          <DialogComponent.Close asChild>
            <button className="close-button" aria-label="Close">
              <XMarkIcon />
            </button>
          </DialogComponent.Close>
        </DialogContent>
      </DialogComponent.Portal>
    </DialogComponent.Root>
  );
};

export default Dialog;
