import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../../../api/services/AuthService';
import { AuthDataRequest } from '../../../types/Auth.types';
import { getEncodedUserData } from './utils';

export const userLogin = createAsyncThunk('user/login', async (user: AuthDataRequest, thunkApi) => {
  const authService = new AuthService();

  try {
    const response = await authService.authenticate(user);
    if (response) {
      localStorage.setItem('userToken', response.token);
      const userData = getEncodedUserData(response.login);
      localStorage.setItem('userData', userData);
      return response;
    } else {
      return thunkApi.rejectWithValue('Erro ao realizar operação');
    }
  } catch (error: unknown) {
    return thunkApi.rejectWithValue('Erro ao realizar operação');
  }
});

interface AuthState {
  loading: boolean;
  userToken: string | null;
  error: string | unknown;
  success: boolean;
  userData: string | null;
}
/* istanbul ignore next */
const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null;
/* istanbul ignore next */
const userData = localStorage.getItem('userData') ? localStorage.getItem('userData') : null;
const initialState: AuthState = {
  loading: false,
  userToken,
  error: null,
  success: false,
  userData,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('userToken');
      localStorage.removeItem('userData');
      state.loading = false;
      state.userToken = null;
      state.userData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.success = true;
      state.loading = false;
      state.userToken = action.payload.token;
      state.userData = getEncodedUserData(action.payload.login);
    });

    builder.addCase(userLogin.rejected, (state, action) => {
      state.success = false;
      state.loading = false;
      state.userToken = null;
      state.userData = null;
      state.error = action.payload;
    });
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
