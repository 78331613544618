/* istanbul ignore file */

export const getToken = () => {
  return window.localStorage.getItem('userToken');
};

export const removeToken = () => {
  window.localStorage.removeItem('userToken');
};

export const setLocation = (location: string) => {
  window.location.href = location;
};
