import { OptionType } from '../../components/Form/Select';
import Badge from '../../components/Text/Badge';
import { ConditionData, ConditionOption } from '../../types/Condition.types';
import { ConditionAssociationData } from '../../types/ConditionAssociation.types';
import { DocumentDataForm, DocumentFlow, DocumentTextItem, SingleDocumentData } from '../../types/Document.types';
import { ModelFlow, SingleModelData } from '../../types/Model.types';
import { TemplateData } from '../../types/Template.types';
import { SingleTextData } from '../../types/Text.types';
import { SingleVariableData } from '../../types/Variable.types';
import { DocumentTextFormHandle } from './DocumentTextForm';
import { InitialDataFormValues } from './InitialData';
import React from 'react';
import parse, { DOMNode, Element } from 'html-react-parser';

export const convertToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getType = (type: string | undefined, asList: boolean) => {
  if (asList) return 'list';
  else if (type === 'Upload de imagens') return 'images';
  else if (type === 'Multipla escolha') return 'multiple-choice';
  else if (type === 'Lista suspensa') return 'suspense-list';
  return 'text';
};

export const getValue = (value: string) => {
  const hasPipe = value?.split('|')?.length > 1;
  return hasPipe ? value?.split('|')[1]?.trim() : value;
};

export const parseValue = (value: string | string[] | OptionType | boolean, type: string) => {
  let returnedValue = value;
  if (type === 'list') {
    let list = '<ul>';
    (value as string[]).forEach((value) => {
      list += `<li>${getValue(value)}</li>`;
    });
    list += '</ul>';
    return { value: (value as string[]).join(', '), text: list };
  } else if (type === 'images') {
    returnedValue = Array.isArray(value) ? value.join('<br/>') : value;
  } else if (type === 'multiple-choice') {
    const items = Array.isArray(value)
      ? value.map((val) => getValue(val))
      : (value as string).split(', ').map((val) => getValue(val));
    let text = items.slice(0, -1).join(', ') + ' e ' + items.slice(-1);
    if (items.length === 0) text = '';
    if (items.length === 1) text = items[0];
    return { value: items.join(', '), text };
  } else if (type === 'suspense-list') {
    const formattedValue = typeof value === 'object' ? (value as OptionType).value : value;
    return { value: formattedValue, text: getValue(formattedValue as string) };
  }
  return { value: null, text: returnedValue };
};

export const replaceVariableValues = (
  text: string,
  documentDataValues: DocumentDataForm,
  variables: SingleVariableData[]
) => {
  let treatedText = text;
  for (const key of Object.keys(documentDataValues)) {
    if (key.startsWith('as-list-')) continue;
    //if (key.startsWith('suspense-list-')) continue;
    if (documentDataValues[key]) {
      const variableData = variables.find((variable) => variable.idVariavel === key);
      const type = getType(variableData?.descricaoTipoVariavel, !!documentDataValues[`as-list-` + key]);
      const { value, text } = parseValue(documentDataValues[key], type);
      const pattern = new RegExp(
        `<span\\s+class="(initial-variable|replaced-variable)"\\s+data-variable-id="${key}".*?>.*?<\\/span>`,
        'gi'
      );
      /* task 7347 - Auto Complete variável já usada */
      const pattern2 = new RegExp(
        `<span\\s+data-variable-id="${key}".*?><span\\s+class="(initial-variable|replaced-variable)".*?>.*?<\\/span><\\/span>`,
        'gi'
      );
      let formattedText;
      if (typeof text === 'string') {
        formattedText = text?.replaceAll('\n', '<br>');
      } else {
        formattedText = text;
      }
      treatedText = treatedText?.replace(
        pattern,
        `<span class="replaced-variable" data-variable-id="${key}" type="${type}"${
          value !== null ? ` value="${value}"` : ''
        }>${formattedText}</span>`
      );

      /* task 7347 - Auto Complete variável já usada */
      treatedText = treatedText?.replace(
        pattern2,
        `<span class="replaced-variable" data-variable-id="${key}" type="${type}"${
          value !== null ? ` value="${value}"` : ''
        }>${formattedText}</span>`
      );
    }
  }
  return treatedText;
};

export const getInitialDataText = (text: string) => {
  let treatedText = text?.replaceAll('class="variable"', 'class="initial-variable"');
  treatedText = treatedText?.replaceAll('class="footnote"', 'class="replaced-footnote"');

  const regex =
    /<span\s+class="replaced-variable"\s+data-variable-id="([^"]+)"(?:\s+type="([^"]+)")?(?:\s+value="([^"]+)")?>(.*?)<\/span>/g;
  const dataValues: DocumentDataForm = {};

  let match;
  while ((match = regex.exec(text)) !== null) {
    const variableId = match[1];
    const type = match[2] || '';
    const value = match[3] || '';
    const textValue = match[4];
    dataValues[variableId] = value ? value : textValue;
    if (type == 'list') {
      dataValues[`as-list-` + variableId] = true;
    }
  }

  return { treatedText: treatedText, values: dataValues };
};

export const getBadge = (finished: string) => {
  if (finished) return <Badge color="green">Pronto</Badge>;
  return <Badge color="red">Em andamento</Badge>;
};

export const createTree = (flow: ModelFlow[], targetElement: string) => {
  const tree: {
    root: ModelFlow | null;
    maxDepth: number;
    targetDepth: number;
  } = { root: null, maxDepth: 1, targetDepth: 0 };

  function buildTree(element: ModelFlow, depth: number, targetId: string): any {
    const node: {
      id?: string;
      tipoFluxo?: string;
      children?: ModelFlow[];
    } = {
      id: element.chaveElemento,
      tipoFluxo: element.modeloTipoFluxo.descricao,
      children: [],
    };

    let maxDepth = depth;
    let targetDepth = null;

    if (element.chaveElemento === targetId) {
      targetDepth = depth;
    }

    if (element.chaveElementoSaida) {
      const entradaArr = element.chaveElementoSaida.split('¨');

      for (const entrada of entradaArr) {
        const child = flow.find((el) => el.chaveElemento === entrada);
        if (child) {
          const { maxDepth: childMaxDepth, targetDepth: childTargetDepth } = buildTree(child, depth + 1, targetId);
          maxDepth = Math.max(maxDepth, childMaxDepth);
          if (childTargetDepth !== null) {
            targetDepth = childTargetDepth;
          }
          node?.children?.push(child);
        }
      }
    }

    return { maxDepth, targetDepth };
  }

  const rootElement = flow.find((el) => el.modeloTipoFluxo.descricao === 'Inicio');

  if (rootElement) {
    tree.root = rootElement;
    const { maxDepth, targetDepth } = buildTree(rootElement, 0, targetElement);
    tree.maxDepth = maxDepth;
    tree.targetDepth = targetDepth;
  }

  return tree;
};

export const getDynamicVariable = (
  currentText: SingleTextData | undefined,
  documentDataVariables: DocumentDataForm,
  documentFormRef: React.RefObject<DocumentTextFormHandle>
) => {
  let treatedDocumentDataVariables = documentDataVariables;
  /* istanbul ignore next */
  if (currentText?.variaveis && currentText?.variaveis.length > 0) {
    for (const variable of currentText.variaveis) {
      if (variable?.variavelRelacao && variable?.variavelRelacao?.length > 0) {
        for (const relation of variable.variavelRelacao) {
          if (
            (documentDataVariables[relation.idVariavelPrincipal] as OptionType)?.value ===
              relation.valorVariavelPrincipal &&
            (!documentDataVariables[relation.idVariavelDependente] ||
              documentDataVariables[relation.idVariavelDependente] === '') &&
            relation.idVariavelPrincipal in documentDataVariables
          ) {
            documentFormRef!.current!.setFormValue(
              relation.idVariavelDependente,
              (documentDataVariables[relation.idVariavelPrincipal] as OptionType)?.value ===
                relation.valorVariavelPrincipal
                ? relation.valorVariavelDependente
                : ''
            );
            treatedDocumentDataVariables = {
              ...treatedDocumentDataVariables,
              [relation.idVariavelDependente]: {
                label: relation.valorVariavelDependente,
                value: relation.valorVariavelDependente,
              },
            };
          }
        }
      }
    }
  }
  return treatedDocumentDataVariables;
};

export const getConditionValue = (
  currentCondition: ConditionData | undefined,
  savedConditionValues: string[] | undefined,
  documentData: DocumentDataForm,
  conditionAssociationData: ConditionAssociationData[] | undefined
) => {
  const conditionId = currentCondition?.idModeloCondicao as string;
  const options = currentCondition?.condicaoOpcoes.map((option) => option.idModeloCondicaoOpcao);
  const optionInSavedConditionValues = options?.some((option) => savedConditionValues?.includes(option as string));

  const newDataFromMock: any = {};
  if (currentCondition) {
    currentCondition.condicaoOpcoes.forEach((option: any) => {
      if (option.variavelVinculadaAtiva && option.idVariavelVinculada) {
        const documentValue: any = documentData[option.idVariavelVinculada];
        if (documentValue) {
          if (documentValue.value == option.optionVariableValue) {
            if (currentCondition?.idModeloCondicao != undefined) {
              newDataFromMock[currentCondition?.idModeloCondicao] = option.idModeloCondicaoOpcao;
            }
          }
        }
      }
    });
  }

  documentData = { ...documentData, ...newDataFromMock };

  if (documentData && documentData[conditionId]) {
    return documentData[conditionId] as string;
  } else if (optionInSavedConditionValues) {
    let prefilledOption = '';
    options?.forEach((option) => {
      if (savedConditionValues?.includes(option as string)) {
        prefilledOption = option as string;
      }
    });
    return prefilledOption;
  } else {
    const conditionValue = conditionAssociationData?.find(
      (conditionAssociation) =>
        conditionAssociation.modeloCondicaoOpcaoResponseVinculada?.idModeloCondicao === conditionId
    );
    if (
      conditionValue &&
      documentData[conditionValue?.modeloCondicaoOpcaoResponsePadrao?.idModeloCondicao as string] &&
      documentData[conditionValue?.modeloCondicaoOpcaoResponsePadrao?.idModeloCondicao as string] ===
        conditionValue?.modeloCondicaoOpcaoResponsePadrao?.idModeloCondicaoOpcao
    ) {
      return conditionValue?.modeloCondicaoOpcaoResponseVinculada?.idModeloCondicaoOpcao;
    }
    return undefined;
  }
};

export const getNextFlow = (
  step: string,
  documentData: DocumentDataForm,
  currentFlow: ModelFlow | null,
  modelFlow: ModelFlow[],
  currentCondition: ConditionData | undefined
) => {
  let nextFlow: ModelFlow | undefined = undefined;
  if (step === 'text') {
    const connectionFlow = modelFlow.find((flow) => flow.chaveElemento === currentFlow?.chaveElementoSaida);
    nextFlow = modelFlow.find((flow) => flow.chaveElemento === connectionFlow?.chaveElementoSaida);
  } else {
    const selectedValue = documentData[currentCondition?.idModeloCondicao as string];
    let connectionFlow = modelFlow.find(
      (flow) =>
        flow?.modeloCondicaoOpcaoResponse?.idModeloCondicaoOpcao === selectedValue &&
        flow?.chaveElementoEntrada === currentFlow?.chaveElemento
    );

    if (connectionFlow == undefined) {
      connectionFlow = modelFlow.find(
        (flow) => flow?.modeloCondicaoOpcaoResponse?.idModeloCondicaoOpcao === selectedValue
      );
    }

    nextFlow = modelFlow.find((flow) => flow.chaveElemento === connectionFlow?.chaveElementoSaida);
  }
  return nextFlow;
};

export const getDocumentFlow = (
  step: string,
  textItems: DocumentTextItem,
  documentData: DocumentDataForm,
  currentText: SingleTextData | undefined,
  documentFlow: DocumentFlow[],
  currentFlow: ModelFlow | null,
  currentCondition: ConditionData | undefined
) => {
  const newDocumentFlow: DocumentFlow[] = documentFlow;
  if (step === 'text') {
    const currentTextItem = textItems[currentText?.idTexto as string];
    const treatedText = replaceVariableValues(currentTextItem, documentData, currentText!.variaveis);
    newDocumentFlow?.push({
      idModeloVsFluxo: currentFlow?.textoResponse?.idTexto,
      valorTexto: treatedText,
    });
  } else {
    const selectedValue = documentData[currentCondition?.idModeloCondicao as string];
    newDocumentFlow?.push({ idModeloVsFluxo: selectedValue as string, valorTexto: '' });
  }
  return newDocumentFlow;
};

function isOptionType(value: any): value is OptionType {
  return (value as OptionType).value !== undefined;
}

export const getInitialVariableValues = (
  initialData: {
    treatedText: string;
    values: DocumentDataForm;
  },
  textData: SingleTextData | undefined,
  variablesProcess: {
    [key: string]: string;
  },
  documentData: DocumentDataForm
) => {
  let treatedDocumentDataVariables = initialData.values;
  if (textData?.variaveis && textData?.variaveis.length > 0) {
    for (const variable of textData.variaveis) {
      if (
        variable.integracaoMyLegal === true &&
        variable?.nomeVariavel &&
        variable?.nomeVariavel.toLowerCase() in variablesProcess
      ) {
        treatedDocumentDataVariables = {
          ...treatedDocumentDataVariables,
          [variable.idVariavel as string]: variablesProcess[variable.nomeVariavel.toLowerCase()],
        };
      }

      /* task 7347, 8728 - Auto Complete variável já usada */
      if (variable.descricaoTipoVariavel != 'Upload de imagens') {
        for (const key of Object.keys(documentData)) {
          if (documentData[key] && key == variable.idVariavel) {
            switch (variable.descricaoTipoVariavel) {
              case 'Lista suspensa':
                switch (typeof documentData[key]) {
                  case 'string':
                    treatedDocumentDataVariables = {
                      ...treatedDocumentDataVariables,
                      [variable.idVariavel as string]: documentData[key] as string,
                    };
                    break;
                  default:
                    if (isOptionType(documentData[key])) {
                      treatedDocumentDataVariables = {
                        ...treatedDocumentDataVariables,
                        [variable.idVariavel as string]: (documentData[key] as OptionType).value,
                      };
                    } else {
                      throw new Error(`Unsupported type for key: ${key}`);
                    }
                    break;
                }
                break;

              case 'Multipla escolha':
                treatedDocumentDataVariables = {
                  ...treatedDocumentDataVariables,
                  [variable.idVariavel as string]: documentData[key] as string[],
                };
                break;

              default:
                treatedDocumentDataVariables = {
                  ...treatedDocumentDataVariables,
                  [variable.idVariavel as string]: documentData[key],
                };
                break;
            }
          }
        }
      }

      if (variable?.variavelRelacao && variable?.variavelRelacao?.length > 0) {
        for (const relation of variable.variavelRelacao) {
          if (
            relation?.idVariavelPrincipal in documentData &&
            (documentData[relation.idVariavelPrincipal] as OptionType)?.value?.toLowerCase() ===
              relation?.valorVariavelPrincipal?.toLowerCase()
          ) {
            treatedDocumentDataVariables = {
              ...treatedDocumentDataVariables,
              [relation.idVariavelDependente]: relation.valorVariavelDependente,
            };
          }
          // else if (
          //   relation?.idVariavelPrincipal in documentData &&
          //   (documentData[relation.idVariavelPrincipal] as OptionType)?.value?.toLowerCase() !==
          //     relation?.valorVariavelPrincipal?.toLowerCase()
          // ) {
          //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
          //   const { [relation.idVariavelDependente]: _, ...rest } = treatedDocumentDataVariables;
          //   treatedDocumentDataVariables = rest;
          // }
        }
      }
    }
  }
  return treatedDocumentDataVariables;
};

export const getProgress = (initialModel: SingleModelData, element: string | undefined, step: string) => {
  // const tree = createTree(initialModel?.fluxosResponse, element as string);
  // const progress = (tree?.targetDepth / tree?.maxDepth) * 100;
  return 100;
};

export const getTemplate = (templateData: TemplateData | undefined) => {
  const config = JSON.parse(templateData?.valorConfiguracao as string);
  return {
    font: config?.fonte,
    font_size: config.tamanhoFonte,
    page_size: config.tamanhoPagina,
    margin: {
      top: config.margemSuperior,
      bottom: config.margemInferior,
      left: config.margemEsquerda,
      right: config.margemDireita,
    },
    header: [templateData?.valorCabecalho as string],
    topic: [templateData?.valorTopico as string],
    footer: [templateData?.valorRodape as string],
  };
};

export const getInitialData = (documentData: SingleDocumentData, templateData: TemplateData | undefined) => {
  return {
    name: documentData?.nomeDocumento as string,
    model: {
      label: documentData?.modeloResponse.nomeModelo as string,
      value: documentData?.modeloResponse.idModelo as string,
    },
    folder: documentData?.clienteCodigoPasta as string,
    client: documentData?.clienteCodigo as string,
    clientName: documentData?.clienteNome as string,
    template: {
      label: templateData?.nomeTemplate as string,
      value: templateData?.idTemplate as string,
    },
  };
};

export const getCreateUpdateData = (
  initialData: InitialDataFormValues,
  templateId: string | undefined,
  text: string,
  documentFlow: DocumentFlow[],
  finished: boolean
) => {
  return {
    nomeDocumento: initialData.name,
    idModelo: initialData.model.value,
    idTemplate: templateId,
    clienteNome: initialData.clientName,
    clienteCodigo: initialData.client,
    clienteCodigoPasta: initialData.folder,
    valorTexto: text,
    finalizado: finished,
    modeloVsFluxoRequests: documentFlow,
  };
};

const isHtml = (text: string): boolean => {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlRegex.test(text);
};

function listPropText(data: DocumentDataForm | null, variables: SingleVariableData[] | undefined) {
  const _variables = [];
  for (const i in data) {
    let varValue = data[i];

    //Verifica se contem html no valor para caso seja um campo de imagem.
    if (varValue && isHtml(varValue.toString())) {
      varValue = 'Imagem anexada';
    }

    _variables.push({
      name: getVariableNameText(variables, i),
      value: varValue,
    });
  }
  // return Object.assign(
  //   {},
  //   _variables.reduce((a, v) => ({ ...a, [v.name]: v.value }), {})
  // );
  return Object.assign({}, _variables);
}

function getVariableNameText(variables: SingleVariableData[] | undefined, variableId: string) {
  const asList = variableId.startsWith('as-list');

  if (asList) {
    variableId = variableId.replace('as-list-', '');
  }

  const variable = variables?.find((x) => x.idVariavel === variableId);

  if (variable == null || variable.nomeVariavel == null) {
    return variableId;
  }
  let variableName = variable.nomeVariavel;
  if (asList) {
    variableName += ' (Exibir como lista)';
  }

  return variableName;
}

function listPropCondition(data: DocumentDataForm | null, variables: ConditionData | undefined) {
  const _variables = [];
  for (const i in data) {
    _variables.push({
      name: getVariableNameCondition(variables, i),
      value: getVariableValueCondition(variables?.condicaoOpcoes, data[i].toString()),
    });
  }
  // return Object.assign(
  //   {},
  //   _variables.reduce((a, v) => ({ ...a, [v.name]: v.value }), {})
  // );
  return Object.assign({}, _variables);
}

function getVariableNameCondition(condition: ConditionData | undefined, conditionId: string) {
  if (conditionId === condition?.idModeloCondicao) {
    return condition?.titulo;
  }
  return conditionId;
}

function getVariableValueCondition(conditions: ConditionOption[] | undefined, conditionId: string) {
  const variable = conditions?.find((x) => x.idModeloCondicaoOpcao === conditionId);
  if (variable == null || variable.descricao == null) {
    return conditionId;
  }
  return variable.descricao;
}

export const getLogStep = (
  action: string,
  data: DocumentDataForm | null,
  currentFlow: ModelFlow | null,
  currentText: SingleTextData | undefined,
  currentCondition: ConditionData | undefined
) => {
  let etapa: string | undefined = '';
  let tipoEtapa = '';
  let formData: { name: string | undefined; value: string | boolean | OptionType | string[] }[] = [];
  if (currentFlow != null && currentFlow?.modeloCondicaoResponse != null) {
    etapa = currentFlow?.modeloCondicaoResponse?.titulo;
    formData = listPropCondition(data, currentCondition);
    tipoEtapa = 'condition';
  }

  if (currentFlow != null && currentFlow?.textoResponse != null) {
    etapa = currentFlow?.textoResponse?.nome;
    formData = listPropText(data, currentText?.variaveis);
    tipoEtapa = 'text';
  }
  const log: StepLogModel = {
    title: etapa,
    stepType: tipoEtapa,
    action: action,
    data: formData,
  };

  return log;
};

export interface StepLogModel {
  title: string | undefined;
  stepType: string;
  action: string;
  data: Array<{
    name: string | undefined;
    value: string | boolean | OptionType | Array<string>;
  }>;
}

export const stringifyWithArrays = (obj: StepLogModel[]) => {
  return JSON.stringify(transformedData(obj));
};

const transformedData = (obj: StepLogModel[]) =>
  obj.map((item) => {
    return {
      ...item,
      data: Object.values(item.data), // Convert object values to an array
    };
  });
