import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    z-index: 400;
    left: ${theme.spacings[3]};
    bottom: 15rem;
  `}
`;

export const MessageContainer = styled.div<{ type?: string }>`
  ${({ theme, type }) => css`
    color: #fff;
    background: ${theme.colors.neutral.white};
    box-shadow: 0px 8px 24px rgba(55, 65, 81, 0.04);
    padding: ${theme.spacings[3]};
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 41.6rem;

    & + & {
      margin-top: ${theme.spacings[1.5]};
    }

    p {
      color: ${theme.colors.grey[600]};
      font-weight: bold;
    }

    svg {
      height: 2.4rem;
      width: 2.4rem;
      margin-right: ${theme.spacings[2]};
      ${type === 'success' &&
      css`
        fill: ${theme.colors.green[400]};
      `}

      ${type === 'danger' &&
      css`
        fill: ${theme.colors.red[500]};
      `}
    }

    button {
      background: none;
      border: none;
      margin-left: ${theme.spacings[2]};
      cursor: pointer;
      height: 1.6rem;
      width: 1.6rem;

      svg {
        height: 1.6rem;
        width: 1.6rem;
        stroke: ${theme.colors.grey[600]};
        margin-right: 0;
      }
    }
  `}
`;

export const MessageContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;
