import LogoIconWhite from '../../assets/my-legal-docs-logo-white.svg';
import { StyledHeader, Avatar } from './styles';
import Paragraph from '../Text/Paragraph';
import DropdownMenu from '../DropdownMenu';
import HeaderActions from './HeaderActions';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '../../hooks/useStore';
import { getDecodedUserData } from '../../store/slices/user/utils';

const Header = () => {
  const { userData } = useAppSelector((state) => state.user);
  const user = getDecodedUserData(userData as string);

  return (
    <StyledHeader>
      <img src={LogoIconWhite} />

      <DropdownMenu content={<HeaderActions />}>
        <Avatar>
          <Paragraph size="medium">{user?.email}</Paragraph>
          <ChevronDownIcon />
        </Avatar>
      </DropdownMenu>
    </StyledHeader>
  );
};

export default Header;
