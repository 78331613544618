import CommandInterceptor from 'diagram-js/lib/command/CommandInterceptor';

export default class CustomCommandInterceptor extends CommandInterceptor {
  constructor(eventBus, elementRegistry, modeling) {
    super(eventBus);

    this.postExecuted(['shape.create'], ({ context }) => {
      const { shape } = context;
      const currentElement = elementRegistry.get(shape.id);

      if (shape.type === 'bpmn:Task') {
        modeling.updateProperties(currentElement, {
          textId: '',
          name: '',
        });
      }
      if (shape.type === 'bpmn:SubProcess') {
        modeling.updateProperties(currentElement, {
          modelId: '',
          name: '',
        });
      }
      if (shape.type === 'bpmn:ExclusiveGateway') {
        modeling.updateProperties(currentElement, {
          conditionId: '',
          name: '',
        });
      }
      if (shape.type === 'bpmn:EndEvent') {
        modeling.updateProperties(currentElement, {
          name: '',
        });
      }
    });
  }
}

CustomCommandInterceptor.$inject = ['eventBus', 'elementRegistry', 'modeling'];
