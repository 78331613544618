import { useCallback } from 'react';
import UserForm from './UserForm';
import { UserFormValues } from '../../types/User.types';
import { useNavigate } from 'react-router-dom';
import { userService } from '../../api/services';
import { showToast } from '../../components/Toast/showToast';

const CreateUser = () => {
  const navigate = useNavigate();
  const navigateToListUsers = useCallback(() => {
    navigate('/users');
  }, []);

  const handleSubmit = useCallback(async (data: UserFormValues) => {
    const successfullyCreated = await userService.create({
      email: data.email,
      permissao: data?.permission?.map((permission) => permission.value).join('|'),
    });
    if (successfullyCreated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar usuário.',
      });
      navigateToListUsers();
    }
  }, []);

  return <UserForm type="create" onSubmit={handleSubmit} />;
};

export default CreateUser;
