import loadingIcon from './carregando.gif';

const Loading = () => {
  return (
    <>
      <center>
        <img alt="Carregando.." src={loadingIcon} />
      </center>
    </>
  );
};

export default Loading;
