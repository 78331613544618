import React, { useCallback, useState } from 'react';
import InputMask from 'react-input-mask';
import { InputContainer, Label, StyledInput, ToggleShowContent } from './styles';
import { maskToCurrency } from './utils';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

export interface InputProps extends React.PropsWithoutRef<JSX.IntrinsicElements['input']> {
  name: string;
  label?: string;
  mask?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  currency?: boolean;
  showContent?: boolean;
  toggleShowContent?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ ...props }, ref) => {
  const {
    name,
    type,
    label,
    icon,
    fullWidth,
    mask,
    value,
    onBlur,
    onChange,
    onFocus,
    disabled,
    readOnly,
    currency,
    showContent = true,
    toggleShowContent = false,
  } = props;

  const [showValue, setShowValue] = useState(showContent);
  const currencyProps = currency ? { beforeMaskedStateChange: maskToCurrency } : {};

  const handleToggleShowContent = useCallback(() => {
    setShowValue(!showValue);
  }, [showValue]);

  return (
    <>
      {label && <Label htmlFor={name}>{label}</Label>}

      <InputContainer className={fullWidth ? 'full-width' : ''}>
        {icon}

        {mask ? (
          <InputMask
            mask={mask}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            disabled={disabled}
            readOnly={readOnly}
            {...currencyProps}>
            <StyledInput id={name} ref={ref} {...props} />
          </InputMask>
        ) : (
          <StyledInput
            id={name}
            ref={ref}
            {...props}
            type={toggleShowContent ? (showValue ? 'text' : 'password') : type}
          />
        )}

        {toggleShowContent && (
          <ToggleShowContent onClick={handleToggleShowContent}>
            {showValue ? <EyeSlashIcon /> : <EyeIcon />}
          </ToggleShowContent>
        )}
      </InputContainer>
    </>
  );
});

Input.displayName = 'Input';
export default Input;
