import BpmnModeler from 'bpmn-js/lib/Modeler';
import BaseLayouter from 'diagram-js/lib/layout/BaseLayouter';
import BendpointMove from 'diagram-js/lib/features/bendpoints/BendpointMove';
import CustomPalette from './CustomPalette';
import CustomRenderer from './CustomRenderer';
import CustomRules from './CustomRules';
import CustomContextPadProvider from './CustomContextPadProvider';
import CustomCommandInterceptor from './CustomCommandInterceptor';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';

function createBendPointMove(injector, eventBus, canvas, dragging, rules, modeling) {
  const bendpointMove = new BendpointMove(injector, eventBus, canvas, dragging, rules, modeling);
  const start = bendpointMove.start.bind(bendpointMove);
  bendpointMove.start = (event, connection, bendpointIndex, insert) => {
    if (insert) {
      return;
    }
    start(event, connection, bendpointIndex, insert);
  };
  return bendpointMove;
}

export const bpmnJS = (containerEl) =>
  new BpmnModeler({
    container: containerEl,
    textRenderer: {
      defaultStyle: {
        fontFamily: '"IBM Plex Sans", Helvetica',
        fontWeight: 'regular',
      },
    },
    additionalModules: [
      {
        __init__: [
          'paletteProvider',
          'customRenderer',
          'customContextPadProvider',
          'customCommandInterceptor',
          'labelEditingProvider',
          'bendpoints',
          'bendpointMove',
          'customRules',
        ],
        paletteProvider: ['type', CustomPalette],
        customRenderer: ['type', CustomRenderer],
        customContextPadProvider: ['type', CustomContextPadProvider],
        customCommandInterceptor: ['type', CustomCommandInterceptor],
        labelEditingProvider: ['value', null],
        bendpointMove: [
          'factory',
          ['injector', 'eventBus', 'canvas', 'dragging', 'rules', 'modeling', createBendPointMove],
        ],
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        connectionSegmentMove: ['value', { start() {} }],
        layouter: ['type', BaseLayouter],
        customRules: ['type', CustomRules],
      },
    ],
    keyboard: { bindTo: document },
  });

export const bpmnProcessId = 'Process_1ngsadj';
