import RuleProvider from 'diagram-js/lib/features/rules/RuleProvider';

import inherits from 'inherits';

export default function CustomRules(eventBus) {
  RuleProvider.call(this, eventBus);

  this.addRule('elements.delete', function (context) {
    return context.elements.filter(function (e) {
      if (
        e?.businessObject?.incoming &&
        e?.businessObject?.incoming.length > 0 &&
        e?.businessObject?.incoming[0].sourceRef &&
        e?.businessObject?.incoming[0]?.sourceRef?.$instanceOf('bpmn:ExclusiveGateway')
      ) {
        return false;
      }
      if (e?.businessObject?.sourceRef && e?.businessObject?.sourceRef?.$instanceOf('bpmn:ExclusiveGateway')) {
        return false;
      }
      return true;
    });
  });
}

inherits(CustomRules, RuleProvider);

CustomRules.$inject = ['eventBus'];
