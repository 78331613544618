import { Controller, useForm } from 'react-hook-form';
import Heading from '../../components/Text/Heading';
import { StyledForm } from './styles';
import { useCallback } from 'react';
import Button from '../../components/Form/Button';
import { DocumentDataForm } from '../../types/Document.types';
import Paragraph from '../../components/Text/Paragraph';
import { ConditionData } from '../../types/Condition.types';
import RadioGroup from '../../components/Form/RadioGroup';
import FormGroup from '../../components/Form/FormGroup';

interface InitialDataFormProps {
  name: string;
  condition?: ConditionData;
  defaultValue?: string;
  onNext: (data: DocumentDataForm) => void;
  onBack: () => void;
  loading: boolean;
}

export const DocumentConditionForm = ({
  name,
  condition,
  defaultValue = '',
  onNext,
  onBack,
  loading,
}: InitialDataFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const renderRadioGroup = useCallback(
    ({ field }: any) => (
      <RadioGroup
        items={
          !condition?.condicaoOpcoes.length
            ? []
            : condition?.condicaoOpcoes.map((option) => ({
                value: option.idModeloCondicaoOpcao as string,
                label: option.descricao as string,
              }))
        }
        selectedValue={field.value}
        setSelectedValue={field.onChange}
      />
    ),
    []
  );

  return (
    <StyledForm onSubmit={handleSubmit(onNext)}>
      <div>
        <Heading>{condition?.titulo}</Heading>
        <Paragraph>{condition?.descricao}</Paragraph>
        <FormGroup error={errors[name]?.message as string}>
          <Controller
            rules={{ required: 'Uma opção deve ser selecionada' }}
            name={name}
            control={control}
            render={renderRadioGroup}
            defaultValue={defaultValue}
          />
        </FormGroup>
      </div>
      <div className="buttons">
        <Button type="button" kind="secondary" onClick={onBack}>
          Voltar
        </Button>
        <Button type="submit" disabled={loading}>
          {loading ? 'Aguarde...' : 'Próximo'}
        </Button>
      </div>
    </StyledForm>
  );
};

export default DocumentConditionForm;
