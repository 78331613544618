import { DisplayStyle, HeadingStyle, MediumStyle, SmallStyle } from './styles';

type HeadingProps = {
  className?: string;
  children: string | React.ReactNode;
  size?: 'display' | 'medium' | 'small';
};

const Heading = ({ size, ...props }: HeadingProps) => {
  switch (size) {
    case 'display':
      return <DisplayStyle {...props} />;
    case 'medium':
      return <MediumStyle {...props} />;
    case 'small':
      return <SmallStyle {...props} />;
    default:
      return <HeadingStyle {...props} />;
  }
};

export default Heading;
