import { OptionType } from '../../components/Form/Select';
import { ConditionFormValues, SingleConditionData } from '../../types/Condition.types';

export const getCreateData = (data: ConditionFormValues) => {
  return {
    titulo: data.name,
    descricao: data.description,
    opcoes: data?.options?.map((option) => ({
      optionId: option.optionId === '' ? null : option.optionId,
      optionValue: option.optionValue ?? null,
      variavelVinculadaAtiva: option.optionChkVariable ?? false,
      idVariavelVinculada: option.optionVariableId === '' ? null : option.optionVariableId,
      valorVariavelVinculada:
        typeof option.optionVariableValue === 'string'
          ? option.optionVariableValue
          : Array.isArray(option.optionVariableValue)
          ? option.optionVariableValue.join('¨')
          : option.optionVariableValue && typeof option.optionVariableValue === 'object'
          ? (option.optionVariableValue as OptionType).value
          : null,
    })),
  };
};

export const getFormattedInitialData = (conditionData: SingleConditionData | undefined) => {
  return {
    name: conditionData?.titulo,
    description: conditionData?.descricao,
    options: conditionData?.condicaoOpcoes.map((option) => ({
      optionId: option.idModeloCondicaoOpcao,
      optionValue: option.descricao,
      optionChkVariable: option.variavelVinculadaAtiva == null ? false : option.variavelVinculadaAtiva,
      optionVariableId: option.idVariavelVinculada == null ? null : option.idVariavelVinculada,
      optionVariableValue: option.valorVariavelVinculada == null ? null : option.valorVariavelVinculada,
    })),
    quantityOfModels: conditionData?.quantidadeModelos,
  };
};
