import React from 'react';
import { Label, StyledTextArea, TextAreaContainer } from './styles';

export interface TextAreaProps extends React.PropsWithoutRef<JSX.IntrinsicElements['textarea']> {
  name: string;
  label?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({ ...props }, ref) => {
  const { name, label, icon, fullWidth } = props;

  return (
    <>
      {label && <Label htmlFor={name}>{label}</Label>}

      <TextAreaContainer className={fullWidth ? 'full-width' : ''}>
        {icon}
        <StyledTextArea id={name} ref={ref} {...props} />
      </TextAreaContainer>
    </>
  );
});

TextArea.displayName = 'TextArea';
export default TextArea;
