import styled from 'styled-components';
import * as DropdownMenuComponent from '@radix-ui/react-dropdown-menu';
import { theme } from '../../styles/themes/default';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${theme.colors.grey[700]};
  padding: ${theme.spacings[2]};
  cursor: pointer;
  position: fixed;
  z-index: 200;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  img {
    border: 0.1rem solid ${theme.colors.teal[200]};
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    margin-right: ${theme.spacings[1]};
  }
  p {
    color: ${theme.colors.neutral.white};
    margin-right: ${theme.spacings[2]};
    padding: 0.4rem 0;
  }
  svg {
    width: 2rem;
    path {
      stroke: ${theme.colors.neutral.white};
    }
  }
`;

export const StyledHeaderActions = styled(DropdownMenuComponent.Content)`
  background: ${theme.colors.neutral.white};
  border: 0.1rem solid ${theme.colors.grey[200]};
  border-radius: 0.4rem;
  padding: ${theme.spacings[2.5]} ${theme.spacings[3]} ${theme.spacings[2]};
  margin-top: ${theme.spacings[2]};
  width: 20rem;
  z-index: 300;
`;
