import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const AlertDialog = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    margin-top: ${theme.spacings[4]};
  }

  h1 {
    font-weight: 400;
    padding: ${theme.spacings[1.5]} 0 ${theme.spacings[1]};
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: ${theme.spacings[4]};
  }
`;

export const LogDialog = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  width: 100%;

  .container {
    min-height: 20rem;
    max-height: 40rem;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0.4rem;
    border: 0.1rem solid #e5e7eb;
    width: 100%;
    margin-top: 4rem;
    color: #4b5563;
  }

  img {
    margin-top: ${theme.spacings[4]};
  }

  h1 {
    font-weight: 400;
    padding: ${theme.spacings[1.5]} 0 ${theme.spacings[1]};
    text-align: left;
  }

  p {
    text-align: left;
    margin-bottom: ${theme.spacings[4]};
    font-size: 14px;
  }

  div.log {
    border-bottom: 0.1rem solid #e5e7eb;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
  }

  h1 > svg {
    max-height: 2rem;
    margin-right: 1.2rem;
  }
`;

export const StyledSearch = styled.div`
  width: 100%;
  max-height: 100%;
  padding: ${theme.spacings[3]};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  &.page {
    padding: ${theme.spacings[3]};
    > div {
      width: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
    }
    h1 {
      margin-bottom: ${theme.spacings[3]};
    }
  }
  &.modal {
    padding: ${theme.spacings[3]};
  }
  .list {
    list-style: none;
    margin: ${theme.spacings[2]} 0;
    padding-right: ${theme.spacings[0.5]};
    overflow-y: scroll;
    width: 100%;
    max-height: 100%;
    &.modal {
      max-height: 20rem;
      .name {
        width: 100%;
      }
    }
    &.page {
      .name {
        width: 50%;
      }
      .created-at,
      .created-by {
        width: 25%;
      }
    }
    li + li {
      margin-top: ${theme.spacings[2]};
    }
    li {
      cursor: pointer;
      padding: ${theme.spacings[2]};
      border: 1px solid ${theme.colors.grey[300]};
      border-radius: 0.6rem;
      display: flex;
      .info {
        .name {
          font-size: ${theme.fonts['xx-small']};
          color: ${theme.colors.grey[400]};
          font-weight: bold;
          text-transform: uppercase;
        }
        .value {
          font-size: ${theme.fonts['xx-small']};
          color: ${theme.colors.grey[600]};
          margin-top: ${theme.spacings[0.5]};
        }
      }
    }
  }
  .suspense-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      margin-bottom: ${theme.spacings[3]};
    }
  }
`;
