import { useCallback, useEffect, useState } from 'react';
import { AssociationConditionFormValues, ConditionData, SingleConditionData } from '../../types/Condition.types';
import {
  ConditionAssociationData,
  ConditionAssociationDataUpdateResponse,
} from '../../types/ConditionAssociation.types';
import { conditionAssociationService, conditionService } from '../../api/services';
import AssociationConditionForm from './AssociationConditionForm';
import { showToast } from '../../components/Toast/showToast';
import { useParams } from 'react-router-dom';

interface AssociationConditionProps {
  id: string;
  closeContent: () => void;
}

const AssociationCondition = ({ id: conditionId, closeContent }: AssociationConditionProps) => {
  const { id: modelId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<AssociationConditionFormValues>({
    vinculatedCondition: undefined,
    currentConditionOption: undefined,
    vinculatedConditionOption: undefined,
  });
  const [principalCondition, setPrincipalCondition] = useState<SingleConditionData>();
  const [conditions, setConditions] = useState<ConditionData[]>([]);
  const [currentConditionAssociation, setCurrentConditionAssociation] = useState<ConditionAssociationData | null>(null);

  useEffect(() => {
    async function getAssociationData() {
      const conditionsResult = await conditionService.list();
      setConditions(conditionsResult as ConditionData[]);

      const conditionResult = await conditionService.getById(conditionId);
      setPrincipalCondition(conditionResult);

      const conditionAssociationModelResult = await conditionAssociationService.list(modelId as string);
      const currentConditionAssociationResult = conditionAssociationModelResult?.find(
        (conditionAssociationModel) =>
          conditionAssociationModel.modeloCondicaoOpcaoResponsePadrao?.idModeloCondicao === conditionId
      );

      if (currentConditionAssociationResult) {
        setCurrentConditionAssociation(currentConditionAssociationResult);
        const vinculatedCondition = conditionsResult?.find(
          (condition) =>
            condition.idModeloCondicao ===
            currentConditionAssociationResult.modeloCondicaoOpcaoResponseVinculada?.idModeloCondicao
        );
        setInitialData({
          vinculatedCondition: {
            label: vinculatedCondition?.titulo as string,
            value: vinculatedCondition?.idModeloCondicao as string,
          },
          currentConditionOption: {
            label: currentConditionAssociationResult?.modeloCondicaoOpcaoResponsePadrao?.descricao as string,
            value: currentConditionAssociationResult?.modeloCondicaoOpcaoResponsePadrao
              ?.idModeloCondicaoOpcao as string,
          },
          vinculatedConditionOption: {
            label: currentConditionAssociationResult?.modeloCondicaoOpcaoResponseVinculada?.descricao as string,
            value: currentConditionAssociationResult?.modeloCondicaoOpcaoResponseVinculada
              ?.idModeloCondicaoOpcao as string,
          },
        });
      } else {
        setCurrentConditionAssociation(null);
      }

      setIsLoading(false);
    }

    getAssociationData();
  }, []);

  const handleSubmit = useCallback(
    async (data: AssociationConditionFormValues) => {
      let sucessfullyAction: ConditionAssociationData | ConditionAssociationDataUpdateResponse | undefined = undefined;
      if (currentConditionAssociation === null) {
        sucessfullyAction = await conditionAssociationService.create({
          idModelo: modelId,
          idModeloCondicaoOpcaoPadrao: data.currentConditionOption?.value,
          idModeloCondicaoOpcaoVinculada: data.vinculatedConditionOption?.value,
        });
      } else {
        sucessfullyAction = await conditionAssociationService.update(
          currentConditionAssociation.idModeloCondicaoAssociacao as string,
          {
            idModelo: modelId,
            idModeloCondicaoOpcaoPadrao: data.currentConditionOption?.value,
            idModeloCondicaoOpcaoVinculada: data.vinculatedConditionOption?.value,
          }
        );
      }
      if (sucessfullyAction) {
        showToast({
          type: 'success',
          text: 'Sucesso ao associar condição.',
        });
        closeContent();
      }
    },
    [principalCondition, currentConditionAssociation]
  );

  return !isLoading ? (
    <>
      <AssociationConditionForm
        initialData={initialData}
        principalCondition={principalCondition as SingleConditionData}
        conditions={conditions}
        onSubmit={handleSubmit}
        onCancel={closeContent}
      />
    </>
  ) : null;
};

export default AssociationCondition;
