import appConfig from '../appConfig';
import Axios, { AxiosInstance } from 'axios';
import { handleServiceError } from './ApiServiceErrors';
import { RequestConfig } from '../types/ApiClient.types';
import { getToken, removeToken, setLocation } from './utils';

export interface IApiClient {
  post<TRequest, TResponse>(path: string, object: TRequest, config?: RequestConfig): Promise<TResponse | undefined>;
  get<TResponse>(path: string): Promise<TResponse | undefined>;
  put<TRequest, TResponse>(path: string, object: TRequest): Promise<TResponse | undefined>;
  delete<TResponse>(path: string): Promise<TResponse | undefined>;
}
export default class ApiClient implements IApiClient {
  private client: AxiosInstance;

  protected createAxiosClient(): AxiosInstance {
    const axiosInstance = Axios.create({
      baseURL: appConfig.authApiBase || '',
      responseType: 'json' as const,
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 60 * 1000,
    });

    axiosInstance.interceptors.request.use((config) => {
      const token = getToken();
      if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    axiosInstance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        if (err.response.status === 401) {
          removeToken();
          setLocation('/');
        }

        return Promise.reject(err);
      }
    );

    return axiosInstance;
  }

  constructor() {
    this.client = this.createAxiosClient();
  }

  async post<TRequest, TResponse>(path: string, payload: TRequest): Promise<TResponse | undefined> {
    try {
      const response = await this.client.post<TResponse>(path, payload);
      return response.data;
    } catch (error) {
      handleServiceError(error);
    }
    return undefined;
  }

  async get<TResponse>(path: string): Promise<TResponse | undefined> {
    try {
      const response = await this.client.get<TResponse>(path);
      return response.data;
    } catch (error) {
      handleServiceError(error);
    }
    return undefined;
  }

  async put<TRequest, TResponse>(path: string, payload: TRequest): Promise<TResponse | undefined> {
    try {
      const response = await this.client.put<TResponse>(path, payload);
      return response.data;
    } catch (error) {
      handleServiceError(error);
    }
    return undefined;
  }

  async delete<TResponse>(path: string): Promise<TResponse | undefined> {
    try {
      const response = await this.client.delete<TResponse>(path);
      return response.data;
    } catch (error) {
      handleServiceError(error);
    }
    return undefined;
  }
}
