import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import { theme } from '../../../styles/themes/default';

const badgeStyle = `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacings[0.5]} ${theme.spacings[1.5]};
  background: ${theme.colors.blue[100]};
  border-radius: 5rem;
  color: ${theme.colors.blue[600]};
  font-weight: bold;
  margin: ${theme.spacings[0.5]};
`;

const selectStyle = `
  width: 100%;
  .react-select__control {
    border: 0.1rem solid ${theme.colors.grey[200]};
    outline: none;
    box-shadow: none;
    &:hover,
    &:active {
      border: 0.1rem solid ${theme.colors.grey[200]};
    }
  }
  .react-select__input-container {
    padding: ${theme.spacings[1]} 0;
    font-size: ${theme.fonts['x-small']};
  }
  .react-select__value-container {
    padding: 0.6rem;
    font-size: ${theme.fonts['x-small']};
  }
  .react-select__indicators {
    svg {
      margin-right: ${theme.spacings[1.5]};
      width: 2rem;
      path {
        stroke: ${theme.colors.grey[600]};
      }
    }
  }
  .react-select__indicator-separator {
    width: 0;
  }
  .react-select__menu {
    background: ${theme.colors.neutral.white};
    box-shadow: 0 0.8rem 2.4rem rgba(6, 148, 162, 0.1);
    border-radius: 0.3rem;
    z-index: 400;
  }
  .react-select__menu-list {
    padding: ${theme.spacings[1]};
  }
  .react-select__option {
    border-radius: 0.3rem;
    font-size: ${theme.fonts['x-small']};
    &.react-select__option--is-selected {
      background: ${theme.colors.teal[50]};
      color: ${theme.colors.grey[600]};
    }
    :hover {
      background: ${theme.colors.grey[50]};
    }
    padding: ${theme.spacings[1]} ${theme.spacings[0.5]};
  }
  .react-select__multi-value {
    ${badgeStyle}
  }
  .react-select__multi-value__label {
    color: ${theme.colors.blue[600]};
  }
  .react-select__multi-value__remove {
    background: none;
    padding: 0;
    height: 1.6rem;
    width: 1.6rem;
    &:hover {
      cursor: pointer;
      background: ${theme.colors.red[100]};
      svg {
        path {
          stroke: ${theme.colors.red[500]};
        }
      }
    }
  }
  .react-select__control--is-disabled {
    background: ${theme.colors.grey[50]};
    border-color: ${theme.colors.grey[200]};
    color: ${theme.colors.grey[400]};
  }
`;

export const StyledSelect = styled(ReactSelect)`
  ${selectStyle}
`;

export const StyledCreatableSelect = styled(CreatableSelect)`
  ${selectStyle}
`;

export const MoreItems = styled.div`
  ${badgeStyle}
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxSelectInput = styled.input.attrs({
  type: 'checkbox',
})`
  cursor: pointer;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  &:checked ~ .check:after {
    display: block;
  }
  &:checked ~ .check {
    border-color: ${theme.colors.blue[600]};
    background: ${theme.colors.blue[600]};
  }
`;

export const Check = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.8rem;
  width: 1.8rem;
  background: ${theme.colors.blue[50]};
  border: 0.1rem solid ${theme.colors.grey[300]};
  transition: 100ms ease-in-out;
  border-radius: 0.4rem;
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 0.5rem;
    top: 0.2rem;
    width: 0.4rem;
    height: 0.8rem;
    border: 0.15rem solid ${theme.colors.blue[50]};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const Label = styled.label`
  margin-left: ${theme.spacings[1]};
  font-size: ${theme.fonts['x-small']};
  display: flex;
  align-items: center;
  position: relative;
  color: ${theme.colors.gray};
  height: 1.6rem;
  cursor: pointer;
  user-select: none;
  padding-left: ${theme.spacings[3]};
`;
