import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { setupStore } from './store';
import App from './App';
import appConfig from './appConfig';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: appConfig.sentryDSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: parseFloat(String(appConfig.sentryTracesSampleRate)),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={setupStore()}>
    <App />
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
