import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import VariableActions from './VariableActions';
import Heading from '../../components/Text/Heading';
import Button from '../../components/Form/Button';
import { ArrowsRightLeftIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Container } from './styles';
import Input from '../../components/Form/Input';
import { VariableData } from '../../types/Variable.types';
import { variableService } from '../../api/services';
import { getBadge } from './utils';
import { formatDate } from '../../components/DataTable/utils';
import PaginatedDataTable, { usePagination, useSorting } from '../../components/PaginatedDataTable';

const Variables = () => {
  const [variables, setVariables] = useState<VariableData[]>([]);
  const [deletedItem, setDeletedItem] = useState<string>('');

  const { limit, onPaginationChange, skip, pagination } = usePagination(10);
  const { sorting, onSortingChange, field, order } = useSorting('dataCriacao', 'DESC');
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [resetPagination, setPaginationTrigger] = useState(false); // Inverter valor para reiniciar a paginaçao
  const [search, setSearch] = useState<string>('');

  const handleSearch = useCallback((e: { target: { value: SetStateAction<string> } }) => {
    setSearch(e.target.value);
  }, []);

  const VariableRelationType = useCallback(
    (info: CellContext<VariableData, unknown>) => <>{getBadge(info.getValue() as string)}</>,
    []
  );

  const DateFormatter = useCallback(
    (info: CellContext<VariableData, unknown>) => formatDate(info.getValue() as string),
    []
  );

  const actions = useCallback(
    (info: CellContext<VariableData, unknown>) => (
      <VariableActions
        setDeletedItem={setDeletedItem}
        id={info.row.getValue('idVariavel')}
        name={info.row.getValue('nomeVariavel')}
        type={info.row.getValue('descricaoTipoVariavel')}
        relationType={info.row.getValue('tipoRelacao')}
      />
    ),
    []
  );

  const columns = useMemo<ColumnDef<VariableData>[]>(
    () => [
      {
        accessorKey: 'idVariavel',
        header: 'Id',
        enableGlobalFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: 'nomeVariavel',
        header: 'Nome da variável',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'descricaoTipoVariavel',
        header: 'Tipo',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'tipoRelacao',
        header: 'Característica',
        cell: VariableRelationType,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataCriacao',
        header: 'Data da criação',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'dataAlteracao',
        header: 'Última alteração',
        cell: DateFormatter,
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'usuarioCriacao',
        header: 'Criado por',
        enableGlobalFilter: true,
      },
      {
        accessorKey: 'action',
        header: '',
        cell: actions,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    []
  );

  useEffect(() => {
    fetchData();
  }, [pagination, sorting, deletedItem]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (search.length < 3 && search.length != 0) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      fetchData(true);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const fetchData = async (_resetPagination = false) => {
    setLoading(true);
    try {
      const result = await variableService.listPaged({
        pagination: { skip: _resetPagination ? 0 : skip, limit },
        sort: { field, order },
        query: search,
      });

      if (result) {
        setVariables(result.data);
        setCount(result.count);
        if (_resetPagination) {
          setPaginationTrigger(!resetPagination); //Retorna para pagina 1 após a pesquisa
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const pageCount = Math.ceil(count / limit);
  const navigate = useNavigate();
  const navigateToCreateVariable = useCallback(() => {
    navigate('/variables/create');
  }, []);

  return (
    <Container>
      <div className="header">
        <Heading>Variáveis</Heading>
        <Button onClick={navigateToCreateVariable}>
          <ArrowsRightLeftIcon />
          Cadastrar nova variável
        </Button>
      </div>

      <div className="search-filter-bar">
        <Input
          name="search"
          icon={<MagnifyingGlassIcon />}
          placeholder="Procurar por nome da variável"
          onChange={handleSearch}
        />
      </div>
      <PaginatedDataTable<VariableData>
        columnVisibility={{ idVariavel: false }}
        data={variables}
        columns={columns}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        pagination={pagination}
        paginationResetTrigger={resetPagination}
        loading={loading}
        pageCount={pageCount}
        sorting={sorting}
      />
    </Container>
  );
};

export default Variables;
