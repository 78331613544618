import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ConditionFormValues } from '../../types/Condition.types';
import FormGroup from '../../components/Form/FormGroup';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import Box from '../../components/Box';
import Heading from '../../components/Text/Heading';
import { StyledForm } from './styles';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useState } from 'react';
import Paragraph from '../../components/Text/Paragraph';
import { DialogConfirmAction } from '../shared/DialogConfirmAction';
import Checkbox from '../../components/Form/Checkbox';
import { OptionType, Select } from '../../components/Form/Select';
import { SingleVariableData, VariableData } from '../../types/Variable.types';
import { variableService } from '../../api/services';
import TextArea from '../../components/Form/TextArea';

interface ConditionFormProps {
  type: 'create' | 'update' | 'duplicate';
  boxType?: 'page' | 'suspense-page';
  initialData?: ConditionFormValues;
  onSubmit: (data: ConditionFormValues) => void;
  onCancel: () => void;
}

const validationSchema = yup.object({
  name: yup.string().required('O campo nome é obrigatório'),
  options: yup
    .array()
    .of(
      yup.object().shape({
        optionValue: yup.string().required('O valor da opção é obrigatório'),
        optionChkVariable: yup.bool().notRequired(),
        optionVariableId: yup.string().nullable().notRequired(),
      })
    )
    .min(1, 'É necessário pelo menos uma opção.'),
});

const ConditionForm = ({ type, boxType = 'page', initialData, onSubmit, onCancel }: ConditionFormProps) => {
  const [listVariables, setListVariables] = useState<SingleVariableData[]>([]); //Aqui estão as listas das variaveis selecionadas que são usados nas opções.
  const [listChk, setListChk] = useState<boolean[]>([]); //Aqui estão as listas das variaveis selecionadas que são usados nas opções.
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectVariablesValue, setSelectVariablesValue] = useState<OptionType[]>([]);
  const [variables, setVariables] = useState<VariableData[]>([]); //Aqui estão as listas de variaveis para que são apresentados para serem selecionados.
  const {
    handleSubmit,
    control,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<ConditionFormValues>({
    defaultValues: initialData,
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    name: 'options',
    control,
  });

  const title = {
    create: 'Criar nova condição',
    update: 'Editar condição',
    duplicate: 'Duplicar condição',
  };

  const fetchVariable = async (id: string) => {
    try {
      const result = await variableService.getById(id);
      if (result) {
        return result;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchData = async () => {
    try {
      const result = await variableService.listPaged({
        pagination: { skip: 0, limit: 50 },
        sort: { field: 'dataCriacao', order: 'DESC' },
        query: search,
      });

      if (result) {
        if (result.data.length == 0) {
          setLoading(true);
        }

        if (listVariables.length > 0) {
          setVariables(() => {
            const updatedVariables = [...result.data];
            return updatedVariables;
          });
        } else {
          setVariables(result.data);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateArrayAtIndex = <T,>(array: T[], index: number, newValue: T): T[] => {
    const updatedArray = [...array];
    updatedArray[index] = newValue;
    return updatedArray;
  };

  const appendValue = useCallback(
    () =>
      append({
        optionId: '',
        optionValue: '',
        optionChkVariable: false,
        optionVariableId: '',
        optionVariableValue: '',
      }),
    []
  );

  const deleteAndReorganizeArrayAtIndex = <T,>(array: T[], index: number): T[] => {
    return [...array.slice(0, index), ...array.slice(index + 1)];
  };

  const removeValue = useCallback(
    (index: number) => () => {
      remove(index);
      setListVariables((prev) => deleteAndReorganizeArrayAtIndex(prev, index));
      setListChk((prev) => deleteAndReorganizeArrayAtIndex(prev, index));
      setSelectVariablesValue((prev) => deleteAndReorganizeArrayAtIndex(prev, index));
      setVariables((prev) => deleteAndReorganizeArrayAtIndex(prev, index));
    },
    [setListVariables, setListChk, setSelectVariablesValue, setVariables]
  );

  const handleSearch = useCallback((newValue: string) => {
    setSearch(newValue);
  }, []);

  useEffect(() => {
    if (initialData?.options) {
      initialData.options.forEach(async (option, index) => {
        if (option.optionVariableId != null && option.optionVariableId !== '') {
          const fetchedVariable: SingleVariableData | undefined = await fetchVariable(option.optionVariableId);

          if (fetchedVariable) {
            setListVariables((prev) => updateArrayAtIndex(prev, index, fetchedVariable));
            setSelectVariablesValue((prev) =>
              updateArrayAtIndex(prev || [], index, {
                label: fetchedVariable.nomeVariavel || '',
                value: fetchedVariable.idVariavel || '',
              })
            );
            setListChk((prev) => updateArrayAtIndex(prev, index, option.optionChkVariable || false));
            switch (fetchedVariable.descricaoTipoVariavel) {
              case 'Lista suspensa':
                setValue(`options.${index}.optionVariableValue`, {
                  label: (option.optionVariableValue as string) || '',
                  value: (option.optionVariableValue as string) || '',
                });
                break;
              case 'Multipla escolha':
                setValue(`options.${index}.optionVariableValue`, (option.optionVariableValue as string).split('¨'));
                break;
              default:
                setValue(`options.${index}.optionVariableValue`, option.optionVariableValue as string);
                break;
            }
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (search.length < 3 && search.length != 0) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search, listVariables]);

  const handleVariable = useCallback(
    async (index: number, selectOption: { value: string | undefined }) => {
      const selectedVariable = variables.find((variable) => variable.idVariavel === selectOption.value);
      if (selectedVariable?.idVariavel) {
        const fetchedVariable: SingleVariableData | undefined = await fetchVariable(selectedVariable.idVariavel);
        if (fetchedVariable?.idVariavel) {
          setListVariables((prev) => updateArrayAtIndex(prev, index, fetchedVariable));
          setListChk((prev) => updateArrayAtIndex(prev, index, true));
          setValue(`options.${index}.optionVariableId`, selectedVariable?.idVariavel);
          setValue(`options.${index}.optionVariableValue`, '');
          setValue(`options.${index}.optionChkVariable`, true);
        }
      }
    },
    [variables, setValue, setListVariables, setListChk]
  );

  const renderSelect = useCallback(
    ({ field, index }: any) => {
      const variable = listVariables[index];
      if (variable == null || variable.valorAuxiliar == null) {
        return <p>Carregando opções...</p>;
      }

      const options = variable.valorAuxiliar.split('¨').map((value) => {
        const labelValue = value.split('|')[0];
        return { label: labelValue, value }; // Return the object for options
      });

      return <Select placeholder="Selecione uma opção" disabled={!listChk[index]} {...field} options={options} />;
    },
    [listVariables, listChk, fields]
  );

  const getInput = useCallback(
    (variableData: SingleVariableData, index: number) => {
      const required = variableData?.obrigatorio ? 'Campo obrigatório' : false;
      const defaultValue = watch(`options.${index}.optionVariableValue`);
      switch (variableData.descricaoTipoVariavel) {
        case 'Upload de imagens':
          return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <h3>As variáveis de imagens não são suportados para essa configuração.</h3>
            </div>
          );
        case 'Lista suspensa':
          return (
            <FormGroup key={variableData?.idVariavel + '/' + index}>
              {listVariables.length > 0 && (
                <Controller
                  key={variableData?.idVariavel + '/index/' + index}
                  name={`options.${index}.optionVariableValue`}
                  control={control}
                  // disabled={!listChk[index]}
                  // eslint-disable-next-line react/jsx-no-bind
                  render={({ field }) => renderSelect({ field, index })} // Passando o índice
                  defaultValue={defaultValue ? defaultValue : undefined}
                />
              )}
            </FormGroup>
          );
        case 'Multipla escolha':
          return (
            <section style={{ overflowY: 'auto', maxHeight: '150px' }} key={variableData?.idVariavel + '/' + index}>
              <FormGroup error={errors?.options && errors.options[index]?.optionValue?.message}>
                <div>
                  {variableData?.valorAuxiliar?.split('¨').map((value) => (
                    <Checkbox
                      disabled={!listChk[index]}
                      style={{ marginRight: '5px' }}
                      key={variableData?.idVariavel + '/index/' + index + '/value/' + value}
                      id={variableData?.idVariavel + '/index/' + index + '/value/' + value}
                      label={value}
                      {...register(`options.${index}.optionVariableValue`, {
                        required: variableData?.obrigatorio ? 'Campo obrigatório' : false,
                        value: defaultValue,
                      })}
                      value={value}
                      data-optional={!required}
                    />
                  ))}
                </div>
              </FormGroup>
            </section>
          );
        case 'Data':
          return (
            <FormGroup
              key={variableData?.idVariavel + '/' + index}
              error={errors?.options && errors.options[index]?.optionValue?.message}>
              <Input
                disabled={!listChk[index]}
                mask="99/99/9999"
                {...register(`options.${index}.optionVariableValue`, {
                  required: variableData?.obrigatorio ? 'Campo obrigatório' : false,
                  value: defaultValue,
                })}
                data-optional={!required}
              />
            </FormGroup>
          );
        case 'Hora':
          return (
            <FormGroup
              key={variableData?.idVariavel + '/' + index}
              error={errors?.options && errors.options[index]?.optionValue?.message}>
              <Input
                disabled={!listChk[index]}
                mask="99:99"
                {...register(`options.${index}.optionVariableValue`, {
                  required: variableData?.obrigatorio ? 'Campo obrigatório' : false,
                  value: defaultValue,
                })}
                data-optional={!required}
              />
            </FormGroup>
          );
        case 'Data e Hora':
          return (
            <FormGroup
              key={variableData?.idVariavel + '/' + index}
              error={errors?.options && errors.options[index]?.optionValue?.message}>
              <Input
                disabled={!listChk[index]}
                mask="99/99/9999 99:99"
                {...register(`options.${index}.optionVariableValue`, {
                  required: variableData?.obrigatorio ? 'Campo obrigatório' : false,
                  value: defaultValue,
                })}
                data-optional={!required}
              />
            </FormGroup>
          );
        case 'Número real':
          return (
            <FormGroup
              key={variableData?.idVariavel + '/' + index}
              error={errors?.options && errors.options[index]?.optionValue?.message}>
              <Input
                disabled={!listChk[index]}
                type="number"
                {...register(`options.${index}.optionVariableValue`, {
                  required: variableData?.obrigatorio ? 'Campo obrigatório' : false,
                  value: defaultValue,
                })}
                step="0.01"
                data-optional={!required}
              />
            </FormGroup>
          );
        case 'Número inteiro':
          return (
            <FormGroup
              key={variableData?.idVariavel + '/' + index}
              error={errors?.options && errors.options[index]?.optionValue?.message}>
              <Input
                disabled={!listChk[index]}
                type="number"
                {...register(`options.${index}.optionVariableValue`, {
                  required: variableData?.obrigatorio ? 'Campo obrigatório' : false,
                  value: defaultValue,
                })}
                step="1"
                data-optional={!required}
              />
            </FormGroup>
          );
        case 'Moeda':
          return (
            <FormGroup
              key={variableData?.idVariavel + '/' + index}
              error={errors?.options && errors.options[index]?.optionValue?.message}>
              <Input
                disabled={!listChk[index]}
                currency
                mask="R$ 999999999999999"
                {...register(`options.${index}.optionVariableValue`, {
                  required: variableData?.obrigatorio ? 'Campo obrigatório' : false,
                  value: defaultValue,
                })}
                data-optional={!required}
              />
            </FormGroup>
          );
        case 'Texto longo':
          return (
            <FormGroup
              key={variableData?.idVariavel + '/' + index}
              error={errors?.options && errors.options[index]?.optionValue?.message}>
              <TextArea
                disabled={!listChk[index]}
                {...register(`options.${index}.optionVariableValue`, {
                  required: variableData?.obrigatorio ? 'Campo obrigatório' : false,
                  value: defaultValue,
                })}
                data-optional={!required}
              />
            </FormGroup>
          );
        default:
          return (
            <FormGroup
              key={variableData?.idVariavel + '/' + index}
              error={errors?.options && errors.options[index]?.optionValue?.message}>
              <Input disabled={!listChk[index]} {...register(`options.${index}.optionVariableValue`, {})} />
            </FormGroup>
          );
      }
    },
    [listVariables, errors, listChk]
  );

  const CheckValueVariableModifyBoolean = useCallback(
    (index: number) => {
      const value = getValues(`options.${index}.optionVariableValue`);
      if (
        value != null &&
        typeof value !== 'boolean' && // Ignora se o tipo é booleano
        ((typeof value === 'string' && value !== '') || // Verifica se é uma string não vazia
          (Array.isArray(value) && value.length > 0) || // Verifica se é um array de strings não vazio
          (typeof value === 'object' &&
            value !== null && // Verifica se é um objeto
            'label' in value &&
            'value' in value && // Verifica se tem as propriedades label e value
            value.label !== '' &&
            value.value !== '')) // Verifica se label e value não são vazios
      ) {
        setValue(`options.${index}.optionChkVariable`, true);
        setListChk((prev) => updateArrayAtIndex(prev, index, true));
      } else {
        setValue(`options.${index}.optionChkVariable`, false);
        setListChk((prev) => updateArrayAtIndex(prev, index, false));
      }
    },
    [getValues, setValue, setListChk]
  );

  const EnableVariable = useCallback(
    (index: number, isChecked: any) => {
      const Variablevalue = listVariables[index];
      if (Variablevalue != null) {
        if (isChecked) {
          setListChk((prev) => updateArrayAtIndex(prev, index, true));
        } else {
          setListChk((prev) => updateArrayAtIndex(prev, index, false));
        }
      }
    },
    [listChk, setListChk, getValues]
  );

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box
          type={boxType}
          footer={
            <>
              <Button className="cancel-button" type="button" kind="alert" onClick={onCancel}>
                Voltar
              </Button>
              {type === 'create' || (initialData?.quantityOfModels as number) <= 0 ? (
                <Button className="submit-button" type="submit">
                  Salvar
                </Button>
              ) : (
                <DialogConfirmAction
                  type="update"
                  trigger={<Button className="submit-button">Salvar</Button>}
                  title="Confirmar alteração?"
                  text={`Tem certeza que deseja alterar a condição ${initialData?.name}? Esta mesma condição está sendo utilizada em ${initialData?.quantityOfModels} modelos e a sua alteração impactará em todos os MODELOS.`}
                  onConfirm={handleSubmit(onSubmit)}
                />
              )}
            </>
          }>
          <div className="content">
            <Heading>{title[type]}</Heading>
            <Box border={false} padding={false}>
              <FormGroup error={errors.name?.message}>
                <Input {...register('name')} label="Nome da condição" />
              </FormGroup>
              <FormGroup error={errors.description?.message}>
                <Input {...register('description')} label="Descrição" />
              </FormGroup>
              <FormGroup error={errors?.options?.message}>
                <Box className="value-container">
                  {fields.map((field, index) => {
                    return (
                      <div
                        key={'o id do campo:' + field.id + 'o index:' + index}
                        style={{
                          width: '100%',
                          display: 'flex',
                          marginBottom: '10px',
                        }}>
                        <FormGroup error={errors?.options && errors.options[index]?.optionValue?.message} inline={true}>
                          <Input type="hidden" {...register(`options.${index}.optionId`)} />
                          <div>
                            <Paragraph className="label">Opções</Paragraph>
                            <Input
                              {...register(`options.${index}.optionValue`)}
                              style={{ width: '100%', minWidth: '250px', height: '50px', alignSelf: 'start' }}
                            />
                          </div>

                          <div>
                            <Paragraph className="label none-visibility">Opção automática</Paragraph>
                            <Checkbox
                              id={`options.${index}.optionChkVariable`}
                              value={''}
                              label={'Habilitar variável a ser impactada ou que impactará a opção.'}
                              {...register(`options.${index}.optionChkVariable`, {
                                onChange: (e) => {
                                  EnableVariable(index, e.target.checked);
                                  return e.target.checked;
                                },
                              })}
                              style={{
                                marginLeft: '15px',
                                marginRight: '15px',
                                width: '100%',
                                maxWidth: '250px',
                                textAlign: 'center',
                              }}
                            />
                          </div>

                          <div style={{ minWidth: '250px', width: '35em' }}>
                            {variables.length === 0 && loading == false ? (
                              <h3>Buscando variáveis...</h3>
                            ) : (
                              <div>
                                <Paragraph className="label">
                                  <b style={{ textAlign: 'center' }}>
                                    Variável a ser impactadada ou que impactará a opção
                                  </b>
                                </Paragraph>
                                <Select
                                  placeholder="Selecione uma variável"
                                  options={variables.map((variable) => ({
                                    label: variable.nomeVariavel,
                                    value: variable.idVariavel,
                                  }))}
                                  onInputChange={handleSearch}
                                  defaultValue={
                                    selectVariablesValue[index]?.value === '' ? '' : selectVariablesValue[index]
                                  }
                                  // eslint-disable-next-line react/jsx-no-bind
                                  onChange={(selectedOption) => handleVariable(index, selectedOption)}
                                />
                              </div>
                            )}
                          </div>
                          {listVariables[index] && (
                            <div
                              // eslint-disable-next-line react/jsx-no-bind
                              onBlur={() => CheckValueVariableModifyBoolean(index)}
                              style={{
                                minWidth: '250px',
                                marginLeft: '20px',
                                width: '380px',
                              }}>
                              <Paragraph className="label">
                                <b style={{ textAlign: 'center' }}>
                                  Valor da variável a ser impactada ou que impactará a opção
                                </b>
                              </Paragraph>
                              {getInput(listVariables[index], index)}
                            </div>
                          )}
                        </FormGroup>

                        <div>
                          <Paragraph className="label none-visibility">Remover</Paragraph>
                          <Button
                            type="button"
                            kind="outline"
                            onClick={removeValue(index)}
                            style={{
                              alignSelf: 'center',
                            }}>
                            <TrashIcon />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                  <div>
                    <Button type="button" kind="outline" onClick={appendValue}>
                      <PlusIcon className="add-option" />
                      Adicionar opção
                    </Button>
                  </div>
                </Box>
              </FormGroup>
            </Box>
          </div>
        </Box>
      </StyledForm>
    </>
  );
};

export default ConditionForm;
