import Button from '../../components/Form/Button';
import Heading from '../../components/Text/Heading';
import { StyledFinishDocument } from './styles';
import DocumentFinished from '../../assets/icons/document-finished.svg';
import Paragraph from '../../components/Text/Paragraph';

interface FinishDocumentProps {
  name: string | undefined;
  onBack: () => void;
  onSave: () => void;
}

const FinishDocument = ({ name, onSave, onBack }: FinishDocumentProps) => {
  return (
    <StyledFinishDocument>
      <div className="title">
        <img src={DocumentFinished} />
        <Heading size="small">DOCUMENTO FINALIZADO COM SUCESSO!</Heading>
        <Paragraph>O documento{name ? ` ${name}` : ''} foi finalizado. O que você deseja fazer agora?</Paragraph>
      </div>
      <div className="buttons">
        <Button type="submit" onClick={onSave}>
          Mostrar preview
        </Button>
        <Button type="button" kind="outline" onClick={onBack}>
          Voltar
        </Button>
      </div>
    </StyledFinishDocument>
  );
};

export default FinishDocument;
