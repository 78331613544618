import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionForm from './SectionForm';
import { SectionFormValues } from '../../types/Section.types';
import { showToast } from '../../components/Toast/showToast';
import { sectionService } from '../../api/services';

const CreateSection = () => {
  const navigate = useNavigate();
  const navigateToListSections = useCallback(() => {
    navigate('/sections');
  }, []);

  const handleSubmit = useCallback(async (data: SectionFormValues) => {
    const successfullyCreated = await sectionService.create({
      tituloSecao: data.name,
      descricaoSecao: data.description,
    });
    if (successfullyCreated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar seção.',
      });
      navigateToListSections();
    }
  }, []);

  return (
    <>
      <SectionForm type="create" onSubmit={handleSubmit} />
    </>
  );
};

export default CreateSection;
