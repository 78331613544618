import { useEffect } from 'react';
import { TemplateDocument } from '../../types/Template.types';
import { StyledDocumentPreview } from './styles';
import parse from 'html-react-parser';

interface DocumentPreviewProps {
  text: string;
  template: TemplateDocument;
  scrollToBottom: () => void;
}

const DocumentPreview = ({ text, template, scrollToBottom }: DocumentPreviewProps) => {
  useEffect(() => {
    scrollToBottom();
  }, [text]);

  return <StyledDocumentPreview template={template}>{parse(text)}</StyledDocumentPreview>;
};

export default DocumentPreview;
