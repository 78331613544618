import styled, { css } from 'styled-components';
import { FormGroupProps } from '.';

export const StyledFormGroup = styled.div<FormGroupProps>`
  ${({ theme, error }) => css`
    & + & {
      margin-top: ${theme.spacings[2]};
    }

    .error {
      display: flex;
      align-items: center;
      margin-top: ${theme.spacings[0.5]};
      font-size: ${theme.fonts['x-small']};
      color: ${theme.colors.red[500]};

      svg {
        height: 1.4rem;
        stroke: ${theme.colors.red[500]};
        margin-right: ${theme.spacings[0.5]};
      }
    }

    ${error &&
    css`
      input {
        border-color: ${theme.colors.red[500]} !important;
      }
    `}
  `}
`;
