import { useCallback, useEffect, useRef, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { modelService } from '../../api/services';
import Input from '../../components/Form/Input';
import { ModelData } from '../../types/Model.types';
import { formatDate } from '../../components/DataTable/utils';
import Heading from '../../components/Text/Heading';
import Box from '../../components/Box';
import { StyledSearch } from '../shared/styles';
import Button from '../../components/Form/Button';
import Loading from '../../components/Loading';
import Switch from '../../components/Form/Switch';
import Paragraph from '../../components/Text/Paragraph';

interface SearchModelContentProps {
  type: string;
  setBpmnModel: (modelId: string, name: string) => () => void;
}

interface SearchModelProps extends SearchModelContentProps {
  title?: string;
}

const SearchModelContent = ({ type, setBpmnModel }: SearchModelContentProps) => {
  const searchText = useRef<HTMLInputElement>(null);
  const [models, setModels] = useState<ModelData[]>([]);
  const exactMatch = useRef(false);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 50;

  const fetchData = async (search: string) => {
    try {
      setLoading(true);
      const result = await modelService.listPaged({
        pagination: { skip: 0, limit: itemsPerPage },
        sort: { field: 'dataCriacao', order: 'DESC' },
        query: search,
        exactMatch: exactMatch.current,
        modelType: 'Simples',
      });

      if (result) {
        setModels(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitch = useCallback(() => {
    exactMatch.current = !exactMatch.current;
  }, []);

  const searchItems = useCallback(() => {
    const search = searchText.current?.value;
    if (search == null || search.length < 3) {
      return;
    }
    fetchData(search);
  }, []);

  return (
    <>
      <div style={{ display: 'flex', width: '100%', marginBottom: '1.6rem' }}>
        <Input
          name="search"
          icon={<MagnifyingGlassIcon />}
          ref={searchText}
          placeholder="Procurar por modelo"
          fullWidth
        />
        <Button kind="secondary" onClick={searchItems} style={{ marginLeft: '1.6rem' }}>
          Pesquisar
        </Button>
      </div>
      <Switch onCheckedChange={handleSwitch} label="Correspondência exata?" name={'test'}></Switch>
      {!loading ? (
        models?.length ? (
          <ul className={`list ${type}`}>
            {models
              //.filter((model) => model.nomeModelo?.toLowerCase().includes(search))
              .map((model) => (
                <li key={model.idModelo} onClick={setBpmnModel(model.idModelo as string, model.nomeModelo as string)}>
                  <div className="info name">
                    <div className="name">NOME DO MODELO</div>
                    <div className="value">{model.nomeModelo}</div>
                  </div>
                  {type !== 'modal' && (
                    <>
                      <div className="info created-by">
                        <div className="name">CRIADO POR</div>
                        <div className="value">{model.usuarioCriacao}</div>
                      </div>
                      <div className="info created-at">
                        <div className="name">DATA DA CRIAÇÃO</div>
                        <div className="value">{formatDate(model.dataCriacao as string)}</div>
                      </div>
                    </>
                  )}
                </li>
              ))}
          </ul>
        ) : (
          <div style={{ width: '100%', textAlign: 'center', margin: '1.6rem auto' }}>
            <Paragraph size="medium" className="type">
              A busca não retornou resultados.
            </Paragraph>
          </div>
        )
      ) : (
        <div style={{ margin: '1.6rem auto' }}>
          <Loading />
        </div>
      )}
    </>
  );
};

const SearchModel = ({ title, type, setBpmnModel }: SearchModelProps) => {
  return type === 'modal' ? (
    <StyledSearch className={type}>
      <SearchModelContent type={type} setBpmnModel={setBpmnModel} />
    </StyledSearch>
  ) : (
    <StyledSearch className={type}>
      <Box>
        <Heading>{title}</Heading>
        <SearchModelContent type={type} setBpmnModel={setBpmnModel} />
      </Box>
    </StyledSearch>
  );
};

export default SearchModel;
