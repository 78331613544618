import * as ProgressComponent from '@radix-ui/react-progress';
import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Root = styled(ProgressComponent.Root)`
  position: relative;
  overflow: hidden;
  background: ${theme.colors.grey[300]};
  border-radius: 99999px;
  width: 100%;
  height: 1.2rem;
  transform: translateZ(0);
`;

export const Indicator = styled(ProgressComponent.Indicator)`
  background-color: ${theme.colors.blue[500]};
  border-radius: 99999px;
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
`;
