import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DynamicVariableFormValuesX } from '../../types/DynamicVariable.types';
import { showToast } from '../../components/Toast/showToast';
import { dynamicVariableService } from '../../api/services';
import DynamicVariableForm from './DynamicVariableForm';

const CreateDynamicVariable = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const navigateToListVariables = useCallback(() => {
    navigate('/variables');
  }, []);

  const handleSubmit = useCallback(async (data: DynamicVariableFormValuesX) => {
    const ListaVariaveisDepedentes =
      data.listDepedentVariable?.map((dependentVariable) => ({
        IdVariavelDependente: dependentVariable.dependentVariableId?.value || '',
        Values:
          dependentVariable.values?.map((value) => ({
            ValorVariavelPrincipal: value.principalVariableValue.value || undefined,
            ValorVariavelDependente: value.dependentVariableValue.value || undefined,
          })) || [],
      })) || [];

    const successfullyUpdated = await dynamicVariableService.create({
      IdVariavelPrincipal: data.principalVariableId,
      ListaVariaveisDepedentes,
    });
    if (successfullyUpdated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao atualizar variável dinâmica.',
      });
      navigateToListVariables();
    }
  }, []);

  return (
    <>
      <DynamicVariableForm
        initialData={{
          principalVariableId: id as string,
        }}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateDynamicVariable;
