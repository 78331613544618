import { AxiosError } from 'axios';
import { showToast } from '../components/Toast/showToast';

interface AxiosCustomError {
  response: {
    data: {
      error: string;
    };
  };
}

export function handleServiceError(error: unknown): void {
  const err = error as AxiosError;
  if (err.response) {
    const customError = err as unknown as AxiosCustomError;
    if (customError.response.data.error) {
      showToast({
        type: 'danger',
        text: customError.response.data.error,
      });
      return;
    }
  }
  showToast({
    type: 'danger',
    text: 'Erro ao realizar operação',
  });
}
