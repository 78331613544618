import * as RadioGroup from '@radix-ui/react-radio-group';
import styled from 'styled-components';
import { LabelStyles } from '../styles';
import { theme } from '../../../styles/themes/default';

export const Root = styled(RadioGroup.Root)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const Item = styled(RadioGroup.Item)`
  background-color: ${theme.colors.neutral.white};
  width: 100%;
  border-radius: 0.4rem;
  border: 0.1rem solid ${theme.colors.grey[200]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: ${theme.spacings[2.5]} ${theme.spacings[4]};
  &:hover {
    border: 0.1rem solid ${theme.colors.blue[600]};
  }
  &:focus {
    background: ${theme.colors.blue[100]};
  }
  &[data-state='checked'] {
    border: 0.1rem solid ${theme.colors.blue[600]};
    background: ${theme.colors.blue[100]};
    label {
      color: ${theme.colors.blue[600]};
      font-weight: bold;
    }
  }
`;

export const ContentIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  border: 0.1rem solid ${theme.colors.blue[600]};
  border-radius: 999rem;
  width: 1.8rem;
  height: 1.8rem;
`;

export const Indicator = styled(RadioGroup.Indicator)`
  left: 0.5rem;
  top: 0.2rem;
  width: 0.5rem;
  height: 0.8rem;
  border: 0.1rem solid ${theme.colors.blue[600]};
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
`;

export const Label = styled.label`
  ${LabelStyles}
  font-weight: normal;
`;
