import {
  DocumentData,
  DocumentDataCreateRequest,
  DocumentDataDeleteResponse,
  DocumentProcessesDataResponse,
  PagedDocumentData,
  PagedDocumentDataRequest,
  SingleDocumentData,
} from '../../types/Document.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface IDocumentApiClient {
  create(data: DocumentDataCreateRequest): Promise<DocumentData | undefined>;
  list(): Promise<DocumentData[] | undefined>;
  listPaged(data: PagedDocumentDataRequest): Promise<PagedDocumentData | undefined>;
  getById(id: string): Promise<SingleDocumentData | undefined>;
  delete(id: string): Promise<DocumentDataDeleteResponse | undefined>;
  update(id: string, data: DocumentDataCreateRequest): Promise<DocumentData | undefined>;
  getProcesses(clientId: string, folderId: string): Promise<DocumentProcessesDataResponse | undefined>;
  download(id: string, type: string): Promise<any>;
}

export class DocumentApiClient implements IDocumentApiClient {
  documentApiClient: IApiClient;

  constructor(documentApiClient: IApiClient) {
    this.documentApiClient = documentApiClient;
  }

  async create(data: DocumentDataCreateRequest): Promise<DocumentData | undefined> {
    const response = await this.documentApiClient.post<DocumentDataCreateRequest, DocumentData>(`/documentos`, data);
    return response;
  }

  async list(): Promise<DocumentData[] | undefined> {
    const response = await this.documentApiClient.get<DocumentData[]>(`/documentos`);
    return response;
  }

  async listPaged(data: PagedDocumentDataRequest): Promise<PagedDocumentData | undefined> {
    const response = await this.documentApiClient.post<PagedDocumentDataRequest, PagedDocumentData>(
      `/documentos_paged`,
      data
    );
    return response;
  }

  async getById(id: string): Promise<SingleDocumentData | undefined> {
    const response = await this.documentApiClient.get<SingleDocumentData>(`/documentos/${id}`);
    return response;
  }

  async update(id: string, data: DocumentDataCreateRequest): Promise<DocumentData | undefined> {
    const response = await this.documentApiClient.put<DocumentDataCreateRequest, DocumentData>(
      `/documentos/${id}`,
      data
    );
    return response;
  }

  async delete(id: string): Promise<DocumentDataDeleteResponse | undefined> {
    const response = await this.documentApiClient.delete<DocumentDataDeleteResponse>(`/documentos/${id}`);
    return response;
  }

  async getProcesses(clientId: string, folderId: string): Promise<DocumentProcessesDataResponse | undefined> {
    const response = await this.documentApiClient.get<DocumentProcessesDataResponse>(
      `/documentos/clientes/${clientId}/processos?codigoPasta=${folderId}`
    );
    return response;
  }

  async download(id: string, type: string): Promise<any> {
    const response = await this.documentApiClient.get<any>(`/documentos/${id}/download?tipoArquivo=${type}`);
    return response;
  }
}

export default class DocumentService {
  documentApiClient: IDocumentApiClient;

  constructor() {
    this.documentApiClient = new DocumentApiClient(new ApiClient());
  }

  async create(data: DocumentDataCreateRequest): Promise<DocumentData | undefined> {
    return this.documentApiClient.create(data);
  }

  async list(): Promise<DocumentData[] | undefined> {
    return this.documentApiClient.list();
  }

  async listPaged(data: PagedDocumentDataRequest): Promise<PagedDocumentData | undefined> {
    return this.documentApiClient.listPaged(data);
  }

  async getById(id: string): Promise<SingleDocumentData | undefined> {
    return this.documentApiClient.getById(id);
  }

  async update(id: string, data: DocumentDataCreateRequest): Promise<DocumentData | undefined> {
    return this.documentApiClient.update(id, data);
  }

  async delete(id: string): Promise<DocumentDataDeleteResponse | undefined> {
    return this.documentApiClient.delete(id);
  }

  async getProcesses(clientId: string, folderId: string): Promise<DocumentProcessesDataResponse | undefined> {
    return this.documentApiClient.getProcesses(clientId, folderId);
  }

  async download(id: string, type: string): Promise<any> {
    return this.documentApiClient.download(id, type);
  }
}
