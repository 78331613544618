import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DynamicVariableForm from './DynamicVariableForm';
import {
  DependentVariable,
  DynamicVariableDataCreateV2Request,
  DynamicVariableFormValuesX,
} from '../../types/DynamicVariable.types';
import { showToast } from '../../components/Toast/showToast';
import { dynamicVariableService, variableService } from '../../api/services';

const UpdateDynamicVariable = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [relationType, setRelationType] = useState<string>('');
  const [initialDataDynamic, setInitialDataDynamic] = useState<DynamicVariableFormValuesX>({
    principalVariableId: id as string,
  });

  const navigate = useNavigate();
  const navigateToListVariables = useCallback(() => {
    navigate('/variables');
  }, []);

  useEffect(() => {
    async function getInitialData() {
      const variableData = await variableService.getById(id as string);
      const principalVariableId =
        variableData?.tipoRelacao === 'Principal'
          ? variableData?.idVariavel
          : variableData!.variavelRelacao![0].idVariavelPrincipal;
      setRelationType(variableData?.tipoRelacao as string);
      console.log('A variableData eh:', variableData);

      if (variableData?.variavelRelacao != null) {
        const dependentVariablesMap = new Map<string, DependentVariable>();
        variableData.variavelRelacao.forEach((el) => {
          if (!dependentVariablesMap.has(el.idVariavelDependente)) {
            dependentVariablesMap.set(el.idVariavelDependente, {
              dependentVariableId: {
                label: el.idVariavelDependente,
                value: el.idVariavelDependente,
              },
              values: [
                {
                  principalVariableValue: {
                    label: el.valorVariavelPrincipal,
                    value: el.valorVariavelPrincipal,
                  },
                  dependentVariableValue: {
                    label: el.valorVariavelDependente,
                    value: el.valorVariavelDependente,
                  },
                },
              ],
            });
          } else {
            const existingVariable = dependentVariablesMap.get(el.idVariavelDependente);
            existingVariable?.values?.push({
              principalVariableValue: {
                label: el.valorVariavelPrincipal,
                value: el.valorVariavelPrincipal,
              },
              dependentVariableValue: {
                label: el.valorVariavelDependente,
                value: el.valorVariavelDependente,
              },
            });
          }
        });
        setInitialDataDynamic({
          principalVariableId: principalVariableId as string,
          listDepedentVariable: Array.from(dependentVariablesMap.values()),
        });
      }

      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: DynamicVariableFormValuesX) => {
    const ListaVariaveisDepedentes =
      data.listDepedentVariable?.map((dependentVariable) => ({
        IdVariavelDependente: dependentVariable.dependentVariableId?.value || '',
        Values:
          dependentVariable.values?.map((value) => ({
            ValorVariavelPrincipal: value.principalVariableValue.value || undefined,
            ValorVariavelDependente: value.dependentVariableValue.value || undefined,
          })) || [],
      })) || [];

    const successfullyUpdated = await dynamicVariableService.update({
      IdVariavelPrincipal: data.principalVariableId,
      ListaVariaveisDepedentes,
    });
    if (successfullyUpdated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao atualizar variável dinâmica.',
      });
      navigateToListVariables();
    }
  }, []);

  return !isLoading ? (
    <>
      <DynamicVariableForm relationType={relationType} initialData={initialDataDynamic} onSubmit={handleSubmit} />
    </>
  ) : null;
};

export default UpdateDynamicVariable;
