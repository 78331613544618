import { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { textService, variableService } from '../../api/services';
import { VariableData } from '../../types/Variable.types';
import { TextData } from '../../types/Text.types';
import { TemplateDocument } from '../../types/Template.types';
import appConfig from '../../appConfig';
import SuspensePage from '../../components/SuspensePage';
import SuspenseCreateVariable from '../Variables/SuspenseCreateVariable';

interface TextEditorProps {
  onEditorChange: (content: string) => void;
  initialValue: string;
  disabled: boolean;
  template?: TemplateDocument;
  type?: 'default' | 'revision';
}

const TextEditor = ({ onEditorChange, initialValue, disabled, template, type = 'default' }: TextEditorProps) => {
  const editorRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openedNewVariablePage, setOpenedNewVariablePage] = useState(false);
  const [initialContent, setInitialContent] = useState(initialValue);
  const [contentEditor, setContentEditor] = useState('');
  const [footnoteOptions, setFootnoteOptions] = useState<TextData[]>([]);
  const editor = useRef(null);
  const itemsPerPage = 20;

  const fetchData = async (search: string, exactMatch: boolean) => {
    try {
      const result = await variableService.listPaged({
        pagination: { skip: 0, limit: itemsPerPage },
        sort: { field: 'dataCriacao', order: 'DESC' },
        query: search,
        exactMatch: exactMatch,
      });

      if (result) {
        return result.data;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };

  /* istanbul ignore next */
  const handleEditorChange = useCallback((content: string) => {
    setContentEditor(content);
    onEditorChange(content);
  }, []);

  const onInitCallback = useCallback((evt: any, editor: any) => (editorRef.current = editor), []);

  const handleGetVariables = useCallback(async (content: string, exactMatch: boolean) => {
    return await fetchData(content, exactMatch);
  }, []);

  // const getVariables = async () => {
  //   const variablesResponse = await variableService.list();
  //   if (variablesResponse) setVariableOptions(variablesResponse);
  // };

  const getFootnotes = async () => {
    const textsResponse = await textService.list('NotaRodape');
    if (textsResponse) setFootnoteOptions(textsResponse);
  };

  useEffect(() => {
    const fetchPromises = async () => {
      //await getVariables();
      await getFootnotes();
      setIsLoading(false);
    };
    fetchPromises();
  }, []);

  useEffect(() => {
    onEditorChange(initialValue);
  }, []);

  /* istanbul ignore next */
  const closeNewVariablePage = useCallback(async () => {
    setOpenedNewVariablePage(false);
    //await getVariables();
    setIsLoading(false);
    setInitialContent(contentEditor);
  }, [contentEditor]);

  /* istanbul ignore next */
  const openNewVariablePage = useCallback(() => {
    setIsLoading(true);
    setOpenedNewVariablePage(true);
  }, []);

  return (
    <>
      {
        /* istanbul ignore next */ openedNewVariablePage && (
          <SuspensePage
            title="Textos"
            menuItems={[{ name: 'Nova variável', onClick: () => null }]}
            backButtonTitle="Voltar ao texto"
            onBackButtonClick={closeNewVariablePage}>
            <SuspenseCreateVariable closeContent={closeNewVariablePage} />
          </SuspensePage>
        )
      }
      {isLoading ? null : (
        <Editor
          ref={editor}
          tinymceScriptSrc={appConfig.publicUrl + '/tinymce/tinymce.min.js'}
          onInit={onInitCallback}
          onEditorChange={handleEditorChange}
          initialValue={initialContent}
          disabled={disabled}
          init={{
            init_instance_callback: (editor) => {
              /* istanbul ignore next */
              if (type === 'revision') {
                editor.formatter.register('revision', {
                  inline: 'span',
                  styles: { backgroundColor: '#FDF6B2' },
                });
                editor.formatter.apply('revision');
              }
            },
            setup: (editor) => {
              if (type === 'revision') {
                /* istanbul ignore next */
                editor.on('beforeinput', (params) => {
                  if (params.inputType !== 'deleteContentBackward' && editor.formatter.match('revision') === false) {
                    editor.formatter.remove('revision');
                    editor.formatter.apply('revision');
                  }
                });
              }
            },
            readonly: true,
            height: 600,
            branding: false,
            language_url: '/custom-tinymce/langs/pt_BR.js',
            language: 'pt_BR',
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'wordcount',
            ],
            toolbar:
              'undo redo | blocks | ' +
              'fontfamily fontsize bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | image',
            contextmenu: 'variable footnote',
            content_css: '/custom-tinymce/editor.css',
            skin_url: '/custom-tinymce/skins/',
            skin: 'cgv',
            icons_url: '/custom-tinymce/icons/icons.js',
            icons: 'cgv',
            external_plugins: {
              variable: '/custom-tinymce/plugins/variable.js',
              footnote: '/custom-tinymce/plugins/footnote.js',
            },
            footnoteOptions: footnoteOptions,
            openNewVariablePage: openNewVariablePage,
            searchVariables: handleGetVariables,
            images_upload_handler: async (blobInfo) => {
              /* istanbul ignore next */
              return Promise.resolve('data:image/png;base64,' + blobInfo.base64());
            },
            menu: {
              file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
              edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
              view: {
                title: 'View',
                items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen',
              },
              insert: {
                title: 'Insert',
                items:
                  'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
              },
              format: {
                title: 'Format',
                items:
                  'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat',
              },
              tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
              table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
              help: { title: 'Help', items: 'help' },
            },
            font_family_formats:
              'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri,arial,sans-serif;Calibri Light=calibri light,arial,sans-serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; IBM Plex Sans=ibm plex sans; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
            content_style: `@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&display=swap');
                        body {
                          padding-top: ${template?.margin.top ? template?.margin.top + 'cm' : '4rem'};
                          padding-bottom: ${template?.margin.bottom ? template?.margin.bottom + 'cm' : '6rem'};
                          padding-left: ${template?.margin.left ? template?.margin.left + 'cm' : '6rem'};
                          padding-right: ${template?.margin.right ? template?.margin.right + 'cm' : '6rem'};
                          font-family: ${template?.font ? template?.font : 'IBM Plex Sans'};
                          font-size: 13pt;
                          ${
                            template?.topic
                              ? `.topic {
                                display: flex;
                                vertical-align: middle;
                                background-color: #fff;
                                background-image: url("${template.topic}");
                                background-repeat: no-repeat;
                                background-size: contain;
                                width: 100%;
                                min-height: 60px;
                                padding-top: 1%;
                                padding-bottom: 1%;
                          }`
                              : ''
                          }
                        }`,
            styles_merge: true,
            styles: [
              {
                title: 'Line Height',
                items: [
                  { title: 'Normal Line Height', inline: 'span', styles: { 'line-height': '100%' } },
                  { title: 'Line Height + 10%', inline: 'span', styles: { 'line-height': '110%' } },
                  { title: 'Line Height + 50%', inline: 'span', styles: { 'line-height': '150%' } },
                  { title: 'Line Height + 100%', inline: 'span', styles: { 'line-height': '200%' } },
                ],
              },
            ],
            formats: {
              topic: {
                block: 'div',
                attributes: { title: 'Tópico' },
                classes: 'topic',
              },
            },
            block_formats:
              'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6; Topic=topic',
            font_size_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
          }}
        />
      )}
    </>
  );
};

export default TextEditor;
