import * as DropdownMenuComponent from '@radix-ui/react-dropdown-menu';
import { Content } from '../../components/DropdownMenu/styles';
import { useCallback } from 'react';
import { DialogConfirmSaveDocument } from './DialogConfirmSaveDocument';
import { useNavigate } from 'react-router-dom';

interface DocumentSaveOptionsProps {
  openPreview: () => void;
  onSave: () => void;
}

const DocumentSaveOptions = ({ onSave }: DocumentSaveOptionsProps) => {
  const navigate = useNavigate();
  const navigateToListPage = useCallback(() => {
    navigate('/documents');
  }, []);

  return (
    <DropdownMenuComponent.Portal container={document.getElementById('full-page-root')}>
      <Content sideOffset={5}>
        <>
          <DialogConfirmSaveDocument onSave={onSave} navigateToListPage={navigateToListPage} />
        </>
      </Content>
    </DropdownMenuComponent.Portal>
  );
};

export default DocumentSaveOptions;
