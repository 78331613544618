export default class CustomContextPadProvider {
  constructor(bpmnFactory, config, contextPad, create, elementFactory, injector, translate, bpmnReplace) {
    this.bpmnFactory = bpmnFactory;
    this.create = create;
    this.elementFactory = elementFactory;
    this.translate = translate;
    this.bpmnReplace = bpmnReplace;

    if (config.autoPlace !== false) {
      this.autoPlace = injector.get('autoPlace', false);
    }

    contextPad.registerProvider(this);
  }

  getContextPadEntries(element) {
    const { bpmnReplace } = this;

    return function (entries) {
      function changeElementToSubProcess() {
        return function (event, element) {
          bpmnReplace.replaceElement(element, {
            type: 'bpmn:SubProcess',
            isExpanded: false,
          });
        };
      }

      function changeElementToTask() {
        return function (event, element) {
          bpmnReplace.replaceElement(element, {
            type: 'bpmn:Task',
          });
        };
      }

      function changeElementToExclusiveGateway() {
        return function (event, element) {
          bpmnReplace.replaceElement(element, {
            type: 'bpmn:ExclusiveGateway',
          });
        };
      }

      function changeElementToFinalEvent() {
        return function (event, element) {
          bpmnReplace.replaceElement(element, {
            type: 'bpmn:EndEvent',
          });
        };
      }

      function getEntries(entries, allowedEntries) {
        Object.entries(entries).forEach((entry) => {
          const entryName = entry[0];
          if (!Object.keys(allowedEntries).includes(entryName)) {
            delete entries[entryName];
          } else {
            entries[entryName] = { ...entry[1], title: allowedEntries[entryName] };
          }
        });
        return entries;
      }

      if (element.type === 'bpmn:ExclusiveGateway') {
        const allowedEntries = { delete: 'Remover elemento' };
        entries = getEntries(entries, allowedEntries);
      }

      if (element.type === 'bpmn:Task') {
        entries['replace-to-subprocess'] = {
          group: 'edit',
          className: 'bpmn-icon-subprocess-collapsed',
          action: {
            click: changeElementToSubProcess(),
          },
        };
        entries['replace-to-exclusive-gateway'] = {
          group: 'edit',
          className: 'bpmn-icon-gateway-none',
          action: {
            click: changeElementToExclusiveGateway(),
          },
        };
        entries['replace-to-final-event'] = {
          group: 'edit',
          className: 'bpmn-icon-end-event-none',
          action: {
            click: changeElementToFinalEvent(),
          },
        };
        const allowedEntries = {
          'append.end-event': 'Anexar evento final',
          'append.gateway': 'Anexar condição',
          'append.append-task': 'Anexar texto',
          'replace-to-subprocess': 'Trocar por sub-modelo',
          'replace-to-exclusive-gateway': 'Trocar por condição',
          'replace-to-final-event': 'Trocar por evento final',
          connect: 'Incluir conexão',
          delete: 'Remover elemento',
        };
        entries = getEntries(entries, allowedEntries);
      }

      if (element.type === 'bpmn:SubProcess') {
        entries['replace-to-task'] = {
          group: 'edit',
          className: 'bpmn-icon-task-none',
          title: 'Trocar por texto',
          action: {
            click: changeElementToTask(),
          },
        };
        const allowedEntries = {
          'append.end-event': 'Anexar evento final',
          'append.gateway': 'Anexar condição',
          'append.append-task': 'Anexar texto',
          'replace-to-task': 'Trocar por texto',
          connect: 'Incluir conexão',
          delete: 'Remover elemento',
        };
        entries = getEntries(entries, allowedEntries);
      }

      if (element.type === 'bpmn:StartEvent') {
        const allowedEntries = {
          'append.gateway': 'Anexar condição',
          'append.append-task': 'Anexar texto',
          connect: 'Incluir conexão',
          delete: 'Remover elemento',
        };
        entries = getEntries(entries, allowedEntries);
      }

      if (element.type === 'bpmn:EndEvent') {
        const allowedEntries = {
          connect: 'Incluir conexão',
          delete: 'Remover elemento',
        };
        entries = getEntries(entries, allowedEntries);
      }

      if (element.type === 'bpmn:SequenceFlow') {
        const allowedEntries = {
          delete: 'Remover elemento',
        };
        entries = getEntries(entries, allowedEntries);
      }

      return entries;
    };
  }
}

CustomContextPadProvider.$inject = [
  'bpmnFactory',
  'config',
  'contextPad',
  'create',
  'elementFactory',
  'injector',
  'translate',
  'bpmnReplace',
];
