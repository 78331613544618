import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import { append as svgAppend, create as svgCreate } from 'tiny-svg';

import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import { query as domQuery } from 'min-dom';

const HIGH_PRIORITY = 1500;

export default class CustomRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer, canvas, elementRegistry) {
    super(eventBus, HIGH_PRIORITY);
    this.bpmnRenderer = bpmnRenderer;
    this.elementRegistry = elementRegistry;

    let defs = domQuery('defs', canvas._svg);
    if (!defs) {
      defs = svgCreate('defs');
      svgAppend(canvas._svg, defs);
    }
  }

  canRender(element) {
    return isAny(element, ['bpmn:Task', 'bpmn:Event', 'bpmn:ExclusiveGateway', 'bpmn:SequenceFlow', 'label']);
  }

  drawShape(parentNode, element) {
    const shape = this.bpmnRenderer.drawShape(parentNode, element);

    if (element.type === 'label') {
      if (element.id.startsWith('Flow')) {
        if (element.di.waypoint) {
          const gatewayElement = this.elementRegistry.get(element.businessObject.sourceRef.id);
          const lastWayPoint = element.di.waypoint.length - 1;
          if (gatewayElement.x > element.di.waypoint[lastWayPoint].x) {
            element.x = element.di.waypoint[lastWayPoint].x + 10;
          } else {
            element.x = element.di.waypoint[lastWayPoint].x - element.width - 10;
          }

          if (gatewayElement.y < element.di.waypoint[lastWayPoint].y) {
            element.y = element.di.waypoint[lastWayPoint].y - 20;
          } else {
            element.y = element.di.waypoint[lastWayPoint].y + 10;
          }
        }
      }
    }

    return shape;
  }
}

CustomRenderer.$inject = ['eventBus', 'bpmnRenderer', 'canvas', 'elementRegistry'];
