import Button from '../../components/Form/Button';
import Heading from '../../components/Text/Heading';
import Paragraph from '../../components/Text/Paragraph';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { LogDialog } from './styles';
import Dialog from '../../components/Dialog';
import { Item } from '../../components/DropdownMenu/styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ForwardIcon, BackwardIcon, ListBulletIcon } from '@heroicons/react/24/solid';
import { StepLogModel } from '../Documents/utils';
import { OptionType } from '../../components/Form/Select';
import { extratoLogService } from '../../api/services';
import Loading from '../../components/Loading';

interface DialogLogProps {
  documentId: string;
  documentName: string;
}

export const DialogLog = ({ documentId, documentName }: DialogLogProps) => {
  const [openedModal, setOpenedModal] = useState(false);
  // const [documentId, setDocumentId] = useState('');
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [documentLog, setDocumentLog] = useState<StepLogModel[]>([]);

  const openLogModal = useCallback((e: any) => {
    e.preventDefault();
    setOpenedModal((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (!isMounted.current || openedModal === false) {
      isMounted.current = true;
      return;
    }

    console.log('modal aberta');
    console.log('document: ' + documentId);
    fetchData();
  }, [openedModal]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await extratoLogService.getById(documentId);

      if (result && result.logJson !== undefined) {
        setDocumentLog(JSON.parse(result.logJson));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getValue = function (value: string | boolean | OptionType | string[]) {
    const values: string[] = [];

    switch (Object.prototype.toString.call(value)) {
      case '[object Array]':
        Object.entries(value).map((val, index) => {
          values.push(val[1].toString());
        });
        return values.join(', ');
      case '[object Object]':
        return Object.entries(value)[0][1].toString();
      case '[object Boolean]':
        return value === false ? 'Não' : 'Sim';
      default:
        return value == null ? 'Não Informado' : value.toString();
    }
  };

  return (
    <Dialog
      controlledClose={true}
      open={openedModal}
      setOpen={setOpenedModal}
      size="medium"
      secondaryButton={<Button kind="outline">Cancelar</Button>}
      content={
        <LogDialog>
          <Heading>Log da última interação</Heading>
          {!loading ? (
            <>
              {documentLog.length > 0 ? (
                <div className="container">
                  {documentLog.map((log, index) => (
                    <div key={index} className="log">
                      <h1>
                        {log.action === 'next' ? <ForwardIcon /> : <BackwardIcon />} {log.title}
                      </h1>
                      <div>
                        {log.action === 'next' ? (
                          <p>
                            {log.data.map((field, index) => (
                              <span key={index}>
                                <b>{field.name}: </b> {getValue(field.value)} <br />
                              </span>
                            ))}
                            <br />
                            <span>
                              <i>Avançou para próxima etapa.</i>
                            </span>
                          </p>
                        ) : (
                          <p>
                            <i>Voltou para a etapa anterior.</i>
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <Paragraph>Não foram encontrados logs para este documento.</Paragraph>
              )}

              <PDFDownloadLink
                style={{ textDecoration: 'none' }}
                document={<DownloadLogsPdf documentLogs={documentLog} />}
                fileName={documentName.trim() + '.pdf'}>
                {({ blob, url, loading, error }) => (
                  <Button kind="outline">{loading ? 'Carregando documento...' : 'Baixar PDF'}</Button>
                )}
              </PDFDownloadLink>
            </>
          ) : (
            <Loading />
          )}
        </LogDialog>
      }>
      <Item onClick={openLogModal}>
        <ListBulletIcon /> <span>Visualizar Logs</span>
      </Item>
    </Dialog>
  );
};

interface DownloadLogProps {
  documentLogs: StepLogModel[];
}

export const DownloadLogsPdf = ({ documentLogs }: DownloadLogProps) => {
  const getValue = function (value: string | boolean | OptionType | string[]) {
    const values: string[] = [];

    switch (Object.prototype.toString.call(value)) {
      case '[object Array]':
        Object.entries(value).map((val, index) => {
          values.push(val[1].toString());
        });
        return values.join(', ');
      case '[object Object]':
        return Object.entries(value)[0][1].toString();
      case '[object Boolean]':
        return value === false ? 'Não' : 'Sim';
      default:
        return value == null ? 'Não Informado' : value.toString();
    }
  };

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 10,
    },
    section: {
      margin: 10,
      padding: 10,
      fontSize: 12,
    },
    h1: {
      fontSize: 14,
    },
    i: {
      fontStyle: 'italic',
    },
  });

  return (
    <Document>
      <Page style={styles.page}>
        {documentLogs.map((log, index) => (
          <View key={index} style={styles.section}>
            <Text style={styles.h1}>{log.title}</Text>
            {log.action === 'next' ? (
              <View key={index}>
                {log.data.map((field, index) => (
                  <View key={index}>
                    <Text>
                      {field.name}: <Text style={{ color: '#023ff7' }}>{getValue(field.value)}</Text>
                    </Text>
                  </View>
                ))}
                <Text style={styles.i}>Avançou para próxima etapa.</Text>
              </View>
            ) : (
              <View key={index}>
                <Text style={styles.i}>Voltou para a etapa anterior.</Text>
              </View>
            )}
          </View>
        ))}
      </Page>
    </Document>
  );
};
