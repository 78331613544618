import type { DefaultTheme } from 'styled-components';

type ColorTypes = Record<number | string, string>;

type FontType = Record<string, string>;

type Spacings = {
  [k in number]: string;
};

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: Record<string, ColorTypes>;
    fonts: FontType;
    spacings: Spacings;
  }
}

export const theme: DefaultTheme = {
  colors: {
    neutral: {
      white: '#FFFFFF',
      black: '#000000',
      grey: '#A5A5A5',
    },
    blue: {
      900: '#06445D',
      800: '#09506C',
      700: '#0A749E',
      600: '#2093C1',
      500: '#50AACE',
      400: '#76BCD9',
      300: '#96CCE1',
      200: '#B1D9E9',
      100: '#E0F0F6',
      50: '#F5FAFC',
    },
    grey: {
      900: '#111928',
      800: '#1F2A37',
      700: '#374151',
      600: '#4B5563',
      500: '#6B7280',
      400: '#9CA3AF',
      300: '#D1D5DB',
      200: '#E5E7EB',
      100: '#F3F4F6',
      50: '#F9FAFB',
    },
    teal: {
      900: '#014451',
      800: '#05505C',
      700: '#036672',
      600: '#047481',
      500: '#0694A2',
      400: '#16BDCA',
      300: '#7EDCE2',
      200: '#AFECEF',
      100: '#D5F5F6',
      50: '#EDFAFA',
    },
    green: {
      900: '#014737',
      800: '#03543F',
      700: '#046C4E',
      600: '#057A55',
      500: '#0E9F6E',
      400: '#31C48D',
      300: '#84E1BC',
      200: '#BCF0DA',
      100: '#DEF7EC',
      50: '#F3FAF7',
    },
    red: {
      900: '#771D1D',
      800: '#9B1C1C',
      700: '#C81E1E',
      600: '#E02424',
      500: '#F05252',
      400: '#F98080',
      300: '#F8B4B4',
      200: '#FBD5D5',
      100: '#FDE8E8',
      50: '#FDF2F2',
    },
  },
  fonts: {
    'xxx-large': '4.2rem',
    'x-large': '2.6rem',
    large: '2.4rem',
    medium: '2rem',
    small: '1.6rem',
    'x-small': '1.4rem',
    'xx-small': '1.2rem',
  },
  spacings: {
    0.5: '0.4rem',
    1: '0.8rem',
    1.5: '1.2rem',
    2: '1.6rem',
    2.5: '2rem',
    3: '2.4rem',
    4: '3.2rem',
    5: '4rem',
  },
};
