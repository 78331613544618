import * as DropdownMenuComponent from '@radix-ui/react-dropdown-menu';
import styled, { keyframes } from 'styled-components';
import { theme } from '../../styles/themes/default';

const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Content = styled(DropdownMenuComponent.Content)`
  background-color: #ffffff;
  border-radius: 0.3rem;
  box-shadow: 0 0.8rem 2.4rem rgba(4, 146, 242, 0.1);
  padding: 0.2rem 0;
  min-width: 20rem;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side='top'] {
    animation-name: ${slideDownAndFade};
  }

  &[data-side='right'] {
    animation-name: ${slideLeftAndFade};
  }

  &[data-side='bottom'] {
    animation-name: ${slideUpAndFade};
  }

  &[data-side='left'] {
    animation-name: ${slideRightAndFade};
  }
`;

export const Item = styled(DropdownMenuComponent.Item)`
  font-size: ${theme.fonts['xx-small']};
  padding: ${theme.spacings[1]};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.3rem;
  margin: ${theme.spacings[0.5]};
  line-height: 1;
  position: relative;
  user-select: none;
  outline: none;
  cursor: pointer;

  span {
    color: ${theme.colors.grey[600]};
  }

  &:hover {
    background: rgba(82, 82, 82, 0.1);
  }

  svg {
    height: 2rem;
    margin-right: ${theme.spacings[2]};
  }

  svg path {
    stroke: ${theme.colors.grey[600]};
  }

  &.Alert span {
    color: ${theme.colors.red[500]};
  }

  &.Alert svg path {
    stroke: ${theme.colors.red[500]};
  }
`;

export const Separator = styled(DropdownMenuComponent.Separator)`
  height: 0.1rem;
  background: ${theme.colors.grey[200]};
  width: 100%;
`;
