import { useCallback, useRef, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { conditionService } from '../../api/services';
import Input from '../../components/Form/Input';
import { ConditionData, ConditionOptionBPMN } from '../../types/Condition.types';
import { formatDate } from '../../components/DataTable/utils';
import Box from '../../components/Box';
import Heading from '../../components/Text/Heading';
import { StyledSearch } from '../shared/styles';
import Button from '../../components/Form/Button';
import Loading from '../../components/Loading';
import Switch from '../../components/Form/Switch';
import Paragraph from '../../components/Text/Paragraph';

interface SearchConditionContentProps {
  type: string;
  setBpmnCondition: (conditionId: string, name: string, options: ConditionOptionBPMN[]) => () => void;
}

interface SearchConditionProps extends SearchConditionContentProps {
  title?: string;
  newConditionAction: () => void;
}

const SearchConditionContent = ({ type, setBpmnCondition }: SearchConditionContentProps) => {
  const searchText = useRef<HTMLInputElement>(null);
  const [conditions, setConditions] = useState<ConditionData[]>([]);
  const exactMatch = useRef(false);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 20;

  const fetchData = async (search: string) => {
    try {
      setLoading(true);
      const result = await conditionService.listPaged({
        pagination: { skip: 0, limit: itemsPerPage },
        sort: { field: 'dataCriacao', order: 'DESC' },
        query: search,
        exactMatch: exactMatch.current,
        includeAllInfo: true,
      });

      if (result) {
        setConditions(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitch = useCallback(() => {
    exactMatch.current = !exactMatch.current;
  }, []);

  const searchItems = useCallback(() => {
    const search = searchText.current?.value;
    if (search == null || search.length < 3) {
      return;
    }
    fetchData(search);
  }, []);

  return (
    <>
      <div style={{ display: 'flex', width: '100%', marginBottom: '1.6rem' }}>
        <Input
          name="search"
          icon={<MagnifyingGlassIcon />}
          ref={searchText}
          placeholder="Procurar por condição"
          fullWidth
        />
        <Button kind="secondary" onClick={searchItems} style={{ marginLeft: '1.6rem' }}>
          Pesquisar
        </Button>
      </div>
      <Switch onCheckedChange={handleSwitch} label="Correspondência exata?" name={'test'}></Switch>
      {!loading ? (
        conditions?.length ? (
          <ul className={`list ${type}`}>
            {conditions
              //.filter((condition) => condition.titulo?.toLowerCase().includes(search))
              .map((condition) => (
                <li
                  key={condition.idModeloCondicao}
                  onClick={setBpmnCondition(
                    condition.idModeloCondicao as string,
                    condition.titulo as string,
                    condition.condicaoOpcoes.map((optionCondition) => ({
                      conditionId: optionCondition.idModeloCondicaoOpcao as string,
                      conditionValue: optionCondition.descricao as string,
                    }))
                  )}>
                  <div className="info name">
                    <div className="name">NOME DA CONDIÇÃO</div>
                    <div className="value">{condition.titulo}</div>
                  </div>
                  {type !== 'modal' && (
                    <>
                      <div className="info created-by">
                        <div className="name">CRIADA POR</div>
                        <div className="value">{condition.usuarioCriacao}</div>
                      </div>
                      <div className="info created-at">
                        <div className="name">DATA DA CRIAÇÃO</div>
                        <div className="value">{formatDate(condition.dataCriacao as string)}</div>
                      </div>
                    </>
                  )}
                </li>
              ))}
          </ul>
        ) : (
          <div style={{ width: '100%', textAlign: 'center', margin: '1.6rem auto' }}>
            <Paragraph size="medium" className="type">
              A busca não retornou resultados.
            </Paragraph>
          </div>
        )
      ) : (
        <div style={{ margin: '1.6rem auto' }}>
          <Loading />
        </div>
      )}
    </>
  );
};

const SearchCondition = ({ title, type, setBpmnCondition, newConditionAction }: SearchConditionProps) => {
  return type === 'modal' ? (
    <StyledSearch className={type}>
      <SearchConditionContent type={type} setBpmnCondition={setBpmnCondition} />
      <Button kind="secondary" onClick={newConditionAction}>
        Nova condição
      </Button>
    </StyledSearch>
  ) : (
    <StyledSearch className={type}>
      <Box>
        <div className="suspense-header">
          <Heading>{title}</Heading>
          <Button kind="secondary" onClick={newConditionAction}>
            Nova condição
          </Button>
        </div>
        <SearchConditionContent type={type} setBpmnCondition={setBpmnCondition} />
      </Box>
    </StyledSearch>
  );
};

export default SearchCondition;
