import { SetStateAction, useCallback, useState } from 'react';

import { StyledTable, Pagination } from './styles';

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  getSortedRowModel,
  SortingState,
  Updater,
  VisibilityState,
  PaginationState,
} from '@tanstack/react-table';

import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';

type DataTableProps<T> = {
  data: T[];
  columns: ColumnDef<T>[];
  columnVisibility?: VisibilityState;
  globalFilter?: string;
  defaultSortingState?: SortingState;
};

function DataTable<T>({ data, columns, columnVisibility, globalFilter, defaultSortingState }: DataTableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSortingState ?? []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const setPageIndex = useCallback(
    (pageIndex: Updater<number>) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      table.setPageIndex(pageIndex);
    },
    []
  );

  const setNextPage = useCallback(() => {
    table.nextPage();
  }, []);

  const setPreviousPage = useCallback(() => {
    table.previousPage();
  }, []);

  const getPageItems = () => {
    const maxPages = table.getPageCount();
    const currentPage = table.getState().pagination.pageIndex + 1;

    let leftSide = currentPage - 3;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = currentPage + 3;
    if (rightSide > maxPages) rightSide = maxPages;

    const items = [];

    for (let number = leftSide; number <= rightSide; number++) {
      items.push(
        <button onClick={setPageIndex(number - 1)} key={number} className={number === currentPage ? 'active' : ''}>
          {number}
        </button>
      );
    }

    return items;
  };

  return (
    <StyledTable>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} className={header.column.getCanSort() ? 'sort' : ''}>
                    <div
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                      }}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort()
                        ? {
                            asc: <ChevronUpIcon />,
                            desc: <ChevronDownIcon />,
                          }[header.column.getIsSorted() as string] ?? <ChevronUpDownIcon />
                        : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination>
        <button
          className="arrow"
          data-testid="previous-button"
          onClick={setPreviousPage}
          disabled={!table.getCanPreviousPage()}>
          <ChevronLeftIcon />
        </button>
        {getPageItems()}
        <button className="arrow" data-testid="next-button" onClick={setNextPage} disabled={!table.getCanNextPage()}>
          <ChevronRightIcon />
        </button>
      </Pagination>
    </StyledTable>
  );
}

export default DataTable;
