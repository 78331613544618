import {
  EllipsisVerticalIcon,
  MagnifyingGlassPlusIcon,
  PencilIcon,
  ArrowsRightLeftIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import { SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { variableService } from '../../api/services';
import DropdownMenu from '../../components/DropdownMenu';
import { Content, Item, Separator } from '../../components/DropdownMenu/styles';
import { showToast } from '../../components/Toast/showToast';
import { DialogCancelDelete } from '../shared/DialogCancelDelete';
import { multipleChoiceTypeName, suspenseListTypeName } from './utils';

interface VariableActionsProps {
  id: string;
  name: string;
  type: string;
  relationType: string;
  setDeletedItem: React.Dispatch<SetStateAction<string>>;
}

const VariableActions = ({ id, name, type, relationType, setDeletedItem }: VariableActionsProps) => {
  const [openedActions, setOpenedActions] = useState(false);

  const navigate = useNavigate();
  const navigateToViewVariable = useCallback(() => {
    navigate(`/variables/${id}`);
  }, []);
  const navigateToUpdateVariable = useCallback(() => {
    navigate(`/variables/${id}/update`);
  }, []);
  const navigateToDuplicateVariable = useCallback(() => {
    navigate(`/variables/${id}/duplicate`);
  }, []);
  const navigateToCreateDynamicVariable = useCallback(() => {
    navigate(`/variables/${id}/dynamic`);
  }, []);
  const navigateToUpdateDynamicVariable = useCallback(() => {
    navigate(`/variables/${id}/dynamic/update`);
  }, []);

  const removeVariable = useCallback(
    (id: string) => async () => {
      const successfullyDeleted = await variableService.delete(id);
      if (successfullyDeleted) {
        showToast({
          type: 'success',
          text: 'Sucesso ao remover variável.',
        });
        setDeletedItem(id);
      }
      setOpenedActions(false);
    },
    []
  );

  return (
    <DropdownMenu
      open={openedActions}
      setOpen={setOpenedActions}
      content={
        <Content sideOffset={5}>
          <Item onClick={navigateToViewVariable}>
            <MagnifyingGlassPlusIcon /> <span>Ver detalhes</span>
          </Item>

          <Separator />

          <Item onClick={navigateToUpdateVariable}>
            <PencilIcon /> <span>Editar variável</span>
          </Item>
          <Item onClick={navigateToDuplicateVariable}>
            <DocumentDuplicateIcon /> <span>Duplicar variável</span>
          </Item>

          {[suspenseListTypeName, multipleChoiceTypeName].includes(type) &&
          !['Principal', 'Dependente'].includes(relationType) ? (
            <>
              <Separator />
              <Item onClick={navigateToCreateDynamicVariable}>
                <ArrowsRightLeftIcon /> <span>Tornar variável dinâmica</span>
              </Item>
            </>
          ) : null}

          {['Principal', 'Dependente'].includes(relationType) ? (
            <>
              <Separator />
              <Item onClick={navigateToUpdateDynamicVariable}>
                <ArrowsRightLeftIcon /> <span>Alterar variável dinâmica</span>
              </Item>
            </>
          ) : null}

          <Separator />

          <DialogCancelDelete itemName={name} itemType="variável" removeItem={removeVariable(id)} />
        </Content>
      }>
      <EllipsisVerticalIcon className="actions-button" />
    </DropdownMenu>
  );
};

export default VariableActions;
