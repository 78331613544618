import {
  SectionData,
  SectionDataCreateRequest,
  SectionDataCreateResponse,
  SectionDataDeleteResponse,
} from '../../types/Section.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface ISectionApiClient {
  create(data: SectionDataCreateRequest): Promise<SectionDataCreateResponse | undefined>;
  list(): Promise<SectionData[] | undefined>;
  getById(id: string): Promise<SectionData | undefined>;
  update(id: string, data: SectionDataCreateRequest): Promise<SectionData | undefined>;
  delete(id: string): Promise<SectionDataDeleteResponse | undefined>;
}

export class SectionApiClient implements ISectionApiClient {
  sectionApiClient: IApiClient;

  constructor(SectionApiClient: IApiClient) {
    this.sectionApiClient = SectionApiClient;
  }

  async create(data: SectionDataCreateRequest): Promise<SectionDataCreateResponse | undefined> {
    const response = await this.sectionApiClient.post<SectionDataCreateRequest, SectionDataCreateResponse>(
      `/secoes`,
      data
    );
    return response;
  }

  async list(): Promise<SectionData[] | undefined> {
    const response = await this.sectionApiClient.get<SectionData[]>(`/secoes`);
    return response;
  }

  async getById(id: string): Promise<SectionData | undefined> {
    const response = await this.sectionApiClient.get<SectionData>(`/secoes/${id}`);
    return response;
  }

  async update(id: string, data: SectionDataCreateRequest): Promise<SectionDataCreateResponse | undefined> {
    const response = await this.sectionApiClient.put<SectionDataCreateRequest, SectionDataCreateResponse>(
      `/secoes/${id}`,
      data
    );
    return response;
  }

  async delete(id: string): Promise<SectionDataDeleteResponse | undefined> {
    const response = await this.sectionApiClient.delete<SectionDataDeleteResponse>(`/secoes/${id}`);
    return response;
  }
}

export default class SectionService {
  sectionApiClient: ISectionApiClient;

  constructor() {
    this.sectionApiClient = new SectionApiClient(new ApiClient());
  }

  async create(data: SectionDataCreateRequest): Promise<SectionDataCreateResponse | undefined> {
    return this.sectionApiClient.create(data);
  }

  async list(): Promise<SectionData[] | undefined> {
    return this.sectionApiClient.list();
  }

  async getById(id: string): Promise<SectionData | undefined> {
    return this.sectionApiClient.getById(id);
  }

  async update(id: string, data: SectionDataCreateRequest): Promise<SectionDataCreateResponse | undefined> {
    return this.sectionApiClient.update(id, data);
  }

  async delete(id: string): Promise<SectionDataDeleteResponse | undefined> {
    return this.sectionApiClient.delete(id);
  }
}
