import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const StyledTable = styled.div`
  width: 100%;

  table {
    background: ${theme.colors.neutral.white};
    border-radius: 0.4rem;
    border: 0.1rem solid ${theme.colors.grey[200]};
    width: 100%;
  }

  tbody {
    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }

      td {
        text-align: left;
        font-size: ${theme.fonts['x-small']};
        padding: ${theme.spacings[2]} ${theme.spacings[3]};
        color: ${theme.colors.grey[600]};
        border-bottom: 0.1rem solid ${theme.colors.grey[200]};

        svg {
          height: 2.4rem;
        }

        .actions-button {
          cursor: pointer;
        }
      }
    }
  }

  th {
    color: ${theme.colors.grey[400]};
    font-size: ${theme.fonts['xx-small']};
    padding: ${theme.spacings[2]} ${theme.spacings[3]};
    text-transform: uppercase;
    border-bottom: 0.1rem solid ${theme.colors.grey[200]};

    div {
      display: flex;
      svg {
        height: 1.4rem;
        fill: ${theme.colors.grey[400]};
        align-items: center;
        margin-left: ${theme.spacings[0.5]};
      }
    }

    &.sort {
      cursor: pointer;
    }
  }
`;

export const Pagination = styled.div`
  margin-top: ${theme.spacings[4]};
  display: flex;
  justify-content: center;

  button {
    border: none;
    background: none;
    padding: ${theme.spacings[1]} ${theme.spacings[2]};
    border-radius: 0.4rem;
    color: ${theme.colors.grey[900]};
    font-size: ${theme.fonts['xx-small']};
    line-height: ${theme.fonts.small};
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;

    &.active {
      background: ${theme.colors.blue[600]};
      color: ${theme.colors.neutral.white};
    }

    &.arrow {
      padding: 0;
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      svg {
        height: 1.4rem;
      }
    }
  }
`;
