import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const StyledFileUploader = styled.div`
  background: ${theme.colors.neutral.white};
  border: 0.1rem solid ${theme.colors.grey[200]};
  border-radius: 0.4rem;
  padding: ${theme.spacings[2]};
  .dropzone {
    background: ${theme.colors.neutral.white};
    border: 0.1rem dashed ${theme.colors.grey[200]};
    border-radius: 0.5rem;
    padding: ${theme.spacings[4]} 0 ${theme.spacings[2]};
    text-align: center;
    a {
      color: ${theme.colors.blue[600]};
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &.image-as-background {
    border: none;
    .dropzone {
      padding: 0;
      height: 12rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: ${theme.colors.grey[50]};
    }
  }
  .thumbs {
    li {
      display: flex;
      align-items: center;
      list-style: none;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        img {
          width: 10rem;
          height: 6rem;
          object-fit: cover;
          border-radius: 0.8rem;
          margin: ${theme.spacings[1]} ${theme.spacings[1]} 0 0;
          flex: 0 0 10rem;
        }
        p {
          margin: 0;
        }
      }
      button {
        text-align: right;
        svg {
          height: 2rem;
          stroke: ${theme.colors.grey[400]};
          margin-right: 0;
          &:hover {
            stroke: ${theme.colors.grey[700]};
          }
        }
      }
    }
  }
`;

export const StyledBackgroundImage = styled.div<{ background?: string }>`
  height: 12rem;
  width: 100%;
  background: url(${(props) => props.background});
  background-size: object-fit;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;
