import { XCircleIcon } from '@heroicons/react/24/outline';
import Paragraph from '../../Text/Paragraph';
import { StyledFormGroup } from './styles';

export interface FormGroupProps {
  children: React.ReactNode;
  inline?: boolean | null;
  error?: string | null;
  errors?: string[] | null;
}

export default function FormGroup({ children, inline = false, error = null, errors = null }: FormGroupProps) {
  return (
    <StyledFormGroup error={error}>
      <div className="form-item" style={inline ? { display: 'inline-flex' } : {}}>
        {children}
      </div>

      {error && (
        <Paragraph className="error">
          <XCircleIcon /> {error}
        </Paragraph>
      )}

      {errors &&
        errors
          .filter((error) => !!error)
          .map((error) => (
            <Paragraph key={error} className="error">
              <XCircleIcon /> {error}
            </Paragraph>
          ))}
    </StyledFormGroup>
  );
}
