/* eslint-disable react/jsx-no-bind */
import { Dispatch, KeyboardEventHandler, SetStateAction, useCallback, useState } from 'react';
import { StyledCreatableSelect } from './styles';

export interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

const components = {
  DropdownIndicator: null,
};

interface CreatableSelectProps {
  placeholder?: string;
  options: readonly Option[];
  setOptions: Dispatch<SetStateAction<readonly Option[]>>;
  disabled?: boolean;
}
export const CreatableSelect = ({ options, setOptions, disabled = false, ...props }: CreatableSelectProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown: KeyboardEventHandler = useCallback(
    (event) => {
      if (!inputValue) return;
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          setOptions((prev) => [...prev, createOption(inputValue)]);
          setInputValue('');
          event.preventDefault();
      }
    },
    [inputValue]
  );

  /* istanbul ignore next */
  const handleChange = useCallback((newValue: any) => setOptions(newValue), []);

  const handleInputChange = useCallback((newValue: SetStateAction<string>) => setInputValue(newValue), [inputValue]);

  return (
    <StyledCreatableSelect
      className="react-select-container"
      classNamePrefix="react-select"
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      value={options}
      isDisabled={disabled}
      {...props}
    />
  );
};
