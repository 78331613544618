import { assign } from 'min-dash';
import { getDi } from 'bpmn-js/lib/util/ModelUtil';

export default class CustomPalette {
  constructor(create, elementFactory, palette, translate, handTool, globalConnect) {
    this.create = create;
    this.elementFactory = elementFactory;
    this.translate = translate;
    this.handTool = handTool;
    this.globalConnect = globalConnect;

    palette.registerProvider(this);
  }

  getPaletteEntries() {
    const { create, elementFactory, translate, handTool, globalConnect } = this;

    function createAction(type, group, className, title, options) {
      function createListener(event) {
        var shape = elementFactory.createShape(assign({ type: type }, options));

        if (options) {
          var di = getDi(shape);
          di.isExpanded = options.isExpanded;
        }

        create.start(event, shape);
      }

      var shortType = type.replace(/^bpmn:/, '');

      return {
        group: group,
        className: className,
        title: title || translate('Create {type}', { type: shortType }),
        action: {
          dragstart: createListener,
          click: createListener,
        },
      };
    }

    return {
      'hand-tool': {
        group: 'tools',
        className: 'bpmn-icon-hand-tool',
        title: translate('Ferramenta de mão'),
        action: {
          click: function (event) {
            handTool.activateHand(event);
          },
        },
      },
      'global-connect-tool': {
        group: 'tools',
        className: 'bpmn-icon-connection-multi',
        title: translate('Ferramenta de conexão'),
        action: {
          click: function (event) {
            globalConnect.start(event);
          },
        },
      },
      'tool-separator': {
        group: 'tools',
        separator: true,
      },
      'create.start-event': createAction(
        'bpmn:StartEvent',
        'event',
        'bpmn-icon-start-event-none',
        translate('Inserir evento de início')
      ),
      'create.exclusive-gateway': createAction(
        'bpmn:ExclusiveGateway',
        'gateway',
        'bpmn-icon-gateway-none',
        translate('Inserir condição')
      ),
      'create.task': createAction('bpmn:Task', 'activity', 'bpmn-icon-task', translate('Inserir texto')),
      'create.end-event': createAction(
        'bpmn:EndEvent',
        'event',
        'bpmn-icon-end-event-none',
        translate('Inserir evento final')
      ),
      'create.subprocess': createAction(
        'bpmn:SubProcess',
        'subprocess',
        'bpmn-icon-subprocess-collapsed',
        translate('Inserir sub-modelo')
      ),
    };
  }
}

CustomPalette.$inject = ['create', 'elementFactory', 'palette', 'translate', 'handTool', 'globalConnect'];
