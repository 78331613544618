import { useCallback, useRef, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { textService } from '../../api/services';
import Input from '../../components/Form/Input';
import { TextData } from '../../types/Text.types';
import { formatDate } from '../../components/DataTable/utils';
import Box from '../../components/Box';
import Heading from '../../components/Text/Heading';
import { StyledSearch } from '../shared/styles';
import Button from '../../components/Form/Button';
import Switch from '../../components/Form/Switch';
import Loading from '../../components/Loading';
import Paragraph from '../../components/Text/Paragraph';

interface SearchTextContentProps {
  type: string;
  setBpmnText: (textId: string, name: string) => () => void;
}

interface SearchTextProps extends SearchTextContentProps {
  title?: string;
  newTextAction: () => void;
}

const SearchTextContent = ({ type, setBpmnText }: SearchTextContentProps) => {
  const searchText = useRef<HTMLInputElement>(null);
  const exactMatch = useRef(false);
  const [texts, setTexts] = useState<TextData[]>([]);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 50;

  const fetchData = async (search: string) => {
    try {
      setLoading(true);
      const result = await textService.listPaged({
        pagination: { skip: 0, limit: itemsPerPage },
        sort: { field: 'dataCriacao', order: 'DESC' },
        query: search,
        exactMatch: exactMatch.current,
      });

      if (result) {
        setTexts(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitch = useCallback(() => {
    exactMatch.current = !exactMatch.current;
  }, []);

  const searchItems = useCallback(() => {
    const search = searchText.current?.value;
    if (search == null || search.length < 3) {
      return;
    }
    fetchData(search);
  }, []);

  return (
    <>
      <div style={{ display: 'flex', width: '100%', marginBottom: '1.6rem' }}>
        <Input
          name="search"
          icon={<MagnifyingGlassIcon />}
          ref={searchText}
          placeholder="Procurar por texto"
          fullWidth
        />
        <Button kind="secondary" onClick={searchItems} style={{ marginLeft: '1.6rem' }}>
          Pesquisar
        </Button>
      </div>
      <Switch onCheckedChange={handleSwitch} label="Correspondência exata?" name={'test'}></Switch>
      {!loading ? (
        texts?.length ? (
          <ul className={`list ${type}`}>
            {texts
              // .filter((text) => text.nome?.toLowerCase().includes(search))
              .map((text) => (
                <li key={text.idTexto} onClick={setBpmnText(text.idTexto as string, text.nome as string)}>
                  <div className="info name">
                    <div className="name">NOME DO TEXTO</div>
                    <div className="value">{text.nome}</div>
                  </div>
                  {type !== 'modal' && (
                    <>
                      <div className="info created-by">
                        <div className="name">CRIADO POR</div>
                        <div className="value">{text.usuarioCriacao}</div>
                      </div>
                      <div className="info created-at">
                        <div className="name">DATA DA CRIAÇÃO</div>
                        <div className="value">{formatDate(text.dataCriacao as string)}</div>
                      </div>
                    </>
                  )}
                </li>
              ))}
          </ul>
        ) : (
          <div style={{ width: '100%', textAlign: 'center', margin: '1.6rem auto' }}>
            <Paragraph size="medium" className="type">
              A busca não retornou resultados.
            </Paragraph>
          </div>
        )
      ) : (
        <div style={{ margin: '1.6rem auto' }}>
          <Loading />
        </div>
      )}
    </>
  );
};

const SearchText = ({ title, type, setBpmnText, newTextAction }: SearchTextProps) => {
  return type === 'modal' ? (
    <StyledSearch className={type}>
      <SearchTextContent type={type} setBpmnText={setBpmnText} />
      <Button kind="secondary" onClick={newTextAction}>
        Novo texto
      </Button>
    </StyledSearch>
  ) : (
    <StyledSearch className={type}>
      <Box>
        <div className="suspense-header">
          <Heading>{title}</Heading>
          <Button kind="secondary" onClick={newTextAction}>
            Novo texto
          </Button>
        </div>
        <SearchTextContent type={type} setBpmnText={setBpmnText} />
      </Box>
    </StyledSearch>
  );
};

export default SearchText;
