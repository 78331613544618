import Dropzone from 'react-dropzone';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import Paragraph from '../Text/Paragraph';
import { StyledFileUploader, StyledBackgroundImage } from './styles';
import { useCallback, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import Button from '../Form/Button';
import React from 'react';
import { convertToBase64 } from '../../pages/Documents/utils';
import parse from 'html-react-parser';
import Heading from '../Text/Heading';

interface FileUploaderProps {
  name: string;
  title?: string;
  multiple?: boolean;
  thumbs?: boolean;
  imageAsBackground?: boolean;
  onChange: () => void;
  onBlur: () => void;
  setValue: UseFormSetValue<FieldValues>;
  defaultImages?: string[];
}

const FileUploader = React.forwardRef<HTMLInputElement, FileUploaderProps>(
  (
    {
      name,
      title = '',
      multiple = true,
      thumbs = true,
      imageAsBackground = false,
      onChange,
      onBlur,
      setValue,
      defaultImages = [],
    },
    ref
  ) => {
    const [images, setImages] = useState<string[]>(defaultImages);

    const onDropFile = useCallback(async (acceptedFiles: File[]) => {
      const files = [];
      for (const file of acceptedFiles) {
        const convertedFile = await convertToBase64(file);
        files.push(imageAsBackground ? (convertedFile as string) : `<img alt="${file.name}" src="${convertedFile}"/>`);
      }
      setImages(files);
      setValue(name, files);
    }, []);

    const removeFile = useCallback(
      (file: string) => () => {
        const newFiles = [...images];
        newFiles.splice(newFiles.indexOf(file), 1);
        setImages(newFiles);
        setValue(name, newFiles);
      },
      [images]
    );

    return (
      <Dropzone
        noClick
        multiple={multiple}
        onDrop={onDropFile}
        accept={{
          'image/png': ['.png'],
          'image/jpeg': ['.jpeg', '.jpg'],
        }}>
        {({ getRootProps, getInputProps, open }) => (
          <StyledFileUploader className={`${imageAsBackground ? ' image-as-background' : ''}`}>
            <div className="dropzone" data-testid="drop-input" {...getRootProps()}>
              <input
                ref={ref}
                {...getInputProps({
                  id: name,
                  onChange,
                  onBlur,
                })}
              />

              {imageAsBackground && images[0] ? (
                <StyledBackgroundImage background={images[0]} onClick={open} />
              ) : (
                <>
                  {title && <Heading size="medium">{title}</Heading>}
                  <Paragraph>
                    Arraste as imagens ou <a onClick={open}>clique aqui</a> para fazer upload
                  </Paragraph>
                  <Paragraph size="small">
                    O formato do arquivo deve ser uma imagem .PNG ou .JPG e ter até 2mb
                  </Paragraph>
                </>
              )}
            </div>
            {thumbs && (
              <ul className="thumbs">
                {images.map((image) => {
                  const altPattern = /<img.*?alt="(.*?)".*?>/i;
                  const altMatch = image.match(altPattern);
                  const key = altMatch ? altMatch[1] : '';
                  return (
                    <li key={key}>
                      <div>
                        {parse(image)}
                        <Paragraph size="small">{key}</Paragraph>
                      </div>
                      <Button type="button" kind="outline" onClick={removeFile(image)}>
                        <TrashIcon />
                      </Button>
                    </li>
                  );
                })}
              </ul>
            )}
          </StyledFileUploader>
        )}
      </Dropzone>
    );
  }
);

FileUploader.displayName = 'FileUploader';
export default FileUploader;
