/* eslint-disable react/jsx-no-bind */
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { VariableFormValues } from '../../types/Variable.types';
import FormGroup from '../../components/Form/FormGroup';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import Box from '../../components/Box';
import Heading from '../../components/Text/Heading';
import { StyledForm, VariableFormContainer } from './styles';
import { useCallback, useEffect, useState } from 'react';
import { DialogCancelCreateUpdate } from '../shared/DialogCancelCreateUpdate';
import { SwitchForm } from '../../components/Form/Switch';
import { OptionType, Select } from '../../components/Form/Select';
import Paragraph from '../../components/Text/Paragraph';
import { CreatableSelect } from '../../components/Form/Select/CreatableSelect';
import { variableService } from '../../api/services';
import { DialogConfirmAction } from '../shared/DialogConfirmAction';
import { multipleChoiceTypeName, suspenseListTypeName } from './utils';

interface VariableFormProps {
  type: 'create' | 'update' | 'view' | 'duplicate';
  boxType?: 'page' | 'suspense-page';
  initialData?: VariableFormValues;
  onSubmit: (data: VariableFormValues) => void;
  onCancel: () => void;
}

const validationSchema = yup.object({
  name: yup.mixed().when('myLegalIntegration', {
    is: (myLegalIntegration: boolean) => myLegalIntegration === false,
    then: yup.string().required('O campo nome é obrigatório'),
  }),
  options: yup.array().when('variableType', {
    is: (variableType: OptionType) => {
      return (
        (variableType && variableType.label === multipleChoiceTypeName) || variableType.label === suspenseListTypeName
      );
    },
    then: yup.array().min(1, 'É necessário pelo menos uma opção.'),
  }),
  variableType: yup.object().shape({
    label: yup.string().required('O campo tipo de dado é obrigatório'),
    value: yup.string().required(),
  }),
});

const VariableForm = ({ type, boxType = 'page', initialData, onSubmit, onCancel }: VariableFormProps) => {
  const {
    handleSubmit,
    control,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm<VariableFormValues>({
    defaultValues: initialData,
    resolver: yupResolver(validationSchema),
  });
  const { fields } = useFieldArray({
    name: 'relationData',
    control,
  });
  const watchVariableType = watch('variableType');
  const watchMyLegalIntegration = watch('myLegalIntegration');

  const title = {
    create: 'Cadastro de variável',
    duplicate: 'Duplicar variável',
    update: 'Editar variável',
    view: 'Visualizar variável',
  };

  const [variableTypes, setVariableTypes] = useState<OptionType[]>([]);
  useEffect(() => {
    async function getVariableTypes() {
      const result = await variableService.getTypes();
      if (result) {
        setVariableTypes(
          result.map((type) => ({ label: type.descricao as string, value: type.idTipoVariavel as string }))
        );
      }
    }
    getVariableTypes();
  }, []);

  const [options, setOptions] = useState<readonly OptionType[]>(
    initialData?.options ? (initialData.options as OptionType[]) : []
  );
  useEffect(() => {
    setValue('options', options);
  }, [options]);

  useEffect(() => {
    setValue('name', '');
  }, [watchMyLegalIntegration]);

  const renderSelectVariableTypes = useCallback(
    ({ field }: any) => (
      <Select
        {...field}
        placeholder="Selecione um tipo"
        options={variableTypes}
        disabled={type === 'view' ? true : false}
      />
    ),
    [variableTypes]
  );

  const getInputDefaultValue = useCallback(() => {
    if (!watchVariableType) return;
    const disabled = type === 'view' ? true : false;
    switch (watchVariableType.label) {
      case 'Lista suspensa':
      case 'Multipla escolha':
        return (
          <Box>
            <FormGroup error={errors?.options?.message}>
              <Paragraph className="label">Opções</Paragraph>
              <CreatableSelect
                disabled={disabled}
                placeholder="Digite os valores e aperte Enter"
                options={options}
                setOptions={setOptions}
              />
            </FormGroup>
          </Box>
        );
    }
  }, [watchVariableType, options, errors]);

  const getRelationVariableValue = useCallback(() => {
    if (initialData?.relationData && initialData?.relationData.length > 0) {
      return initialData?.relationType == 'Principal'
        ? initialData.relationData[0].dependentVariableName
        : initialData.relationData[0].principalVariableName;
    } else {
      return '-';
    }
  }, []);
  const relationVariableValue = getRelationVariableValue();

  useEffect(() => {
    if (type == 'create' || watchVariableType?.label != initialData?.variableType?.label) {
      setValue('options', []);
      setOptions([]);
    }
  }, [watchVariableType]);

  useEffect(() => {
    setValue('name', initialData?.name);
  }, [initialData]);

  const renderSelectMyLegalIntegration = useCallback(
    ({ field }: any) => (
      <Select
        placeholder="Selecione o valor da variável do MyLegal"
        {...field}
        options={[
          { label: 'comarca', value: 'comarca' },
          { label: 'uf', value: 'uf' },
          { label: 'numeroProcesso', value: 'numeroProcesso' },
          { label: 'ficha', value: 'ficha' },
          { label: 'acao', value: 'acao' },
          { label: 'pasta', value: 'pasta' },
          { label: 'numero', value: 'numero' },
          { label: 'orgao', value: 'orgao' },
          { label: 'autor', value: 'autor' },
          { label: 'documentoAutor', value: 'documentoAutor' },
          { label: 'reu', value: 'reu' },
          { label: 'documentoReu', value: 'documentoReu' },
          { label: 'numeroIntegracao', value: 'numeroIntegracao' },
          { label: 'valorCausa', value: 'valorCausa' },
          { label: 'motivo', value: 'motivo' },
          { label: 'subMotivo', value: 'subMotivo' },
          { label: 'ojSigla', value: 'ojSigla' },
        ]}
      />
    ),
    []
  );

  const beforeSubmit = useCallback((data: VariableFormValues) => {
    const returnedData = { ...data, name: typeof data.name === 'object' ? data.name.value : data.name };
    onSubmit(returnedData);
  }, []);

  const getVariableName = useCallback(
    (name: string | OptionType | undefined) => {
      if (typeof name === 'object') return name.label;
      return name;
    },
    [initialData]
  );

  return (
    <VariableFormContainer>
      <StyledForm>
        <Box
          type={boxType}
          footer={
            type === 'create' || type === 'update' || type === 'duplicate' ? (
              <>
                <DialogCancelCreateUpdate type={type} navigateToListPage={onCancel} />

                {type === 'update' && initialData?.relationType !== 'Comum' ? (
                  <DialogConfirmAction
                    type="update"
                    trigger={<Button className="submit-button">Salvar</Button>}
                    title="Confirmar alteração?"
                    text={`Tem certeza que deseja alterar a variável ${getVariableName(
                      initialData?.name
                    )}? Esta variável está vinculada à variável ${relationVariableValue}. Deseja continuar com a alteração?`}
                    onConfirm={handleSubmit(onSubmit)}
                  />
                ) : (
                  <Button className="submit-button" onClick={handleSubmit(beforeSubmit)}>
                    Salvar
                  </Button>
                )}
              </>
            ) : (
              <Button className="cancel-button" kind="alert" onClick={onCancel}>
                Voltar
              </Button>
            )
          }>
          <div className="content">
            <Heading>{title[type]}</Heading>
            <div className="switch">
              <SwitchForm
                name="myLegalIntegration"
                label="Integração MyLegal"
                position="right"
                control={control}
                disabled={type === 'view' ? true : false}
              />
            </div>
            <Box grid={2} border={false} padding={false}>
              {watchMyLegalIntegration === true ? (
                <FormGroup>
                  <Paragraph className="label">Nome da variável</Paragraph>
                  <Controller name="name" control={control} render={renderSelectMyLegalIntegration} />
                </FormGroup>
              ) : (
                <FormGroup error={errors.name?.message}>
                  <Input {...register('name')} label="Nome da variável" disabled={type === 'view' ? true : false} />
                </FormGroup>
              )}

              <FormGroup error={errors.description?.message}>
                <Input {...register('description')} label="Descrição" disabled={type === 'view' ? true : false} />
              </FormGroup>
            </Box>

            <Heading className="subheader">Tipos de dados</Heading>
            {variableTypes.length > 0 && (
              <FormGroup error={errors.variableType?.label?.message}>
                <Paragraph className="label">Tipo de dado</Paragraph>
                <Controller name="variableType" control={control} render={renderSelectVariableTypes} />
              </FormGroup>
            )}

            <div className="default-value">{getInputDefaultValue()}</div>

            <div className="switch">
              <SwitchForm
                name="required"
                label="Variável obrigatória"
                position="right"
                control={control}
                disabled={type === 'view' ? true : false}
              />
            </div>
          </div>
        </Box>

        {type === 'view' && ['Principal', 'Dependente'].includes(initialData?.relationType as string) ? (
          <Box className="values-container">
            <FormGroup>
              <div>
                <Heading size="medium">Dados de variável dinâmica</Heading>
                <FormGroup>
                  <Input
                    name="relationVariableName"
                    label={initialData?.relationType == 'Principal' ? 'Variável dependente' : 'Variável principal'}
                    value={relationVariableValue}
                    disabled={true}
                  />
                </FormGroup>

                {fields.map((field, index) => {
                  return (
                    <Box className="value-container" key={field.id}>
                      <section key={field.id}>
                        <FormGroup>
                          <Paragraph className="label">Dados da variável principal</Paragraph>
                          <Select
                            options={[{ label: field.principalVariableValue, value: field.principalVariableId }]}
                            {...field}
                            selectedOption={
                              { label: field.principalVariableValue, value: field.principalVariableId } as OptionType
                            }
                            name={`relationData.${index}.principalVariableValue`}
                            disabled={true}
                          />
                        </FormGroup>
                        <div className="value-separator">
                          <Paragraph size="small">Vincular à</Paragraph>
                        </div>
                        <FormGroup>
                          <Paragraph className="label">Dados da variável dependente</Paragraph>
                          <Select
                            options={[{ label: field.dependentVariableValue, value: field.dependentVariableId }]}
                            {...field}
                            selectedOption={
                              { label: field.dependentVariableValue, value: field.dependentVariableId } as OptionType
                            }
                            name={`relationData.${index}.dependentVariableValue`}
                            disabled={true}
                          />
                        </FormGroup>
                      </section>
                    </Box>
                  );
                })}
              </div>
            </FormGroup>
          </Box>
        ) : null}
      </StyledForm>
    </VariableFormContainer>
  );
};

export default VariableForm;
