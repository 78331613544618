import { TemplateData, TemplateDataCreateRequest, TemplateDataDeleteResponse } from '../../types/Template.types';
import ApiClient, { IApiClient } from '../ApiClient';

interface ITemplateApiClient {
  create(data: TemplateDataCreateRequest): Promise<TemplateData | undefined>;
  list(): Promise<TemplateData[] | undefined>;
  getById(id: string): Promise<TemplateData | undefined>;
  update(id: string, data: TemplateDataCreateRequest): Promise<TemplateData | undefined>;
  delete(id: string): Promise<TemplateDataDeleteResponse | undefined>;
}

export class TemplateApiClient implements ITemplateApiClient {
  templateApiClient: IApiClient;

  constructor(TemplateApiClient: IApiClient) {
    this.templateApiClient = TemplateApiClient;
  }

  async create(data: TemplateDataCreateRequest): Promise<TemplateData | undefined> {
    const response = await this.templateApiClient.post<TemplateDataCreateRequest, TemplateData>(`/templates`, data);
    return response;
  }

  async list(): Promise<TemplateData[] | undefined> {
    const response = await this.templateApiClient.get<TemplateData[]>(`/templates`);
    return response;
  }

  async getById(id: string): Promise<TemplateData | undefined> {
    const response = await this.templateApiClient.get<TemplateData>(`/templates/${id}`);
    return response;
  }

  async update(id: string, data: TemplateDataCreateRequest): Promise<TemplateData | undefined> {
    const response = await this.templateApiClient.put<TemplateDataCreateRequest, TemplateData>(
      `/templates/${id}`,
      data
    );
    return response;
  }

  async delete(id: string): Promise<TemplateDataDeleteResponse | undefined> {
    const response = await this.templateApiClient.delete<TemplateDataDeleteResponse>(`/templates/${id}`);
    return response;
  }
}

export default class TemplateService {
  templateApiClient: ITemplateApiClient;

  constructor() {
    this.templateApiClient = new TemplateApiClient(new ApiClient());
  }

  async create(data: TemplateDataCreateRequest): Promise<TemplateData | undefined> {
    return this.templateApiClient.create(data);
  }

  async list(): Promise<TemplateData[] | undefined> {
    return this.templateApiClient.list();
  }

  async getById(id: string): Promise<TemplateData | undefined> {
    return this.templateApiClient.getById(id);
  }

  async update(id: string, data: TemplateDataCreateRequest): Promise<TemplateData | undefined> {
    return this.templateApiClient.update(id, data);
  }

  async delete(id: string): Promise<TemplateDataDeleteResponse | undefined> {
    return this.templateApiClient.delete(id);
  }
}
