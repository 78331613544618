import styled from 'styled-components';
import { theme } from '../../styles/themes/default';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const View = styled.div`
  width: 100%;
  height: 60rem;
  cursor: grab;
  border: 0.1rem solid ${theme.colors.grey[200]};
  border-radius: 0.4rem;
  position: relative;

  .group[data-group='create'] {
    display: none;
  }

  .entry[data-action='append'] {
    display: none;
  }

  .djs-overlay-drilldown {
    display: none;
  }

  .djs-bendpoint.floating,
  .djs-segment-dragger {
    display: none !important;
  }

  &.fullscreen {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    background: ${theme.colors.neutral.white};
  }
`;

export const ContentInfo = styled.div`
  width: 100%;
  padding: ${theme.spacings[2]};
  .type {
    margin-bottom: ${theme.spacings[0.5]};
    color: ${theme.colors.grey[400]};
  }
`;

export const Toolbar = styled.div`
  padding: ${theme.spacings[2]};
  background: ${theme.colors.neutral.white};
  border: 0.1rem solid ${theme.colors.grey[200]};
  box-shadow: 0 0.8rem 2.4rem -1.2rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 300;
  .zoom {
    display: flex;
    flex-direction: row;
    border: 0.1rem solid ${theme.colors.grey[200]};
    border-radius: 0.4rem;
    .zoom-in,
    .current-zoom,
    .zoom-out {
      padding: ${theme.spacings[1]};
    }
    .zoom-in,
    .zoom-out {
      border: none;
      background: ${theme.colors.neutral.white};
      cursor: pointer;
      &:hover {
        background: ${theme.colors.grey[100]};
      }
    }
    .current-zoom {
      font-size: ${theme.fonts['x-small']};
      line-height: ${theme.fonts['medium']};
      border: 0.1rem solid ${theme.colors.grey[200]};
      border-top: none;
      border-bottom: none;
      padding: ${theme.spacings[1]} ${theme.spacings[2]};
      color: ${theme.colors.grey[700]};
    }
  }
  .button {
    border: 0.1rem solid ${theme.colors.grey[200]};
    border-radius: 0.4rem;
    padding: ${theme.spacings[1]};
    margin-left: ${theme.spacings[2]};
    background: ${theme.colors.neutral.white};
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      background: ${theme.colors.grey[100]};
    }
  }
  svg {
    height: 2rem;
  }
`;
