import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Container, Toolbar, View } from './styles';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-font/dist/css/bpmn-embedded.css';
import { bpmnJS, bpmnProcessId } from './Bpmn';
import { ConditionOptionBPMN } from '../../types/Condition.types';
import TextContent from './TextContent';
import ModelContent from './ModelContent';
import ConditionContent from './ConditionContent';
import { MinusSmallIcon } from '@heroicons/react/24/outline';
import { ArrowsPointingInIcon, ArrowsPointingOutIcon, PlusIcon } from '@heroicons/react/24/solid';

interface BpmnEditorProps {
  initialBpmn: string;
  configuredModeler: any;
  setConfiguredModeler: Dispatch<SetStateAction<any>>;
}

interface TextElement {
  name: string;
  $attrs: {
    textId: string;
    fullName: string;
  };
}

interface ModelElement {
  name: string;
  $attrs: {
    modelId: string;
    fullName: string;
  };
}

interface ConditionElement {
  name: string;
  $attrs: {
    conditionId: string;
  };
}

const BpmnEditor = ({ initialBpmn, configuredModeler, setConfiguredModeler }: BpmnEditorProps) => {
  let modeler: any = null;

  const [openedNewTextPage, setOpenedNewTextPage] = useState(false);
  const [openedAddTextModal, setOpenedAddTextModal] = useState(false);
  const [openedAddTextPage, setOpenedAddTextPage] = useState(false);
  const [openedReplaceTextPage, setOpenedReplaceTextPage] = useState(false);
  const [openedUpdateTextPage, setOpenedUpdateTextPage] = useState(false);
  const [openedTextOptionsModal, setOpenedTextOptionsModal] = useState(false);
  const [currentText, setCurrentText] = useState({ textId: '', name: '', fullName: '' });
  const [addTextType, setAddTextType] = useState('');

  const [openedAddModelModal, setOpenedAddModelModal] = useState(false);
  const [openedAddModelPage, setOpenedAddModelPage] = useState(false);
  const [openedReplaceModelPage, setOpenedReplaceModelPage] = useState(false);
  const [openedModelOptionsModal, setOpenedModelOptionsModal] = useState(false);
  const [currentModel, setCurrentModel] = useState({ modelId: '', name: '', fullName: '' });

  const [openedNewConditionPage, setOpenedNewConditionPage] = useState(false);
  const [openedAddConditionModal, setOpenedAddConditionModal] = useState(false);
  const [openedReplaceConditionPage, setOpenedReplaceConditionPage] = useState(false);
  const [openedAddConditionPage, setOpenedAddConditionPage] = useState(false);
  const [openedUpdateConditionPage, setOpenedUpdateConditionPage] = useState(false);
  const [openedConditionOptionsModal, setOpenedConditionOptionsModal] = useState(false);
  const [openedAssociationConditionPage, setOpenedAssociationConditionPage] = useState(false);
  const [currentCondition, setCurrentCondition] = useState({ conditionId: '', name: '' });
  const [addConditionType, setAddConditionType] = useState('');

  const [currentElement, setCurrentElement] = useState<any>({});

  const [currentZoom, setCurrentZoom] = useState(6);
  const [fullScreen, setFullScreen] = useState(false);

  const openBpmnDiagram = async (xml: string) => {
    await modeler.importXML(xml);
    configureListeners();
  };

  const configureListeners = useCallback(() => {
    const eventBus = modeler.get('eventBus');

    eventBus.on('shape.added', (e: any) => {
      if (e.element.type === 'bpmn:Task' && !e.element.dinamycallyCreated) {
        setCurrentElement(e.element);
        openTextContent('addTextModal')();
      }
      if (e.element.type === 'bpmn:SubProcess') {
        setCurrentElement(e.element);
        openModelContent('addModelModal')();
      }
      if (e.element.type === 'bpmn:ExclusiveGateway') {
        setCurrentElement(e.element);
        openConditionContent('addConditionModal')();
      }
    });

    eventBus.on('element.dblclick', (e: any) => {
      if (e.element.type === 'bpmn:Task') {
        const elementData: TextElement = e.element.businessObject;
        if (!elementData.name || !elementData.$attrs.textId) {
          setCurrentElement(e.element);
          openTextContent('addTextModal')();
        } else {
          setCurrentText({
            name: elementData.name,
            textId: elementData.$attrs.textId,
            fullName: elementData.$attrs.fullName,
          });
          setCurrentElement(e.element);
          openTextContent('textOptionsModal')();
        }
      }
      if (e.element.type === 'bpmn:SubProcess') {
        const elementData: ModelElement = e.element.businessObject;
        if (!elementData.name || !elementData.$attrs.modelId) {
          setCurrentElement(e.element);
          openModelContent('addModelModal')();
        } else {
          setCurrentModel({
            name: elementData.name,
            modelId: elementData.$attrs.modelId,
            fullName: elementData.$attrs.fullName,
          });
          setCurrentElement(e.element);
          openModelContent('modelOptionsModal')();
        }
      }
      if (e.element.type === 'bpmn:ExclusiveGateway') {
        const elementData: ConditionElement = e.element.businessObject;
        if (!elementData.name || !elementData.$attrs.conditionId) {
          setCurrentElement(e.element);
          openConditionContent('addConditionPage')();
        } else {
          setCurrentCondition({ name: elementData.name, conditionId: elementData.$attrs.conditionId });
          setCurrentElement(e.element);
          openConditionContent('conditionOptionsModal')();
        }
      }
    });
  }, []);

  useEffect(() => {
    const containerEl = document.getElementById('bpmnview');
    modeler = bpmnJS(containerEl);
    setConfiguredModeler(modeler);
    openBpmnDiagram(initialBpmn);

    return () => {
      modeler.destroy();
    };
  }, []);

  const zoomIn = useCallback(() => {
    let newZoom = currentZoom;
    newZoom = currentZoom < 10 ? newZoom + 1 : currentZoom;
    setCurrentZoom(() => newZoom);
    configuredModeler.get('zoomScroll').stepZoom(1);
  }, [configuredModeler, currentZoom]);

  const zoomOut = useCallback(() => {
    let newZoom = currentZoom;
    newZoom = currentZoom > 0 ? newZoom - 1 : currentZoom;
    setCurrentZoom(() => newZoom);
    configuredModeler.get('zoomScroll').stepZoom(-1);
  }, [configuredModeler, currentZoom]);

  const fitViewport = useCallback(() => {
    configuredModeler.get('canvas').zoom('fit-viewport', 'auto');
  }, [configuredModeler]);

  const switchFullScreen = useCallback(() => {
    setFullScreen((fullScreen) => !fullScreen);
  }, [fullScreen]);

  const getShortName = useCallback((name: string) => {
    const maxLength = 40;
    return name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
  }, []);

  /* TEXT */
  const setBpmnText = useCallback(
    (textId: string, name: string) => () => {
      const modeling = configuredModeler.get('modeling');
      modeling.updateProperties(currentElement, {
        textId: textId,
        name: getShortName(name),
        fullName: name,
      });
      closeTextContent();
    },
    [currentElement]
  );

  const closeTextContent = useCallback(() => {
    setOpenedNewTextPage(false);
    setOpenedAddTextModal(false);
    setOpenedAddTextPage(false);
    setOpenedReplaceTextPage(false);
    setOpenedUpdateTextPage(false);
    setOpenedTextOptionsModal(false);
  }, []);

  const openTextContent = useCallback(
    (content: string, type?: string) => () => {
      closeTextContent();
      if (content === 'newTextPage') {
        setOpenedNewTextPage(true);
        setAddTextType(type as string);
      }
      if (content === 'addTextModal') setOpenedAddTextModal(true);
      if (content === 'addTextPage') setOpenedAddTextPage(true);
      if (content === 'replaceTextPage') setOpenedReplaceTextPage(true);
      if (content === 'updateTextPage') setOpenedUpdateTextPage(true);
      if (content === 'textOptionsModal') setOpenedTextOptionsModal(true);
    },
    []
  );

  const closeNewTextPage = useCallback(() => {
    setOpenedNewTextPage(false);
    if (addTextType === 'modal') setOpenedAddTextModal(true);
    if (addTextType === 'page') setOpenedAddTextPage(true);
  }, [addTextType]);

  /* MODEL */
  const setBpmnModel = useCallback(
    (modelId: string, name: string) => () => {
      const modeling = configuredModeler.get('modeling');
      modeling.updateProperties(currentElement, {
        modelId: modelId,
        name: getShortName(name),
        fullName: name,
      });
      closeModelContent();
    },
    [currentElement]
  );

  const closeModelContent = useCallback(() => {
    setOpenedAddModelModal(false);
    setOpenedAddModelPage(false);
    setOpenedReplaceModelPage(false);
    setOpenedModelOptionsModal(false);
  }, []);

  const openModelContent = useCallback(
    (content: string) => () => {
      closeModelContent();
      if (content === 'addModelModal') setOpenedAddModelModal(true);
      if (content === 'addModelPage') setOpenedAddModelPage(true);
      if (content === 'replaceModelPage') setOpenedReplaceModelPage(true);
      if (content === 'modelOptionsModal') setOpenedModelOptionsModal(true);
    },
    []
  );

  /* CONDITION */
  const setBpmnCondition = useCallback(
    (conditionId: string, name: string, options: ConditionOptionBPMN[]) => () => {
      const modeling = configuredModeler.get('modeling');
      const elementRegistry = configuredModeler.get('elementRegistry');
      const elementFactory = configuredModeler.get('elementFactory');
      const bpmnFactory = configuredModeler.get('bpmnFactory');

      const elementData = currentElement.businessObject;
      if (elementData.outgoing) {
        const outgoing = elementData.outgoing;
        if (outgoing && outgoing.length > 0) {
          const elementsToRemove: any = [];
          outgoing.forEach((element: any) => {
            elementsToRemove.push(elementRegistry.get(element.id));
            elementsToRemove.push(elementRegistry.get(element.targetRef.id));
          });
          modeling.removeElements(elementsToRemove);
        }
      }

      modeling.updateProperties(currentElement, {
        conditionId: conditionId,
        name: name,
      });

      const process = elementRegistry.get(bpmnProcessId);
      let x = currentElement.x + 200;
      let y = currentElement.y + 25;

      options.forEach((option) => {
        const participant = elementFactory.createParticipantShape({ type: 'bpmn:Task', dinamycallyCreated: true });
        modeling.createShape(participant, { x, y }, process);
        const businessObject = bpmnFactory.create('bpmn:SequenceFlow', {
          conditionOptionId: option.conditionId,
          name: option.conditionValue,
        });
        modeling.createConnection(
          currentElement,
          participant,
          {
            type: 'bpmn:SequenceFlow',
            businessObject: businessObject,
          },
          process
        );
        x += 50;
        y += 100;
      });
      closeConditionContent();
    },
    [currentElement]
  );

  const closeConditionContent = useCallback(() => {
    setOpenedNewConditionPage(false);
    setOpenedAddConditionModal(false);
    setOpenedAddConditionPage(false);
    setOpenedReplaceConditionPage(false);
    setOpenedUpdateConditionPage(false);
    setOpenedConditionOptionsModal(false);
    setOpenedAssociationConditionPage(false);
  }, []);

  const openConditionContent = useCallback(
    (content: string, type?: string) => () => {
      closeConditionContent();
      if (content === 'newConditionPage') {
        setOpenedNewConditionPage(true);
        setAddConditionType(type as string);
      }
      if (content === 'addConditionModal') setOpenedAddConditionModal(true);
      if (content === 'addConditionPage') setOpenedAddConditionPage(true);
      if (content === 'replaceConditionPage') setOpenedReplaceConditionPage(true);
      if (content === 'updateConditionPage') setOpenedUpdateConditionPage(true);
      if (content === 'conditionOptionsModal') setOpenedConditionOptionsModal(true);
      if (content === 'associationConditionPage') setOpenedAssociationConditionPage(true);
    },
    []
  );

  const closeNewConditionPage = useCallback(() => {
    setOpenedNewConditionPage(false);
    if (addConditionType === 'modal') setOpenedAddConditionModal(true);
    if (addConditionType === 'page') setOpenedAddConditionPage(true);
  }, [addConditionType]);

  return (
    <>
      <Container id="bpmncontainer">
        <View id="bpmnview" className={fullScreen ? 'fullscreen' : ''}>
          <Toolbar>
            <div className="zoom">
              <button type="button" className="zoom-out" onClick={zoomOut}>
                <MinusSmallIcon />
              </button>
              <div className="current-zoom">{currentZoom * 10}%</div>
              <button type="button" className="zoom-in" onClick={zoomIn}>
                <PlusIcon />
              </button>
            </div>
            <button type="button" className="button" onClick={fitViewport}>
              <ArrowsPointingInIcon />
            </button>
            <button type="button" className="button" onClick={switchFullScreen}>
              <ArrowsPointingOutIcon />
            </button>
          </Toolbar>
        </View>

        <TextContent
          openedTextOptionsModal={openedTextOptionsModal}
          setOpenedTextOptionsModal={setOpenedTextOptionsModal}
          openTextContent={openTextContent}
          openedAddTextModal={openedAddTextModal}
          setOpenedAddTextModal={setOpenedAddTextModal}
          openedNewTextPage={openedNewTextPage}
          closeNewTextPage={closeNewTextPage}
          closeTextContent={closeTextContent}
          openedAddTextPage={openedAddTextPage}
          openedReplaceTextPage={openedReplaceTextPage}
          openedUpdateTextPage={openedUpdateTextPage}
          currentText={currentText}
          setBpmnText={setBpmnText}
        />

        <ModelContent
          openedModelOptionsModal={openedModelOptionsModal}
          setOpenedModelOptionsModal={setOpenedModelOptionsModal}
          openedAddModelModal={openedAddModelModal}
          setOpenedAddModelModal={setOpenedAddModelModal}
          openModelContent={openModelContent}
          openedAddModelPage={openedAddModelPage}
          closeModelContent={closeModelContent}
          openedReplaceModelPage={openedReplaceModelPage}
          currentModel={currentModel}
          setBpmnModel={setBpmnModel}
        />

        <ConditionContent
          openedConditionOptionsModal={openedConditionOptionsModal}
          setOpenedConditionOptionsModal={setOpenedConditionOptionsModal}
          openConditionContent={openConditionContent}
          openedAddConditionModal={openedAddConditionModal}
          setOpenedAddConditionModal={setOpenedAddConditionModal}
          openedAssociationConditionPage={openedAssociationConditionPage}
          openedNewConditionPage={openedNewConditionPage}
          closeNewConditionPage={closeNewConditionPage}
          closeConditionContent={closeConditionContent}
          openedAddConditionPage={openedAddConditionPage}
          openedReplaceConditionPage={openedReplaceConditionPage}
          openedUpdateConditionPage={openedUpdateConditionPage}
          currentCondition={currentCondition}
          setBpmnCondition={setBpmnCondition}
        />
      </Container>
    </>
  );
};

export default BpmnEditor;
