import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFormValues, InitialTextForm } from '../../types/Text.types';
import FormGroup from '../../components/Form/FormGroup';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import Box from '../../components/Box';
import { StyledForm } from './styles';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { DialogCancelCreateUpdate } from '../shared/DialogCancelCreateUpdate';
import { sectionService } from '../../api/services';
import { OptionType, Select } from '../../components/Form/Select';
import Paragraph from '../../components/Text/Paragraph';
import { DialogConfirmAction } from '../shared/DialogConfirmAction';
import TextEditor from './TextEditor';
import Accordion from '../../components/Accordion';

interface TextFormProps {
  type: 'create' | 'update' | 'view' | 'duplicate';
  boxType?: 'page' | 'suspense-page';
  initialData?: InitialTextForm;
  onSubmit: (data: TextFormValues) => void;
  onCancel: () => void;
}

const validationSchema = yup.object({
  name: yup.string().required('O campo nome é obrigatório'),
});

const TextForm = ({ type, boxType = 'page', initialData, onSubmit, onCancel }: TextFormProps) => {
  const [sections, setSections] = useState<readonly OptionType[]>([]);
  const [currentTab, setCurrentTab] = useState(1);

  useEffect(() => {
    async function getSections() {
      const result = await sectionService.list();
      if (result) {
        setSections(
          result.map((section) => ({ label: section.tituloSecao as string, value: section.idSecao as string }))
        );
      }
    }

    getSections();
  }, []);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<TextFormValues>({
    defaultValues: {
      ...initialData,
      type: initialData?.type ? initialData.type : { label: 'Texto comum', value: '1' },
    },
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();
  const navigateToTexts = useCallback(() => {
    navigate('/texts');
  }, []);

  const renderSelectSection = useCallback(
    ({ field }: any) => (
      <Select
        disabled={type === 'view' ? true : false}
        placeholder="Selecione as seções associadas"
        {...field}
        isMulti
        options={sections}
      />
    ),
    [sections]
  );

  const renderSelectType = useCallback(
    ({ field }: any) => (
      <Select
        disabled={type === 'view' ? true : false}
        placeholder="Selecione o tipo do texto"
        {...field}
        options={[
          { label: 'Texto comum', value: '1' },
          { label: 'Nota de rodapé', value: '2' },
        ]}
      />
    ),
    []
  );

  const handleEditorChange = useCallback((content: string) => setValue('text', content), []);

  const setTab = useCallback(
    (tab: number) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      setCurrentTab(tab);
    },
    []
  );

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box
          type={boxType}
          className="box-info"
          padding={false}
          border={false}
          footer={
            type === 'view' ? (
              <Button className="cancel-button" kind="alert" onClick={onCancel}>
                Voltar
              </Button>
            ) : (
              <>
                <DialogCancelCreateUpdate type={type} navigateToListPage={navigateToTexts} />

                {type === 'update' ? (
                  <DialogConfirmAction
                    type="update"
                    trigger={<Button className="submit-button">Salvar</Button>}
                    title="Confirmar alteração?"
                    text={`Tem certeza que deseja alterar o texto ${initialData?.name}? Este texto pode estar relacionado a um ou mais modelos e sua alteração impactará em todos. Deseja continuar com a alteração?`}
                    onConfirm={handleSubmit(onSubmit)}
                  />
                ) : (
                  <Button className="submit-button" type="submit">
                    Salvar
                  </Button>
                )}
              </>
            )
          }>
          <Accordion
            content={
              currentTab === 1 ? (
                <div className="content">
                  <Box grid={2} border={false} padding={false}>
                    <FormGroup error={errors.type?.message}>
                      <Paragraph className="label">Tipo do texto</Paragraph>
                      <Controller name="type" control={control} render={renderSelectType} />
                    </FormGroup>
                    <FormGroup error={errors.name?.message}>
                      <Input
                        {...register('name')}
                        label="Nome do texto"
                        placeholder="Digite o nome do texto"
                        disabled={type === 'view' ? true : false}
                      />
                    </FormGroup>
                    <FormGroup error={errors.sections?.message}>
                      <Paragraph className="label">Seções</Paragraph>
                      <Controller name="sections" control={control} render={renderSelectSection} />
                    </FormGroup>
                  </Box>
                </div>
              ) : (
                <>
                  {initialData?.variables && initialData?.variables.length > 0 && (
                    <div className="info">
                      <Paragraph className="label">Variáveis aplicadas</Paragraph>
                      <ul>
                        {initialData?.variables?.map((variable) => (
                          <li key={variable.idVariavel}>{variable.nomeVariavel}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {initialData?.footnotes && initialData?.footnotes.length > 0 && (
                    <div className="info">
                      <Paragraph className="label">Notas de rodapé</Paragraph>
                      <ul>
                        {initialData?.footnotes?.map((footnote) => (
                          <li key={footnote.idTexto}>{footnote.nome}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
              )
            }>
            <div className="tabs">
              <button className={`tab${currentTab === 1 ? ' active' : ''}`} onClick={setTab(1)}>
                DADOS DO TEXTO
              </button>
              {(type === 'view' || type === 'update') && (
                <button className={`tab${currentTab === 2 ? ' active' : ''}`} onClick={setTab(2)}>
                  OUTRAS INFORMAÇÕES
                </button>
              )}
            </div>
          </Accordion>
        </Box>
        <div className="content-editor">
          <TextEditor
            onEditorChange={handleEditorChange}
            initialValue={initialData?.text as string}
            disabled={type === 'view' ? true : false}
          />
        </div>
      </StyledForm>
    </>
  );
};

export default TextForm;
