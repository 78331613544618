import * as DialogComponent from '@radix-ui/react-dialog';
import styled, { css, keyframes } from 'styled-components';

const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const DialogOverlay = styled(DialogComponent.Overlay)`
  position: fixed;
  inset: 0;
  animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  background: rgba(55, 65, 81, 0.5);
  backdrop-filter: blur(2.5px);
  z-index: 300;
`;

export const DialogContent = styled(DialogComponent.Content)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: ${theme.colors.neutral.white};
    border-radius: 0.6rem;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 45rem;
    max-height: 85vh;
    animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 400;
    padding: ${theme.spacings[3]};

    &.medium {
      max-width: 55rem;
    }

    &.has-title {
      padding: 0;
    }

    &:focus {
      outline: none;
    }

    .title {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.1rem solid ${theme.colors.grey[200]};
      width: 100%;
      padding: ${theme.spacings[1.5]} ${theme.spacings[2]};
      button {
        margin-right: ${theme.spacings[4]};
        color: ${theme.colors.grey[600]};
        font-size: ${theme.fonts['x-small']};
        font-weight: bold;
        outline: none;
      }
    }

    .close-button {
      position: absolute;
      border: none;
      background: none;
      top: 2.8rem;
      right: ${theme.spacings[3]};
      cursor: pointer;

      svg {
        height: 2rem;
        width: 2rem;
        stroke: ${theme.colors.grey[600]};
      }
    }
  `};
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button + button {
      margin-top: ${theme.spacings[1]};
    }

    &.row {
      padding: ${theme.spacings[4]} ${theme.spacings[3]};
      flex-direction: row-reverse;
      width: 100%;
      justify-content: flex-start;
      button + button {
        margin-top: 0;
        margin-right: ${theme.spacings[1]};
      }
    }
  `};
`;
