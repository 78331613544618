import Badge from '../../components/Text/Badge';
import { VariableFormValues } from '../../types/Variable.types';

export const multipleChoiceTypeName = 'Multipla escolha';
export const suspenseListTypeName = 'Lista suspensa';

export const getBadge = (variableType: string) => {
  switch (variableType) {
    case 'Principal':
      return <Badge color="blue">Principal</Badge>;
    case 'Dependente':
      return <Badge color="red">Dependente</Badge>;
    case 'Comum':
    default:
      return <Badge color="green">Comum</Badge>;
  }
};

export const getCreateUpdateData = (data: VariableFormValues) => {
  return {
    nomeVariavel: data.name as string,
    descricaoVariavel: data.description,
    idTipoVariavel: data.variableType.value,
    obrigatorio: data.required ? true : false,
    valorAuxiliar: data.options.map((option) => option.value).join('¨'),
    integracaoMyLegal: data.myLegalIntegration ? true : false,
  };
};
