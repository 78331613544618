import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextForm from './TextForm';
import { TextFormValues, InitialTextForm } from '../../types/Text.types';
import { textService } from '../../api/services';
import { getInitialType } from './utils';
import { OptionType } from '../../components/Form/Select';
import { showToast } from '../../components/Toast/showToast';

const DuplicateText = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState<InitialTextForm>({
    name: '',
    type: { label: 'Texto comum', value: '1' },
    sections: [],
    text: '',
    variables: [],
    footnotes: [],
  });

  const navigate = useNavigate();
  const navigateToListTexts = useCallback(() => {
    navigate('/texts');
  }, []);

  useEffect(() => {
    async function getInitialData() {
      const textData = await textService.getById(id as string);
      setInitialData({
        name: `Cópia - ${textData?.nome}`,
        type: getInitialType(textData?.tipo as string),
        sections: textData?.secoes.map(
          (section) => ({ label: section.tituloSecao, value: section.idSecao } as OptionType)
        ),
        text: textData?.valorTexto,
        variables: textData?.variaveis,
        footnotes: textData?.notasRodapes,
      });
      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: TextFormValues) => {
    const successfullyUpdated = await textService.create({
      nome: data.name,
      valor: data.text,
      tipo: parseInt(data.type?.value as string),
      secoes: data.sections?.map((section) => section.value as string),
    });
    if (successfullyUpdated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar texto.',
      });
      navigateToListTexts();
    }
  }, []);

  return !isLoading ? (
    <>
      <TextForm type="duplicate" initialData={initialData} onSubmit={handleSubmit} onCancel={navigateToListTexts} />
    </>
  ) : null;
};

export default DuplicateText;
