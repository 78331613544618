import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserFormValues } from '../../types/User.types';
import FormGroup from '../../components/Form/FormGroup';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import Box from '../../components/Box';
import Heading from '../../components/Text/Heading';
import { StyledForm } from './styles';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import Paragraph from '../../components/Text/Paragraph';
import { Select } from '../../components/Form/Select';
import { permissionsList } from './utils';

interface UserFormProps {
  type: 'create' | 'update';
  initialData?: UserFormValues;
  onSubmit: (data: UserFormValues) => void;
}

const validationSchema = yup.object({
  email: yup.string().required('O campo e-mail é obrigatório'),
});

const UserForm = ({ type, initialData, onSubmit }: UserFormProps) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<UserFormValues>({
    defaultValues: initialData,
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();
  const navigateToListUsers = useCallback(() => {
    navigate('/users');
  }, []);

  const title = {
    create: 'Cadastrar novo usuário',
    update: 'Editar usuário',
  };

  const renderSelectPermissions = useCallback(
    ({ field }: any) => (
      <>
        <Paragraph className="label">Escolha a quais seções o usuário poderá acessar</Paragraph>
        <Select placeholder="Escolha as seções" {...field} isMulti options={Object.values(permissionsList)} />
      </>
    ),
    []
  );

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box
          footer={
            <>
              <Button className="cancel-button" kind="alert" onClick={navigateToListUsers}>
                Voltar
              </Button>
              <Button className="submit-button" type="submit">
                Salvar
              </Button>
            </>
          }>
          <div className="content">
            <Heading>{title[type]}</Heading>
            <Box grid={2} padding={false} border={false}>
              <FormGroup error={errors.email?.message}>
                <Input {...register('email')} label="E-mail do usuário" />
              </FormGroup>
              <FormGroup error={errors.permission?.message}>
                <Controller name="permission" control={control} render={renderSelectPermissions} />
              </FormGroup>
            </Box>
          </div>
        </Box>
      </StyledForm>
    </>
  );
};

export default UserForm;
