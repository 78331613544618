import { Controller, useForm } from 'react-hook-form';
import { ConditionData, AssociationConditionFormValues, SingleConditionData } from '../../types/Condition.types';
import Button from '../../components/Form/Button';
import Box from '../../components/Box';
import { StyledForm } from './styles';
import { useCallback, useEffect } from 'react';
import Paragraph from '../../components/Text/Paragraph';
import { Select } from '../../components/Form/Select';
import Heading from '../../components/Text/Heading';

interface AssociationConditionFormProps {
  initialData?: AssociationConditionFormValues;
  principalCondition: SingleConditionData;
  conditions: ConditionData[];
  onSubmit: (data: AssociationConditionFormValues) => void;
  onCancel: () => void;
}

const AssociationConditionForm = ({
  initialData,
  principalCondition,
  conditions,
  onSubmit,
  onCancel,
}: AssociationConditionFormProps) => {
  const { control, handleSubmit, setValue, watch } = useForm<AssociationConditionFormValues>({
    defaultValues: initialData,
  });

  const vinculatedCondition = watch('vinculatedCondition');

  const renderConditionOptions = useCallback(({ field }: any) => {
    return (
      <>
        <Paragraph className="label">Escolha a condição associada</Paragraph>
        <Select
          placeholder="Selecione uma opção"
          {...field}
          options={conditions?.map((condition) => ({ label: condition.titulo, value: condition.idModeloCondicao }))}
        />
      </>
    );
  }, []);

  const renderCurrentConditionOptions = useCallback(({ field }: any) => {
    return (
      <>
        <Paragraph className="label">Informação padrão</Paragraph>
        <Select
          placeholder="Selecione uma opção"
          {...field}
          options={principalCondition?.condicaoOpcoes?.map((option) => ({
            label: option.descricao,
            value: option.idModeloCondicaoOpcao,
          }))}
        />
      </>
    );
  }, []);

  const renderVinculatedConditionOptions = useCallback(
    ({ field }: any) => {
      const condition = conditions?.find((condition) => condition.idModeloCondicao === vinculatedCondition?.value);
      return (
        <>
          <Paragraph className="label">Aplicar para</Paragraph>
          <Select
            placeholder="Selecione uma opção"
            {...field}
            options={condition?.condicaoOpcoes?.map((option) => ({
              label: option.descricao,
              value: option.idModeloCondicaoOpcao,
            }))}
          />
        </>
      );
    },
    [vinculatedCondition]
  );

  useEffect(() => {
    if (vinculatedCondition?.value !== initialData?.vinculatedCondition?.value) {
      setValue('vinculatedConditionOption', { label: '', value: '' });
    }
  }, [vinculatedCondition]);

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box
          type="suspense-page"
          footer={
            <>
              <Button className="cancel-button" type="button" kind="alert" onClick={onCancel}>
                Voltar
              </Button>
              <Button className="submit-button" type="submit">
                Salvar
              </Button>
            </>
          }>
          <div className="content">
            <Box border={false} padding={false}>
              <Heading>Associação de condição</Heading>
              <div className="association">
                <Controller name="vinculatedCondition" control={control} render={renderConditionOptions} />
                {vinculatedCondition && (
                  <Box className="vinculation" grid={2}>
                    <div>
                      <Controller
                        name="currentConditionOption"
                        control={control}
                        render={renderCurrentConditionOptions}
                      />
                    </div>
                    <div>
                      <Controller
                        name="vinculatedConditionOption"
                        control={control}
                        render={renderVinculatedConditionOptions}
                      />
                    </div>
                  </Box>
                )}
              </div>
            </Box>
          </div>
        </Box>
      </StyledForm>
    </>
  );
};

export default AssociationConditionForm;
