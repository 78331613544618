import { DocumentArrowDownIcon, EllipsisVerticalIcon, PencilIcon } from '@heroicons/react/24/outline';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { documentService } from '../../api/services';
import DropdownMenu from '../../components/DropdownMenu';
import { Content, Item, Separator } from '../../components/DropdownMenu/styles';
import { showToast } from '../../components/Toast/showToast';
import { DialogCancelDelete } from '../shared/DialogCancelDelete';
import appConfig from '../../appConfig';
import { useAppSelector } from '../../hooks/useStore';
import { getDecodedUserData } from '../../store/slices/user/utils';
import axios, { AxiosResponse } from 'axios';
import { DialogLog } from '../shared/DialogLog';

interface DocumentActionsProps {
  id: string;
  name: string;
  setDeletedItem: React.Dispatch<SetStateAction<string>>;
  finished: boolean;
}

interface DownloadDocxProps {
  id: string;
  tipo: string;
  docxLink: string;
}

const DocumentActions = ({ id, name, setDeletedItem, finished }: DocumentActionsProps) => {
  const [openedActions, setOpenedActions] = useState(false);
  const { userData } = useAppSelector((state) => state.user);
  const user = getDecodedUserData(userData as string);
  const permissaoExcluir = !(user?.permissions?.includes('documentos') && user?.permissions?.length == 1);

  const removeDocument = useCallback(
    (id: string) => async () => {
      const successfullyDeleted = await documentService.delete(id);
      if (successfullyDeleted) {
        showToast({
          type: 'success',
          text: 'Sucesso ao remover documento.',
        });
        setDeletedItem(id);
      }
      setOpenedActions(false);
    },
    []
  );

  const verificarExistenciaArquivo = async (id: string, type: string) => {
    try {
      let url: string;

      if (type === 'Word') {
        url = `${appConfig.docsApi}/${id}.docx`;
      } else {
        url = `${appConfig.docsApi}/${id}.pdf`;
      }

      const axiosConfig = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        },
        mode: 'cors',
      };

      const response: AxiosResponse<any> = await axios.head(url, axiosConfig);
      const isDocxAvailable = response.status === 200;
      const sleep = (ms: number | undefined) => new Promise((resolve) => setTimeout(resolve, ms));

      while (!isDocxAvailable) {
        await sleep(2000);
      }

      if (isDocxAvailable) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', `_blank`);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      }
    } catch (erro) {
      console.log(erro);
    }
  };

  const downloadDocument = useCallback(
    (id: string, type: string) => async () => {
      showToast({
        type: 'success',
        text: 'Por favor aguarde, o documento esta sendo preparado e o download irá começar em breve.',
      });
      await documentService.download(id, type);

      const link = document.createElement('a');
      if (type === 'Word') {
        link.href = `${appConfig.docsApi}/${id}.docx`;
      } else {
        link.href = `${appConfig.docsApi}/${id}.pdf`;
      }

      // link.setAttribute('target', `_blank`);
      link.setAttribute('download', `download`);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);

      /* Task 7578 - Mylegal Docs - Baixar Documento - ajuste método Async */
      //verificarExistenciaArquivo(id, type);
    },
    []
  );

  // const showLogModal = useCallback(() => {
  //   DialogLog();
  // }, []);

  const navigate = useNavigate();
  const navigateToUpdateDocument = useCallback(() => {
    navigate(`/documents/${id}/update`);
  }, []);

  return (
    <DropdownMenu
      open={openedActions}
      setOpen={setOpenedActions}
      content={
        <Content sideOffset={5}>
          <Item onClick={downloadDocument(id, 'Word')}>
            <DocumentArrowDownIcon /> <span>Baixar documento Word</span>
          </Item>

          {/* <Item onClick={downloadDocument(id, 'Pdf')}>
            <DocumentArrowDownIcon /> <span>Baixar documento PDF</span>
          </Item> */}

          <Separator />

          {!finished && (
            <>
              <Item onClick={navigateToUpdateDocument}>
                <PencilIcon /> <span>Continuar preenchimento</span>
              </Item>

              <Separator />
            </>
          )}

          {permissaoExcluir && (
            <>
              <DialogLog documentId={id} documentName={name} />
              <Separator />
              <DialogCancelDelete itemName={name} itemType="documento" removeItem={removeDocument(id)} />
            </>
          )}
        </Content>
      }>
      <EllipsisVerticalIcon className="actions-button" />
    </DropdownMenu>
  );
};

export default DocumentActions;
