import { EllipsisVerticalIcon, MagnifyingGlassPlusIcon, PencilIcon } from '@heroicons/react/24/outline';
import { SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { legalAreaService } from '../../api/services/';
import DropdownMenu from '../../components/DropdownMenu';
import { Content, Item, Separator } from '../../components/DropdownMenu/styles';
import { showToast } from '../../components/Toast/showToast';
import { DialogCancelDelete } from '../shared/DialogCancelDelete';

interface LegalAreaActionsProps {
  id: string;
  name: string;
  setDeletedItem: React.Dispatch<SetStateAction<string>>;
}

const LegalAreaActions = ({ id, name, setDeletedItem }: LegalAreaActionsProps) => {
  const [openedActions, setOpenedActions] = useState(false);

  const navigate = useNavigate();
  const navigateToViewLegalArea = useCallback(() => {
    navigate(`/legal-areas/${id}`);
  }, []);
  const navigateToUpdateLegalArea = useCallback(() => {
    navigate(`/legal-areas/${id}/update`);
  }, []);

  const removeLegalArea = useCallback(
    (id: string) => async () => {
      const successfullyDeleted = await legalAreaService.delete(id);
      if (successfullyDeleted) {
        showToast({
          type: 'success',
          text: 'Sucesso ao remover área jurídica.',
        });
        setDeletedItem(id);
      }
      setOpenedActions(false);
    },
    []
  );

  return (
    <DropdownMenu
      open={openedActions}
      setOpen={setOpenedActions}
      content={
        <Content sideOffset={5}>
          <Item onClick={navigateToViewLegalArea}>
            <MagnifyingGlassPlusIcon /> <span>Ver detalhes</span>
          </Item>

          <Separator />

          <Item onClick={navigateToUpdateLegalArea}>
            <PencilIcon /> <span>Editar área jurídica</span>
          </Item>

          <Separator />

          <DialogCancelDelete itemName={name} itemType="área jurídica" removeItem={removeLegalArea(id)} />
        </Content>
      }>
      <EllipsisVerticalIcon className="actions-button" />
    </DropdownMenu>
  );
};

export default LegalAreaActions;
