import { Dispatch, SetStateAction, useCallback } from 'react';
import { Container, CheckboxSelectInput, Check, Label } from './styles';

export interface CheckSelectProps {
  name?: string;
  label: string;
  isChecked: boolean;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
}

export const CheckSelect = (props: CheckSelectProps) => {
  const { name, label, isChecked, setIsChecked } = props;

  const handleOnChange = useCallback(() => setIsChecked((prev) => !prev), []);

  return (
    <Container>
      <Label htmlFor={name}>
        <CheckboxSelectInput id={name} onChange={handleOnChange} checked={isChecked} {...props} />
        <Check className="check" />
        <span>{label}</span>
      </Label>
    </Container>
  );
};
