import { useCallback } from 'react';
import TextForm from './TextForm';
import { TextFormValues } from '../../types/Text.types';
import { showToast } from '../../components/Toast/showToast';
import { textService } from '../../api/services';

interface SuspenseCreateTextProps {
  closeContent: () => void;
}

const SuspenseCreateText = ({ closeContent }: SuspenseCreateTextProps) => {
  const handleSubmit = useCallback(async (data: TextFormValues) => {
    const successfullyCreated = await textService.create({
      nome: data.name,
      valor: data.text,
      tipo: parseInt(data.type?.value as string),
      secoes: data.sections?.map((section) => section.value as string),
    });
    if (successfullyCreated) {
      showToast({
        type: 'success',
        text: 'Sucesso ao cadastrar texto.',
      });
      closeContent();
    }
  }, []);

  return (
    <>
      <TextForm type="create" boxType="suspense-page" onSubmit={handleSubmit} onCancel={closeContent} />
    </>
  );
};

export default SuspenseCreateText;
