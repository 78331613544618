import { useCallback, useEffect, useState } from 'react';
import TemplateForm from './TemplateForm';
import { TemplateFormValues, TemplateFullData, TemplateItemsFormValues } from '../../types/Template.types';
import { useNavigate, useParams } from 'react-router-dom';
import { templateService } from '../../api/services';
import { showToast } from '../../components/Toast/showToast';
import TemplateItemsForm from './TemplateItemsForm';
import { OptionType } from '../../components/Form/Select';

const UpdateTemplate = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState('initial');
  const [initialData, setInitialData] = useState({
    nomeTemplate: '',
    ativo: false,
    codigoCliente: '',
  });
  const [templateData, setTemplateData] = useState<TemplateFullData>();

  const navigate = useNavigate();
  const navigateToListTemplates = useCallback(() => {
    navigate('/templates');
  }, []);

  useEffect(() => {
    async function getInitialData() {
      const templateData = await templateService.getById(id as string);
      const config = JSON.parse(templateData?.valorConfiguracao as string);
      setTemplateData({
        name: templateData?.nomeTemplate,
        active: templateData?.ativo,
        client: { label: templateData?.codigoCliente as string, value: templateData?.codigoCliente as string },
        font: config?.fonte,
        font_size: config.tamanhoFonte,
        page_size: config.tamanhoPagina,
        margin: {
          top: config.margemSuperior,
          bottom: config.margemInferior,
          left: config.margemEsquerda,
          right: config.margemDireita,
        },
        header: [templateData?.valorCabecalho as string],
        topic: [templateData?.valorTopico as string],
        footer: [templateData?.valorRodape as string],
      });
      setIsLoading(false);
    }

    getInitialData();
  }, []);

  const handleSubmit = useCallback(async (data: TemplateFormValues) => {
    setInitialData({
      nomeTemplate: data.name as string,
      ativo: data.active as boolean,
      codigoCliente: data.client?.value as string,
    });
    setStep('items');
  }, []);

  const handleSubmitItems = useCallback(
    async (data: TemplateItemsFormValues) => {
      const successfullyUpdated = await templateService.update(id as string, {
        ...initialData,
        valorConfiguracao: JSON.stringify({
          fonte: data.font?.value,
          tamanhoFonte: data.font_size?.value,
          tamanhoPagina: data.page_size?.value,
          margemSuperior: data.margin.top,
          margemEsquerda: data.margin.left,
          margemDireita: data.margin.right,
          margemInferior: data.margin.bottom,
        }),
        valorCabecalho: data?.header![0],
        valorRodape: data?.footer![0],
        valorTopico: data?.topic![0],
      });
      if (successfullyUpdated) {
        showToast({
          type: 'success',
          text: 'Sucesso ao atualizar template.',
        });
        navigateToListTemplates();
      }
    },
    [initialData]
  );

  return !isLoading ? (
    <>
      {step === 'initial' && (
        <TemplateForm
          initialData={{
            name: templateData?.name,
            client: templateData?.client,
            active: templateData?.active,
          }}
          type="update"
          onSubmit={handleSubmit}
        />
      )}
      {step === 'items' && (
        <TemplateItemsForm
          initialData={{
            font: { label: templateData?.font, value: templateData?.font } as unknown as OptionType,
            font_size: { label: templateData?.font_size, value: templateData?.font_size } as unknown as OptionType,
            page_size: { label: templateData?.page_size, value: templateData?.page_size } as unknown as OptionType,
            margin: {
              top: templateData?.margin?.top,
              bottom: templateData?.margin?.bottom,
              left: templateData?.margin?.left,
              right: templateData?.margin?.right,
            },
            header: templateData?.header,
            topic: templateData?.topic,
            footer: templateData?.footer,
          }}
          onSubmit={handleSubmitItems}
        />
      )}
    </>
  ) : null;
};

export default UpdateTemplate;
