import {
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassPlusIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import { SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { textService } from '../../api/services';
import DropdownMenu from '../../components/DropdownMenu';
import { Content, Item, Separator } from '../../components/DropdownMenu/styles';
import { showToast } from '../../components/Toast/showToast';
import { DialogCancelDelete } from '../shared/DialogCancelDelete';

interface TextActionsProps {
  id: string;
  name: string;
  setDeletedItem: React.Dispatch<SetStateAction<string>>;
}

const TextActions = ({ id, name, setDeletedItem }: TextActionsProps) => {
  const [openedActions, setOpenedActions] = useState(false);

  const navigate = useNavigate();
  const navigateToViewText = useCallback(() => {
    navigate(`/texts/${id}`);
  }, []);
  const navigateToUpdateText = useCallback(() => {
    navigate(`/texts/${id}/update`);
  }, []);
  const navigateToDuplicateText = useCallback(() => {
    navigate(`/texts/${id}/duplicate`);
  }, []);

  const removeText = useCallback(
    (id: string) => async () => {
      const successfullyDeleted = await textService.delete(id);
      if (successfullyDeleted) {
        showToast({
          type: 'success',
          text: 'Sucesso ao remover texto.',
        });
        setDeletedItem(id);
      }
      setOpenedActions(false);
    },
    []
  );

  return (
    <DropdownMenu
      open={openedActions}
      setOpen={setOpenedActions}
      content={
        <Content sideOffset={5}>
          <Item onClick={navigateToViewText}>
            <MagnifyingGlassPlusIcon /> <span>Ver detalhes</span>
          </Item>

          <Separator />

          <Item onClick={navigateToUpdateText}>
            <PencilIcon /> <span>Editar texto</span>
          </Item>

          <Separator />

          <Item onClick={navigateToDuplicateText}>
            <DocumentDuplicateIcon /> <span>Duplicar texto</span>
          </Item>

          <Separator />

          <DialogCancelDelete itemName={name} itemType="texto" removeItem={removeText(id)} />
        </Content>
      }>
      <EllipsisVerticalIcon className="actions-button" />
    </DropdownMenu>
  );
};

export default TextActions;
