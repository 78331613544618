import { ModelFormValues } from '../../types/Model.types';

export const getTotalElements = (items: unknown) => {
  return (items as any[]).length;
};

export const getTextList = (items: unknown, attribute: string) => {
  return (items as any[]).map((item) => item[attribute]).join(', ');
};

export const getCreateUpdateData = (data: ModelFormValues) => {
  return {
    nomeModelo: data.name,
    modeloCompleto: data?.type?.value === '2' ? true : false,
    ativo: data.active ? true : false,
    secoes: data.sections?.map((section) => section.value as string),
    areasjuridicas: data.legalAreas?.map((legalArea) => legalArea.value as string),
    templateObrigatorio: '00000000-0000-0000-00000000000000000',
    modeloXml: data.xmlModel,
  };
};
