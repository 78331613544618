import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Heading from '../../components/Text/Heading';
import { StyledForm, StyledInitialDataContent } from './styles';
import FormGroup from '../../components/Form/FormGroup';
import { useCallback, useEffect, useState } from 'react';
import { modelService, templateService } from '../../api/services';
import { OptionType, Select } from '../../components/Form/Select';
import Button from '../../components/Form/Button';
import Paragraph from '../../components/Text/Paragraph';
import BlankDocument from '../../assets/icons/blank_document.svg';
import Input from '../../components/Form/Input';
import { PagedModelDataRequest } from '../../types/Model.types';
import useDebounce from '../../hooks/useDebounce';

export interface InitialDataFormValues {
  name: string;
  model: OptionType;
  template: OptionType;
  client: string;
  folder: string;
  clientName: string;
}

interface InitialDataFormProps {
  initialData?: InitialDataFormValues;
  onSubmit: (data: InitialDataFormValues) => void;
  onBack: () => void;
  loading: boolean;
}

const validationSchema = yup.object({
  name: yup.string().required('O campo nome é obrigatório'),
  model: yup.object().shape({
    label: yup.string().required('O campo modelo é obrigatório'),
    value: yup.string().required(),
  }),
  template: yup.object().shape({
    label: yup.string().required('O campo template é obrigatório'),
    value: yup.string().required(),
  }),
});

export const InitialDataForm = ({ initialData, onSubmit, onBack, loading }: InitialDataFormProps) => {
  const [models, setModels] = useState<OptionType[]>([]);
  const [templates, setTemplates] = useState<OptionType[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);

  const fetchModels = useCallback(async (search: string) => {
    try {
      if (search.length < 3) return [];
      const requestData: PagedModelDataRequest = {
        pagination: { skip: 0, limit: 50 },
        sort: { field: 'dataCriacao', order: 'DESC' },
        query: search,
        activeOnly: true,
      };
      const result = await modelService.listPaged(requestData);

      if (result && result.data.length > 0) {
        const models = result.data.map((model: any) => ({
          label: model.nomeModelo as string,
          value: model.idModelo as string,
        }));

        setModels(models);
        return models;
      }
      return [];
    } catch (error) {
      console.error('Erro ao buscar modelos:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchModels(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, fetchModels]);

  useEffect(() => {
    const fetchPromises = () => {
      const templatesRequest = templateService.list();

      Promise.all([templatesRequest]).then(([templatesResponse]) => {
        setTemplates(
          templatesResponse
            ? templatesResponse.map((template) => ({
                label: template.nomeTemplate as string,
                value: template.idTemplate as string,
              }))
            : []
        );
      });
    };

    fetchPromises();
  }, []);

  const handleChange = useCallback(
    (field: any) => (selectedOption: any) => {
      field.onChange(selectedOption);
    },
    []
  );

  const handleInputChange = useCallback((inputValue: string) => {
    setSearchTerm(inputValue);
  }, []);

  const renderSelectModels = useCallback(
    ({ field }: any) => (
      <>
        <Paragraph className="label">Escolha o modelo</Paragraph>
        {field?.value?.value === '' ? (
          <Select
            placeholder="Pesquisar modelo"
            options={models}
            onInputChange={handleInputChange}
            onChange={handleChange(field)}
          />
        ) : (
          <Select
            {...field}
            placeholder="Pesquisar modelo"
            options={models}
            onInputChange={handleInputChange}
            onChange={handleChange(field)}
          />
        )}
      </>
    ),
    [models, handleInputChange, handleChange]
  );

  const renderSelectTemplates = useCallback(
    ({ field }: any) => (
      <>
        <Paragraph className="label">Template</Paragraph>
        <Select placeholder="Escolha o template" {...field} options={[...templates]} />
      </>
    ),
    [templates]
  );

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<InitialDataFormValues>({
    defaultValues: initialData,
    resolver: yupResolver(validationSchema),
  });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Heading>Vamos lá, para começar nos informe alguns dados:</Heading>
        <FormGroup error={errors.name?.message}>
          <Input {...register('name')} label="Nome do documento" />
        </FormGroup>
        <FormGroup error={errors.model?.label?.message}>
          <Controller name="model" control={control} render={renderSelectModels} />
        </FormGroup>
        <FormGroup error={errors.folder?.message}>
          <Input {...register('client')} label="Código do cliente" type="number" />
        </FormGroup>
        <FormGroup error={errors.folder?.message}>
          <Input {...register('folder')} label="Código da pasta" type="number" />
        </FormGroup>
        <FormGroup error={errors.template?.label?.message}>
          <Controller name="template" control={control} render={renderSelectTemplates} />
        </FormGroup>
      </div>
      <div className="buttons">
        <Button type="button" kind="secondary" onClick={onBack}>
          Voltar
        </Button>

        <Button type="submit" disabled={loading}>
          {loading ? 'Aguarde...' : 'Próximo'}
        </Button>
      </div>
    </StyledForm>
  );
};

export const InitialDataContent = () => (
  <StyledInitialDataContent>
    <img src={BlankDocument} />
    <Heading size="small">DOCUMENTO EM BRANCO</Heading>
    <Paragraph>Você ainda não começou o preenchimento do documento.</Paragraph>
  </StyledInitialDataContent>
);
