import React, { useCallback } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Content, Header, Item, Root, Trigger } from './styles';

type AccordionProps = {
  children: React.ReactNode;
  content: React.ReactNode;
};

const Accordion = ({ children, content }: AccordionProps) => {
  const [value, setValue] = React.useState('item-1');

  const toggle = useCallback(() => {
    setValue((currentValue) => (currentValue === 'item-1' ? '' : 'item-1'));
  }, []);

  return (
    <Root type="single" value={value} collapsible>
      <Item value="item-1">
        <Header>
          <Trigger>
            {children}
            <button type="button" onClick={toggle}>
              <ChevronDownIcon aria-hidden />
            </button>
          </Trigger>
        </Header>

        <Content>
          <div>{content}</div>
        </Content>
      </Item>
    </Root>
  );
};

export default Accordion;
